
const Aviator = () => {
    const fullScreen = `
    "position": "fixed", 
                    "top": "0",
                    "left": "0",
                    "bottom": "0", 
                    "right": "0", 
                    "width": "100%", 
                    "height": "100%", 
                    "border": "none", 
                    "margin": "0", 
                    "padding": "0", 
                    "overflow": "hidden", 
                    "zIndex": "999999",`;
  return (
    <div>
      <iframe 
        src="https://aviator-demo.spribegaming.com/?currency=USD&operator=demo&jurisdiction=CW&lang=EN&return_url=https:%2F%2Fspribe.co%2Fgames&user=73253&token=0cIrXcMmlOflKHPjrWsQFy70oHrIPl62" 
        title="Aviator"
        style={{
            height: "calc(100vh - 4px)",
            width: "calc(100vw - 4px)",
            boxSizing: "border-box"
        }}
        allow="fullscreen"
        frameBorder="0"
        >
        Your browser doesn't support iframes
      </iframe>
    </div>
  )
}
export default Aviator


