import React from 'react';
import CatalogItem from './CatalogItem';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
  
const images = importAll(require.context('../../container/assets/games', false, /\.(png|jpe?g|svg|webp)$/));


function GamesCatalog() {
    return (
        <div className="games__catalog">
            <div className="catalog">

                <div className="catalog__list">
                     {/* Object to Map Affiliate partners images*/ } 
                {
                    Object.keys(images).map((keyName, i) => (
                        <CatalogItem 
                            key={i}
                            alt="Book of Dead game tile"
                            image={images[keyName]}
                            gameUrl="/popup"
                            gameName="Book of Dead"
                            provider="playngo" 
                        />
                    ))
                }
                   
                </div>


                <div className="catalog__more">
                    <button 
                        className="btn--more btn btn--secondary btn--large btn--block"
                        >
                        show more
                    </button>
                </div>

            </div>
        </div>
    )
}

export default GamesCatalog
