import React from 'react'
import GameContainer from './GameContainer'
import GameContent from './GameContent'
import PopupHeader from './PopupHeader'

/* 
* @Params *
frameTitle
*/
function Popup(props) {
    return (
        <main className="layout__game">
            <div className="page-game">
                <PopupHeader />
                <div className="page-game__bg"></div>
                <GameContainer 
                    gameTitle="Piggy Riches"
                    frameTitle="Play Lightning Roulette Online by evolution" 
                    gameUrl="https://int.datachannel.cloud/netent/v23/games/piggyriches_mobile_html/game/piggyriches_mobile_html.xhtml?launchType=iframe&iframeSandbox=allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock&applicationType=browser&gameId=piggyriches_not_mobile&server=https://int2.datachannel.cloud/.netentGamble2/&lang=ru&sessId={%22operatorId%22:%220%22,%22userId%22:%2273f902dd31ab6a16c837382758e2d021_172.68.10.126%22,%22sessionId%22:%22ce4c1e53-a510-4cb6-809e-7066a4c4a8cc%22}&operatorId=netent&logsId=715ded4b-7416-43bb-bd0c-6ae47311460e&loadStarted=1656321943271&giOperatorConfig={%22targetElement%22:%22netentgame%22,%22launchType%22:%22iframe%22,%22iframeSandbox%22:%22allow-scripts%20allow-popups%20allow-popups-to-escape-sandbox%20allow-top-navigation%20allow-top-navigation-by-user-activation%20allow-same-origin%20allow-forms%20allow-pointer-lock%22,%22applicationType%22:%22browser%22,%22gameId%22:%22piggyriches_not_mobile%22,%22server%22:%22https://int2.datachannel.cloud/.netentGamble2/%22,%22lang%22:%22ru%22,%22sessId%22:{%22operatorId%22:%220%22,%22userId%22:%2273f902dd31ab6a16c837382758e2d021_172.68.10.126%22,%22sessionId%22:%22ce4c1e53-a510-4cb6-809e-7066a4c4a8cc%22},%22operatorId%22:%22netent%22}&loadSeqNo=0&disableDeviceDetection=true&casinourl=&lobbyURL="
                    />
                <GameContent />
            </div>
        </main>
    )
}

export default Popup