import React from 'react';
import enLogo from '../../container/assets/language/en.svg';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';


function FooterLanguage() {
    return (
        <div className="footer__locales-wrapper">
            <div className="select-language-icons-with-label" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="lang-select-label">
                <div className="select-language-icons-with-label__button" aria-autocomplete="list" aria-labelledby="lang-select-label" autoComplete="off" value="English" id="lang-select">
                    <div className="select-language-icons-with-label__button-inner">
                        <a className="select-language-icons-with-label__link">
                            <div className="select-language-icons-with-label__icon">
                                <img 
                                    className="select-language-icons-with-label__img flag-icon image" 
                                    alt="en" 
                                    src={enLogo}
                                    alt="image" 
                                />
                            </div>
                        </a>
                        <div className="select-language-icons-with-label__code">English</div>
                    </div>
                    <div className="select-language-icons-with-label__arrow-down-wrapper">
                        <i className="select-language-icons-with-label__arrow-down">
                            <KeyboardArrowDownOutlinedIcon/>
                        </i>
                    </div>
                </div>

                {/*<div className="select-language-icons-with-label__container">
                    <ul className="select-language-icons-with-label__list" role="listbox" aria-labelledby="lang-select-label" id="lang-select-menu">
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-0" role="option" aria-selected="false" href="https://www.crossgaming.com/ru/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="ru" src="./assets/ru.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Русский</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-1" role="option" aria-selected="false" href="https://www.crossgaming.com/en-AU/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="en-AU" src="./assets/en-AU.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">English - Australia</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-2" role="option" aria-selected="false" href="https://www.crossgaming.com/en-NZ/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="en-NZ" src="./assets/en-NZ.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">English - New Zealand</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-3" role="option" aria-selected="false" href="https://www.crossgaming.com/de/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="de" src="./assets/de.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Deutsch</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-4" role="option" aria-selected="false" href="https://www.crossgaming.com/fi/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="fi" src="./assets/fi.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Suomi</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-5" role="option" aria-selected="false" href="https://www.crossgaming.com/pl/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="pl" src="./assets/pl.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Polski</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-6" role="option" aria-selected="false" href="https://www.crossgaming.com/es/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="es" src="./assets/es.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Español</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-7" role="option" aria-selected="false" href="https://www.crossgaming.com/pt/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="pt" src="./assets/pt.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Português</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-8" role="option" aria-selected="false" href="https://www.crossgaming.com/pt-BR/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="pt-BR" src="./assets/pt-BR.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Português - BR</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-9" role="option" aria-selected="false" href="https://www.crossgaming.com/ja/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="ja" src="./assets/ja.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">日本語</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-10" role="option" aria-selected="false" href="https://www.crossgaming.com/no/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="no" src="./assets/no.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Norwegian</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-11" role="option" aria-selected="false" href="https://www.crossgaming.com/en-CA/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="en-CA" src="./assets/en-CA.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">English - Canada</div>
                            </div>
                        </a>
                        <a className="select-language-icons-with-label__link option-link link" variant="primary" id="lang-select-item-12" role="option" aria-selected="false" href="https://www.crossgaming.com/fr-CA/">
                            <div className="option-link__content">
                                <div className="select-language-icons-with-label__icon"><img className="select-language-icons-with-label__img flag-icon image" alt="fr-CA" src="./assets/fr-CA.svg" alt="image" /></div>
                                <div className="select-language-icons-with-label__code body3">Français - Canada</div>
                            </div>
                        </a>

                    </ul>
                </div>*/}
            </div>
        </div>
    )
}

export default FooterLanguage
