import React from 'react';
import FooterLicense from './FooterLicense';
import FooterLanguage from './FooterLanguage';
import FooterMenu from './FooterMenu';
import FooterProviderList from './FooterProviderList';

import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';


function Footer({ props }) {
    return (
        <div className="layout__footer">
            <div className="container container--large">
                
                
                <footer className="layout__footer footer">

                    <div className="footer-logos footer__logos">
                        <div 
                            className="slick-slider footer-logos-slider slick-initialized" 
                            dir="ltr">

                            <div className="footer-logos-slider__PrevArrow">
                                <div 
                                    style={{transform: 'rotate(180deg)'}}>
                                    <KeyboardArrowLeftOutlinedIcon/>
                                </div>
                            </div>
                            
                            <FooterProviderList/>

                            <div className="footer-logos-slider__NextArrow">
                                <KeyboardArrowLeftOutlinedIcon/>
                            </div>
                        </div>
                    </div>


                    
                    <div className="footer__divider"></div>
                    <div className="footer__sections">
                        <div className="footer__section-license">
                            <FooterLicense/>
                            <FooterLanguage/>
                        </div>
                        <FooterMenu/>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Footer
