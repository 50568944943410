import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { domain } from '../../config';

import '../../container/styles/f03711c8.css';

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import ChatIcon from '../../container/assets/support-icons/chat.png';
import EmailIcon from '../../container/assets/support-icons/email.png';
import FAQIcon from '../../container/assets/support-icons/FAQ.png';

function Support() {

    const [formValue, setFormValue] = useState({
        email: "",
        question: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({ ...formValue, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!formValue.email || !formValue.question) {
            console.log("Enter required fields.")
            return;
        }

        // Send data to server
        try {
            const response = await fetch(`${domain()}/support/email`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    //"Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({ formValue }),
            });

            const result = await response.json();  

            if (!result.error) {
                console.log(result);
                setFormValue({
                    email: "",
                    question: ""
                })
            } else {
                console.log(result.error);
            }

        } catch (err) {
            console.log('Support email: ' + err);
        }
    }

    const { email, question } = formValue;

    return (
        <main className="layout__main">
            <div className="support-page bg-image">
                <div className="support-page__container">
                    <h1 className="support-page__title title-accent">Support</h1>
                    <p className="support-page__description text">
                        Please, fill the information below. All fields are required.
                    </p>
                    <div className="support-page__content">
                        <div 
                            className="support-page__form"
                            onSubmit={handleSubmit}
                            >

                            <form className="contact-form form">
                                <div className="contact-form__form-element form-element">
                                    <label className="input contact-form__input">

                                        <input
                                            className="input__native"
                                            name="email"
                                            type="email"
                                            autoComplete="off"
                                            id="contact-form-email"
                                            placeholder=" "
                                            value={email} 
                                            onChange={handleChange}
                                            required
                                            />
                                        <span className="input__label">Enter your email</span>
                                        <span className="input__focus"></span>
                                    </label>
                                    <div 
                                        className="collapse collapse--exiting" 
                                        style={{ "transitionDuration": "300ms"}}
                                        >
                                        <div className="collapse__content-wrapper">
                                            <div className="collapse__content">
                                                <div className="form-element__error">must be completed</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="contact-form__form-element form-element">
                                    <label htmlFor="content" className="input textarea input--empty">
                                        <textarea 
                                            id="question" 
                                            name="question" 
                                            rows="8" 
                                            cols="40" 
                                            value={question}
                                            onChange={handleChange}
                                            required
                                            placeholder=" "
                                            >
                                        </textarea>
                                        <span className="input__label">Enter your message</span>
                                        <span className="input__focus"></span>
                                    </label>
                                    <div className="collapse" style={{ "transitionDuration": "300ms" }}>
                                        <div className="collapse__content-wrapper">
                                            <div className="collapse__content"></div>
                                        </div>
                                    </div>
                                </div>
                                <button 
                                    className="contact-form__submit-button btn btn--danger btn--large" 
                                    type="submit"
                                    >
                                    submit
                                </button>
                            </form>
                        </div>
                        <div className="support-page__items">
                            <div className="support-page__item">
                                <img src={ChatIcon} alt="support chat" className="support-page__item-img" />
                                <div className="support-page__item-content">
                                    <h5 className="support-page__item-title">Chat</h5>
                                    <p className="text">
                                        Get an answer as quick as lightning!
                                    </p>
                                    <span className="support-page__item-text">
                                        {/*<i className="icon-chat-with-us"></i>*/}
                                        <ChatBubbleOutlineOutlinedIcon/>
                                    <span style={{ "marginLeft": "10px" }}>Chat with us</span></span>
                                </div>
                            </div>
                            <div className="support-page__item">
                                <img src={EmailIcon} alt="support email" className="support-page__item-img" />
                                <div className="support-page__item-content">
                                    <h5 className="support-page__item-title">Email</h5>
                                    <p className="text">
                                        We monitor our inboxes and respond as quick as a flash 
                                        <Link to="mailto:support@coingateway.me"> support@coingateway.me</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="support-page__item">
                                <img src={FAQIcon} alt="support faq" className="support-page__item-img" />
                                <div className="support-page__item-content">
                                    <h5 className="support-page__item-title">FAQ</h5>
                                    <ul className="text">
                                        <li>How to play?</li>
                                        <li>How can I withdraw the winnings?</li>
                                    </ul>
                                    <p className="text">
                                        Answers to these questions in the &nbsp;  
                                        <Link 
                                            className="link" 
                                            variant="primary" 
                                            to="/faq"
                                            >
                                            FAQ
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Support