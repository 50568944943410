import React from 'react';

function TournCard(props) {
    return (
        <div className="tourn-card">
            <div className="tourn-card__image">
                <img 
                    className="image" 
                    alt="Wonder iHunt" 
                    src={props.image} 
                    />
            </div>
            <div className="tourn-card__wrap">
                <h3 className="tourn-card__title">{props.title}</h3>
                <ul className="tourn-card__options">
                    <li className="tourn-card__option">
                        <span className="tourn-card__dot"></span>
                        <span className="text">Time Left</span>
                        
                        <div className="inline-timer tourn-card__timer">
                            <div className="inline-timer__item">
                                <div className="inline-timer__value">01</div>
                                <div className="inline-timer__text">d</div>
                                <div className="inline-timer__text inline-timer__text--full">days</div>
                            </div>
                            <div className="inline-timer__item">
                                <div className="inline-timer__value">21</div>
                                <div className="inline-timer__text">h</div>
                                <div className="inline-timer__text inline-timer__text--full">hours</div>
                            </div>
                            <div className="inline-timer__item">
                                <div className="inline-timer__value">32</div>
                                <div className="inline-timer__text">m</div>
                                <div className="inline-timer__text inline-timer__text--full">minutes</div>
                            </div>
                            <div className="inline-timer__item">
                                <div className="inline-timer__value">45</div>
                                <div className="inline-timer__text">s</div>
                                <div className="inline-timer__text inline-timer__text--full">seconds</div>
                            </div>
                        </div>
                    </li>

                    <li className="tourn-card__option">
                        <span className="text">Prize Pool</span>
                        <strong>Apple Devices + 5,000 EUR + 5,000 FS</strong>
                    </li>

                    <li className="tourn-card__option">
                        <span className="text">Places</span>
                        <strong>150</strong>
                    </li>
                </ul>
                
                <a 
                    className="tourn-card__link link-text-btn" 
                    href="https://www.crossgaming.com/tournaments/treasure-hunt-weekly" 
                    variant="primary">Show More
                </a>
            </div>
        </div>
    )
}

export default TournCard
