import React from 'react'
import { Link } from 'react-router-dom';
import BonusesCards from './BonusesCards';
import BonusesItems from './BonusesItems';

import bonusItemImg1 from '../../container/assets/bonuses/Monday_Reload_card.jpg';
import bonusItemImg2 from '../../container/assets/bonuses/Wednesday_Freespins_reload_(other)_card.jpg';
import bonusItemImg3 from '../../container/assets/bonuses/High_roller_reload_card.jpg';

import bonusCards1 from '../../container/assets/bonuses/loki.png';
import bonusCards2 from '../../container/assets/bonuses/sakura.png';
import bonusCards3 from '../../container/assets/bonuses/wild.png';



function Bonuses() {
    return (
        <main className="layout__main">
            <div className="bonuses-page bg-image">
                <div className="bonuses-page__container container">
                    <h1 className="bonuses-page__title">
                        Welcome Package
                    </h1>
                    <p className="bonuses-page__description text">
                        Each new player requires a good jump-start to play more confidently. The much needed and convenient boost is waiting for you. Get the Welcome Package!
                    </p>

                    <div className="bonuses-page__cards bonuses-cards">
                        <BonusesCards
                            image={bonusCards1}
                            imgalt="First Deposit"
                            title="First Deposit"
                            size="100%"
                            description="Up to 200 EUR + 50 Free Spins"
                            url="/register"
                            buttonText="Get Bonus"
                        />

                        <BonusesCards
                            image={bonusCards2} 
                            imgalt="Second Deposit" 
                            title="Second Deposit" 
                            size="100%" 
                            description="Up to 200 EUR + 50 Free Spins" 
                            url="/register" 
                            buttonText="Get Bonus" 
                        />

                        <BonusesCards 
                            image={bonusCards3} 
                            imgalt="3rd Deposit" 
                            title="3rd Deposit" 
                            size="100%" 
                            description="Up to 200 EUR + 50 Free Spins" 
                            url="/register" 
                            buttonText="Get Bonus" 
                        />
                    </div>

                    <h2 className="bonuses-page__title">
                        Reload Bonuses
                    </h2>
                    <p className="bonuses-page__description text">
                        Enjoy your set of Reload Bonuses. Get After Party Cash bonuses on Mondays and Free Spins awards on Wednesdays.
                    </p>

                    <div className="bonuses-items__items">
                        <BonusesItems image={bonusItemImg1} imgAlt="Monday Reload" title="Monday Reload" description="Use MON40 bonus code to get 40% up to 100 EUR bonuses. Victories await!" readMore="Read More" />
                        <BonusesItems image={bonusItemImg2} imgAlt="Wednesday Reload" title="Wednesday Reload" description="Use MON40 bonus code to get 40% up to 100 EUR bonuses. Victories await!" readMore="Read More" />
                        <BonusesItems image={bonusItemImg3} imgAlt="High roller" title="High Reload" description="Use MON40 bonus code to get 40% up to 100 EUR bonuses. Victories await!" readMore="Read More" />
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Bonuses