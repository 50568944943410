import "./ProfileVerification.css";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";

function ProfileVerification() {
  return (
    <main className="sc-fzoXzr wPaCf layout__main">
      <h1 className="sc-pIJJz XfiFP title title--h1">Profile</h1>

      <nav className="sc-pIJJz dhpicN tabs__container">
        <div className="sc-pRFjI iaxKdj tabs__wrapper">
          <a
            aria-current="page"
            className="sc-pZBmh eUpOXi tabs__tab-link "
            href="/profile/general/info"
          >
            General
          </a>
          <span className="sc-oTbqq ccNeVQ tabs__delimiter"></span>

          <a
            className="sc-pZBmh eUpOXi tabs__tab-link active"
            href="/profile/general/verification"
          >
            Verification
          </a>

          <span className="sc-oTbqq ccNeVQ tabs__delimiter"></span>

          <a
            className="sc-pZBmh eUpOXi tabs__tab-link"
            href="/profile/general/security"
          >
            Security
          </a>
          <span className="sc-oTbqq ccNeVQ tabs__delimiter"></span>
        </div>
      </nav>

      <div className="sc-pjSSY fjBVSK profile-verification page-container">
        <div className="sc-pZOBi jOdohT styled-cards__container">
          <div
            className="sc-pJUVA bCkCTr animated styled-card__wrapper fadeIn"
            style={{
              "animationDuration": "500ms"
            }}
          >
            <div className="sc-qYIQh sc-oUOMp clsEOx styled-card verification-card">
              <div
                className="slick-slider sc-pdjNk iQzCqY icons-carousel__slider slick-initialized"
                dir="ltr"
              >
                <div className="slick-list">
                  <div
                    className="slick-track"
                    style={{
                      "width": "200px",
                      "opacity": "1",
                      "transform": "translate3d(0px, 0px, 0px)"
                    }}
                  >
                    <div
                      data-index="0"
                      className="slick-slide slick-active slick-current"
                      tabIndex="-1"
                      aria-hidden="false"
                      style={{
                        "outline": "none",
                        "width": "100px"
                      }}
                    >
                      <div>
                        <i className="sc-pkIrX fxhluv verification-card__icon icon icon-identity"></i>
                      </div>
                    </div>
                    <div
                      data-index="1"
                      className="slick-slide"
                      tabIndex="-1"
                      aria-hidden="true"
                      style={{
                        "outline": "none",
                        "width": "100px"
                      }}
                    >
                      <div>
                        <i className="sc-pkIrX fxhluv verification-card__icon icon icon-selfie"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="sc-ptdsS ljgwsh verification-card__title">
                Identity
              </h3>
              <div className="sc-pAyMl bhgzma text-carousel__container">
                <div
                  className="slick-slider sc-pQsrT TanSi text-carousel__slider slick-initialized"
                  dir="ltr"
                >
                  <button
                    type="button"
                    data-role="none"
                    className="slick-arrow slick-prev slick-disabled"
                    style={{
                      "display": "none"
                    }}
                  >
                    {" "}
                    Previous
                  </button>
                  <div className="slick-list">
                    <div
                      className="slick-track"
                      style={{
                        "width": "200px",
                        "opacity": "1",
                        "transform": "translate3d(0px, 0px, 0px)"
                      }}
                    >
                      <div
                        data-index="0"
                        className="slick-slide slick-active slick-current"
                        tabIndex="-1"
                        aria-hidden="false"
                        style={{
                          "outline": "none", 
                          "width": "100px"
                        }}
                      >
                        <div>
                          <button
                            data-index="0"
                            tabIndex="-1"
                            className="sc-fzoJMP eFMCar sc-pITNg kuPloX text-carousel__slider-title text-button text-button--primary"
                            type="button"
                            style={{
                              "width": "100%",
                              "display": "inline-block"
                            }}
                          >
                            Primary
                          </button>
                        </div>
                      </div>
                      <div
                        data-index="1"
                        className="slick-slide"
                        tabIndex="-1"
                        aria-hidden="true"
                        style={{
                          "outline": "none",
                          "width": "100px"
                        }}
                      >
                        <div>
                          <button
                            data-index="1"
                            tabIndex="-1"
                            className="sc-fzoJMP eFMCar sc-pITNg kuPloX text-carousel__slider-title text-button text-button--primary"
                            type="button"
                            style={{
                              "width": "100%", 
                              "display": "inline-block"
                            }}
                          >
                            Selfie &amp; ID
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    data-role="none"
                    className="slick-arrow slick-next"
                    style={{
                      "display": "none"
                    }}
                  >
                    {" "}
                    Next
                  </button>
                </div>
              </div>
              <div className="sc-pYNsO hLLvQA text-carousel__content">
                <div className="sc-pspzH wcDuv entered collapse__container">
                  <div className="sc-qPLKk eIsxcS collapse__content-wrapper">
                    <div className="sc-qXHHN bLRbgg collapse__content">
                      <p className="sc-qYsuA ia-ddhZ verification-card__text">
                        Passport, driver's license, or other official document
                        replacing them.{" "}
                        <a
                          variant="primary"
                          type="button"
                          className="sc-fzoJMP eFMCar text-button text-button--primary"
                          href="/profile/general/verification?moreInfoDocumentType=identity"
                        >
                          More info
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sc-qPXtF bFiGZg verification-card__spacing"></div>
              <div className="sc-pTHAw BqSfT file-load-block__wrapper">
                <div className="sc-fzokOt hQCeKK sc-qbDCV bAmzjN file-load-block__mobile-or-tablet breakpoints--mobile-or-tablet">
                  <span>
                    <input
                      type="file"
                      multiple=""
                      accept="image/*, application/pdf"
                      style={{
                        "display": "none"
                      }}
                    />
                    <button className="sc-fzplWN gwNWYc sc-pcZJD jyNuao file-load-block__button sc-pcZJD jyNuao file-load-block__button styled-button styled-button--secondary">
                      Upload
                    </button>
                  </span>
                  <div className="sc-pCPXO bCoHWT load-file-limit__container">
                    jpg, pdf. Max size 2MB
                  </div>
                </div>
                <div className="sc-fzqNJr kTbGNa sc-oVdHe emIfqj file-load-block__desktop breakpoints--desktop">
                  <div className="sc-pKMan bvodAA sc-plVjM kKlEuu file-load-block__drop-block drop-block__container">
                    <div className="sc-ptRml gxleGx file-load-block__drop-block-text">
                      Drop file here or{" "}
                      <span>
                        <input
                          type="file"
                          multiple=""
                          accept="image/*, application/pdf"
                          style={{
                            "display": "none"
                          }}
                        />
                        <button
                          type="button"
                          className="sc-fzoJMP eFMCar text-button text-button--primary"
                        >
                          browse
                        </button>
                      </span>
                      <div className="sc-pCPXO bCoHWT load-file-limit__container">
                        jpg, pdf. Max size 2MB
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sc-pJUVA bCkCTr animated styled-card__wrapper fadeIn"
            style={{
              "animationDuration": "500ms"
            }}
          >
            <div className="sc-qYIQh sc-oUOMp clsEOx styled-card verification-card">
              <div className="slick-slider sc-pdjNk iQzCqY icons-carousel__slider slick-initialized">
                <div className="slick-list">
                  <div
                    className="slick-track"
                    style={{
                      "width": "100px",
                      "opacity": "1",
                       "transform": "translate3d(0px, 0px, 0px)"
                    }}
                  >
                    <div
                      data-index="0"
                      className="slick-slide slick-active slick-current"
                      tabIndex="-1"
                      aria-hidden="false"
                      style={{
                        "outline": "none",
                        "width": "100px"
                      }}
                    >
                      <div>
                        <i className="sc-pkIrX fxhluv verification-card__icon icon icon-address"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="sc-ptdsS eTUyKk verification-card__title">
                Address
              </h3>
              <p className="sc-qYsuA ia-ddhZ verification-card__text">
                Utility bill, phone bill, or bank statement, in which your name
                and address are fully indicated.{" "}
                <a
                  variant="primary"
                  type="button"
                  className="sc-fzoJMP eFMCar text-button text-button--primary"
                  href="/profile/general/verification?moreInfoDocumentType=address"
                >
                  More info
                </a>
              </p>
              <div className="sc-qPXtF bFiGZg verification-card__spacing"></div>
              <div className="sc-pTHAw BqSfT file-load-block__wrapper">
                <div className="sc-fzokOt hQCeKK sc-qbDCV bAmzjN file-load-block__mobile-or-tablet breakpoints--mobile-or-tablet">
                  <span>
                    <input
                      type="file"
                      multiple=""
                      accept="image/*, application/pdf"
                      style={{
                        "display": "none"
                      }}
                    />
                    <button className="sc-fzplWN gwNWYc sc-pcZJD jyNuao file-load-block__button sc-pcZJD jyNuao file-load-block__button styled-button styled-button--secondary">
                      Upload
                    </button>
                  </span>
                  <div className="sc-pCPXO bCoHWT load-file-limit__container">
                    jpg, pdf. Max size 2MB
                  </div>
                </div>
                <div className="sc-fzqNJr kTbGNa sc-oVdHe emIfqj file-load-block__desktop breakpoints--desktop">
                  <div className="sc-pKMan bvodAA sc-plVjM kKlEuu file-load-block__drop-block drop-block__container">
                    <div className="sc-ptRml gxleGx file-load-block__drop-block-text">
                      Drop file here or{" "}
                      <span>
                        <input
                          type="file"
                          multiple=""
                          accept="image/*, application/pdf"
                          style={{
                            "display": "none"
                          }}
                        />
                        <button
                          type="button"
                          className="sc-fzoJMP eFMCar text-button text-button--primary"
                        >
                          browse
                        </button>
                      </span>
                      <div className="sc-pCPXO bCoHWT load-file-limit__container">
                        jpg, pdf. Max size 2MB
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sc-pJUVA bCkCTr animated styled-card__wrapper fadeIn"
            style={{
              "animationDuration": "500ms"
            }}
          >
            <div className="sc-qYIQh sc-oUOMp clsEOx styled-card verification-card">
              <div className="slick-slider sc-pdjNk iQzCqY icons-carousel__slider slick-initialized">
                <div className="slick-list">
                  <div
                    className="slick-track"
                    style={{
                      "width": "100px",
                      "opacity": "1", 
                      "transform": "translate3d(0px, 0px, 0px)"
                    }}
                  >
                    <div
                      data-index="0"
                      className="slick-slide slick-active slick-current"
                      tabIndex="-1"
                      aria-hidden="false"
                      style={{
                        "outline": "none", 
                        "width": "100px"
                      }}
                    >
                      <div>
                        <i className="sc-pkIrX fxhluv verification-card__icon icon icon-payment"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className="sc-ptdsS eTUyKk verification-card__title">
                Payment
              </h3>
              <p className="sc-qYsuA ia-ddhZ verification-card__text">
                Screenshot or photo from online bank, bank statement, Skrill
                page, etc. showing the deposit.{" "}
                <a
                  variant="primary"
                  type="button"
                  className="sc-fzoJMP eFMCar text-button text-button--primary"
                  href="/profile/general/verification?moreInfoDocumentType=payment"
                >
                  More info
                </a>
              </p>
              <div className="sc-qPXtF bFiGZg verification-card__spacing"></div>
              <div className="sc-pTHAw BqSfT file-load-block__wrapper">
                <div className="sc-fzokOt hQCeKK sc-qbDCV bAmzjN file-load-block__mobile-or-tablet breakpoints--mobile-or-tablet">
                  <span>
                    <input
                      type="file"
                      multiple=""
                      accept="image/*, application/pdf"
                      style={{
                        "display": "none"
                      }}
                    />
                    <button className="sc-fzplWN gwNWYc sc-pcZJD jyNuao file-load-block__button sc-pcZJD jyNuao file-load-block__button styled-button styled-button--secondary">
                      Upload
                    </button>
                  </span>
                  <div className="sc-pCPXO bCoHWT load-file-limit__container">
                    jpg, pdf. Max size 2MB
                  </div>
                </div>
                <div className="sc-fzqNJr kTbGNa sc-oVdHe emIfqj file-load-block__desktop breakpoints--desktop">
                  <div className="sc-pKMan bvodAA sc-plVjM kKlEuu file-load-block__drop-block drop-block__container">
                    <div className="sc-ptRml gxleGx file-load-block__drop-block-text">
                      Drop file here or{" "}
                      <span>
                        <input
                          type="file"
                          multiple=""
                          accept="image/*, application/pdf"
                          style={{
                            "display": "none"
                          }}
                        />
                        <button
                          type="button"
                          className="sc-fzoJMP eFMCar text-button text-button--primary"
                        >
                          browse
                        </button>
                      </span>
                      <div className="sc-pCPXO bCoHWT load-file-limit__container">
                        jpg, pdf. Max size 2MB
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sc-pZOBi jOdohT styled-cards__container">
          <div className="sc-pJUVA bCkCTr styled-card__wrapper">
            <h2 className="sc-paXsP sc-pAMyN kZJfYy title title--h2 profile-verification__tab-subtitle">
              Phone verification
            </h2>
            <div className="sc-qYIQh iutLqZ styled-card">
              <div className="sc-pBzUF cIxsVM styled-card__title">
                Phone number
              </div>
              <div className="sc-pRtAn kfNapK styled-card__body">
                <div className="sc-pzMyG icAmLS status-item__wrapper">
                  <div
                    title="+37455****53"
                    className="sc-pQEbo fRpOky status-item__name"
                  >
                    +37455****53
                  </div>
                  <div className="sc-pbYBj dzHhcY status-item__status-wrapper">
                    <i
                      className="sc-pReKu sc-pZaHX jgtVFK status-icon__pending-icon icon icon-history"
                      title="Unverified"
                    ></i>
                  </div>
                  <button
                    title="Remove"
                    className="sc-fzoJMP eFMCar sc-pYA-dN gFgakE status-item__delete-button text-button text-button--primary"
                    type="button"
                  >
                    <i className="icon icon-close"></i>
                  </button>
                </div>
                <button className="sc-fzplWN gwNWYc styled-button styled-button--secondary styled-button--sm">
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default ProfileVerification;
