import React from 'react'
import GamesCategoryMenu from './GamesCategoryMenu'
import GamesFilterSearch from './GamesFilterSearch'
import GamesHeader from './GamesHeader'
import MenuProviderList from './MenuProviderList';
import GamesCatalog from './GamesCatalog';
import HeaderSlider from '../HeaderSlider/HeaderSlider';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function Casino() {
    return (
        <>
            <HeaderSlider />
            <main className="layout__main">
                <div className="games">
                    <div className="container">
                        <div className="games__section">
                            <GamesHeader />


                            <div className="games__filter games-filter">
                                <div className="games-filter__cell games-filter__cell--category">
                                    <div className="games-filter__category games-filter__category--desktop">
                                        <GamesCategoryMenu />
                                    </div>
                                </div>


                                <div className="games-filter__controls">
                                    <GamesFilterSearch />


                                    <div className="games-filter__cell games-filter__cell--providers games-filter__cell--home">
                                        <div className="menu-providers" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="games-page-providers-label">
                                            <div className="menu-providers__button" aria-autocomplete="list" aria-labelledby="games-page-providers-label" autoComplete="off" value="" id="games-page-providers">
                                                <div className="menu-providers__button-inner subtitle3">Providers</div>
                                                <div className="menu-providers__caret">
                                                    <KeyboardArrowDownOutlinedIcon/>
                                                </div>
                                            </div>
                                            <div className="menu-providers__content" role="listbox" aria-labelledby="games-page-providers-label" id="games-page-providers-menu">

                                                <MenuProviderList />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <GamesCatalog />


                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Casino
