import { Link } from "react-router-dom";
import './PageNotFound.css';

function PageNotFound() {
  return (
    <main className="layout__main">
      <div className="error-page__content container container--small">
        <div className="error-page__content-wrapper">
          <h2 className="error-page__code heading2">404</h2>
          <div className="error-page__title heading2 font-extra">
            Sorry, page not found
          </div>
          <div className="error-page__text body2">
            Seems like you have lost your way. You could return to the home page
            or brows games.
          </div>
          <Link
            className="error-page__button btn link-btn btn--danger btn--large"
            to="/"
          >
            Go to Home page
          </Link>
        </div>
      </div>
    </main>
  );
}
export default PageNotFound;
