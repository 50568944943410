import React from "react";
import './FAQ.css';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

function FAQ() {
  return (
    <main className="layout__main">
      <div className="terms-and-conditions bg-image">
        <div className="container">
          <div className="terms-and-conditions__wrapper">
            <h1 className="terms-and-conditions__page-title title-accent">
              Frequently asked questions
            </h1>
            <div className="terms-and-conditions__block">
              <p className="terms-and-conditions__title heading1 secondary">
                Registration &amp; Account
              </p>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How do I register for an account?
                  </div>
                </div>

                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Click the SIGN UP button at the top of the page. Fill in
                        the registration form and click Registration. A welcome
                        letter with a verification link to activate your account
                        will be sent to the email address you entered.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    I haven’t received the welcome letter with the verification
                    link.
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Some email service providers may classify our emails as
                        'Spam' and may send our messages to your 'Junk Mail'
                        folder. In order you get letters from us further on, add
                        our email address to your list of 'Safe' contacts.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    I forgot my password and can’t login. What shall I do?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Please click on the{" "}
                        <a href="https://www.crossgaming.com/?forgot-password=modal">
                          'Forgot your password?'
                        </a>{" "}
                        button in the “Sign in” block on the top of the page,
                        enter your email and we will send you password reset
                        instructions.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    Can everyone play at Cross Gaming for real money?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        In order to play for real money, you must be at least 18
                        years old. Fun mode players have no age limit. Also,
                        there are country restrictions in our Casino. To learn
                        about them, see point{" "}
                        <a href="https://www.crossgaming.com/terms-and-conditions">
                          2.4 in Terms and Conditions
                        </a>
                        . Take note that in some countries it is illegal to
                        gamble online. It is your responsibility to learn and
                        abide by the laws concerning betting that apply in your
                        country (or state). The Casino cannot guarantee
                        successful processing of withdrawals or refunds if the
                        player breaches the Restricted Countries policy.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-and-conditions__block">
              <p className="terms-and-conditions__title heading1 secondary">
                Deposits &amp; Withdrawals
              </p>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">How do I make a deposit?</div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        There are different options for making a deposit. For
                        example, you can transfer money with your credit or
                        debit card, Neteller, or Skrill. To see the full list of
                        available options see the Payment Methods page. To make
                        a deposit, click on the “Deposit” button in the top
                        right corner of the page and follow the instructions.
                        You can find out all the necessary information
                        concerning available deposit methods on
                        <a href="https://www.crossgaming.com/payments">
                          'the Payment Methods page'
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How do I know if the deposited money has been accredited to
                    my account?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Once you make a transfer, the money will immediately
                        appear in the Balance section of your Account.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How do I withdraw money from my account?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        To withdraw money available, hit Cash Out button in the
                        top right corner of the page. Your money can be
                        withdrawn to your credit/debit card, e-wallets, or any
                        other option available in your region. To see the full
                        list of available options go to the{" "}
                        <a href="https://www.crossgaming.com/payments">
                          'Payment Methods page'
                        </a>
                        . Please note that you don’t need to withdraw your money
                        at the end of each playing session. It will be kept on
                        your account and available during your next playing
                        sessions. You can read more about our withdrawal and
                        refund policy on the{" "}
                        <a href="https://www.crossgaming.com/terms-and-conditions">
                          'Terms and Conditions page'
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    I’m trying to deposit money but the system doesn’t allow it.
                    What is the reason?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Cross Gaming is only accepting players from
                        jurisdictions where online gaming for real money is not
                        prohibited by law. Inability to deposit money into your
                        account might be caused by the fact that the
                        jurisdiction of your country of residence has internal
                        regulation against online gaming.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-and-conditions__block">
              <p className="terms-and-conditions__title heading1 secondary">
                Games
              </p>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    Some games are running slow and/or lagging. Why is it
                    happening?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        The speed of your games depends solely on the Internet
                        connection. Since our games require constant exchange of
                        data, they require sufficient Internet bandwidth. We
                        recommend limiting the number of other web applications
                        running on your computer simultaneously with our Casino
                        games to improve the performance of our games.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    Can I play on my mobile device?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Yes, our games are mobile-friendly. Just open
                        www.crossgaming.com and launch any game displayed on the
                        Casino home page!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    I have a problem playing your games on my mobile device.
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        The problem might appear because you are using an old
                        model of a mobile device which our games do not support.
                        Please contact our support team at
                        support@crossgaming.com with the details on the model of
                        the mobile device, operational system, and the browser
                        you are using, and we will check for possible problems.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How can I be sure that the pay out percentage in your games
                    is fair?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        The Casino is based on the software which is certified
                        by Itech Labs, an ISO/IEC 17025 compliant Australian
                        testing laboratory for online gaming systems. This means
                        that the RNG (Random Number Generator) complies with the
                        industry requirements, and all the results you get
                        during your play (reels in slots and cards dealt in card
                        games) are random. Payouts for all games are also
                        standard for the industry and are between 95 – 98%.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    What happens if my Internet connection fails?
                  </div>
                </div>
                <div
                  className="collapse collapse--exiting"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        If your Internet connection fails, you will be able to
                        resume the game from the point where the game stopped
                        after the connection is restored. The exception is
                        multiplayer games where other players also take part in
                        betting.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-and-conditions__block">
              <p className="terms-and-conditions__title heading1 secondary">
                Promotional Offers
              </p>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How do I claim my Welcome bonus?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        To claim your Welcome bonus, register an account at
                        www.crossgaming.com and simply make a deposit of at
                        least $/€20. Once your payment is made, the bonus will
                        instantly be added your account.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">Can I withdraw my bonus?</div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Yes, you can withdraw your bonus but only after the
                        bonus wagering requirement has been met. If you request
                        to withdraw before having reached the minimum wagering
                        requirements, the bonus and the winnings will be void.
                        To see the wagering requirements of a particular bonus,
                        please visit the{" "}
                        <a href="https://www.crossgaming.com/bonus-terms">
                          'Bonus terms and conditions page'
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">Where can I see my bonuses?</div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Once you make a deposit which meets the conditions of a
                        particular bonus, the corresponding bonus will be
                        automatically credited to your balance. To see all the
                        bonuses, please click the 'My Bonuses' tab in 'My
                        Account' once logged in. Bonuses are also shown as a
                        separate column in the 'My Balance' section of 'My
                        Account'.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="terms-and-conditions__block">
              <p className="terms-and-conditions__title heading1 secondary">
                Privacy &amp; Security
              </p>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    How does Cross Gaming protect my privacy?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        Cross Gaming uses SSL technology and the latest
                        anti-fraud techniques to guarantee full financial
                        security of players, so paying with your credit card you
                        can be 100% sure it is safe. We also keep your personal
                        information private and only use it for the purposes of
                        providing you with gaming services. For more
                        information, please visit our{" "}
                        <a href="https://www.crossgaming.com/privacy-policy">
                          'Privacy Policy page'
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="collapse-block">
                <div className="collapse-block__toggle">
                  <div className="collapse-block__icon">
                    <AddOutlinedIcon />
                  </div>
                  <div className="subtitle2">
                    I want to register as an affiliate at Cross Gaming. How can
                    I do it?
                  </div>
                </div>
                <div
                  className="collapse"
                  style={{ transitionDuration: "300ms" }}
                >
                  <div className="collapse__content-wrapper">
                    <div className="collapse__content">
                      <div className="collapse-block__content body2">
                        You need to go through the standard registration process
                        and sign in. Then, click the 'My Account' button at the
                        top of the page and choose the 'Affiliates' tab. After
                        that, click the 'Create Affiliate Link' and fill in the
                        required fields. Each affiliate can set up multiple
                        'Affiliate Profiles'. For each Affiliate Profile
                        created, the system will automatically assign a new
                        unique URL link that the affiliate can use for
                        promotion. This link may be used as it is or coded into
                        an Affiliate Banner. For more information on the
                        conditions of our affiliate program, please email us at{" "}
                        <a
                          target="_self"
                          href="mailto:affiliates@crossgaming.com"
                        >
                          affiliates@crossgaming.com
                        </a>
                        .
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </main>
  );
}

export default FAQ;
