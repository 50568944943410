import React from 'react'
import lightning_roulette from '../../container/assets/lightning_roulette.png';
import WolfGold from '../../container/assets/WolfGold.png';
import BookofDead from '../../container/assets/BookofDead.png'
import { Link } from 'react-router-dom';


function GameSlider() {
    return (
        <div className="slick-slider game-slider common-slider slick-initialized" dir="ltr">
            <div className="slick-list">
                <div
                    className="slick-track"
                    style={{
                        opacity: 1,
                        transform: `translate3d(0px, 0px, 0px)`
                    }}>

                    <div data-index="-3" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div
                                className="game-slider__item game-slider__list"
                                tabIndex="-1"
                                style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}
                            >
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Lightning Roulette game tile" src={lightning_roulette} />
                                        <img className="game__img image image--hidden" alt="Lightning Roulette game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title">
                                                    <Link
                                                        className="game__link link-no-styles" 
                                                        variant="primary" 
                                                        to="https://www.crossgaming.com/en-AU/game/lightning-roulette">
                                                        Lightning Roulette
                                                    </Link>
                                                </div>
                                                <div className="game__provider">evolution</div>
                                            </div>
                                            <div className="game__action">
                                                <button className="game__play">
                                                    <img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div>
                                        <button className="text-btn game__favorite" variant="primary">
                                            <i className="icon-heart"></i>
                                        </button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="-2" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div
                                className="game-slider__item game-slider__list"
                                tabIndex="-1"
                                style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Wolf Gold game tile" src={WolfGold} />
                                        <img className="game__img image image--hidden" alt="Wolf Gold game tile" src={WolfGold} />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/wolf-gold">Wolf Gold</a></div>
                                                <div className="game__provider">pragmaticplay</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="-1" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div
                                className="game-slider__item game-slider__list"
                                tabIndex="-1"
                                style={{
                                    width: '100%',
                                    display: 'inline-block'

                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Book of Dead game tile" src={BookofDead} />
                                        <img className="game__img image image--hidden" alt="Book of Dead game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-dead">Book of Dead</a></div>
                                                <div className="game__provider">playngo</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                    
                    <div 
                        data-index="0" 
                        className="slick-slide slick-active slick-current" 
                        tabIndex="-1" 
                        aria-hidden="false" 
                        style={{
                            outline: 'none'
                        }}>
                        <div>
                            <div 
                                className="game-slider__item game-slider__list" 
                                tabIndex="-1" 
                                style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Lightning Roulette game tile" src={lightning_roulette} />
                                        <img className="game__img image image--hidden" alt="Lightning Roulette game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title">
                                                    <a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/lightning-roulette">Lightning Roulette</a></div>
                                                <div className="game__provider">evolution</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="1" className="slick-slide" tabIndex="-1" aria-hidden="true" 
                        style={{ outline: 'none' }}>
                        <div>
                            <div className="game-slider__item game-slider__list" tabIndex="-1" style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Wolf Gold game tile" src={WolfGold} />
                                        <img className="game__img image image--hidden" alt="Wolf Gold game tile" src={WolfGold} />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/wolf-gold">Wolf Gold</a></div>
                                                <div className="game__provider">pragmaticplay</div>
                                            </div>
                                            <div className="game__action">
                                                <button className="game__play">
                                                    <img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="2" className="slick-slide" tabIndex="-1" aria-hidden="true" style={{ outline: 'none' }}>
                        <div>
                            <div className="game-slider__item game-slider__list" tabIndex="-1" style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap"><img className="game__img image" alt="Book of Dead game tile" src={BookofDead} />
                                    <img className="game__img image image--hidden" alt="Book of Dead game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-dead">Book of Dead</a></div>
                                                <div className="game__provider">playngo</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="3" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div className="game-slider__item game-slider__list" tabIndex="-1" style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap"><img className="game__img image" alt="Lightning Roulette game tile" src={lightning_roulette} />
                                    <img className="game__img image image--hidden" alt="Lightning Roulette game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/lightning-roulette">Lightning Roulette</a></div>
                                                <div className="game__provider">evolution</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div data-index="4" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div className="game-slider__item game-slider__list" tabIndex="-1" style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img className="game__img image" alt="Wolf Gold game tile" src={WolfGold} />
                                        <img className="game__img image image--hidden" alt="Wolf Gold game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title">
                                                    <a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/wolf-gold">Wolf Gold</a></div>
                                                <div className="game__provider">pragmaticplay</div>
                                            </div>
                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                    <div data-index="5" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div className="game-slider__item game-slider__list" tabIndex="-1" style={{
                                    width: '100%',
                                    display: 'inline-block'
                                }}>
                                <article className="game--xs game game--user">
                                    <div className="game__wrap"><img className="game__img image" alt="Book of Dead game tile" src={BookofDead} />
                                    <img className="game__img image image--hidden" alt="Book of Dead game tile" src="" />
                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-dead">Book of Dead</a></div>
                                                <div className="game__provider">playngo</div>
                                            </div>
                                            <div className="game__action"><button className="game__play">
                                                <img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                        </div>
                                        <button className="text-btn game__favorite" variant="primary">
                                            <i className="icon-heart"></i>
                                        </button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSlider
