import React from 'react';

import banner1 from '../../container/assets/vip/vipBackground-sm.png';  
import banner2 from '../../container/assets/vip/vipBackground-lg.png';  


function VIP() {
  return (
     <main className="layout__main">
                <div className="vip">
                    <div className="vip__banner">
                        <img className="vip__banner-img vip__banner-img--mobile" src={banner1} alt=""/>
                        <img className="vip__banner-img vip__banner-img--desktop" src={banner2} alt=""/>
                        <p className="vip__banner-new heading1 secondary">Seasonal VIP Program</p>
                        <h2 className="vip__banner-heading heading2">Mystery Prizes</h2>
                        <p className="vip__banner-timer-text">Raise your level!</p>
                        <div className="blocks-timer vip__banner-timer">
                            <div className="blocks-timer__item">
                                <div className="blocks-timer__value">13</div>
                                <div className="blocks-timer__text">days</div>
                            </div>
                            <div className="blocks-timer__item">
                                <div className="blocks-timer__value">08</div>
                                <div className="blocks-timer__text">hours</div>
                            </div>
                            <div className="blocks-timer__item">
                                <div className="blocks-timer__value">34</div>
                                <div className="blocks-timer__text">minutes</div>
                            </div>
                            <div className="blocks-timer__item">
                                <div className="blocks-timer__value">38</div>
                                <div className="blocks-timer__text">seconds</div>
                            </div>
                        </div>
                    </div>
                    <div className="vip__content">
                        <div className="vip__content-inner container--small container">
                            <div className="vip__content-info">
                                <p>With each new VIP-level, you will get a special Mystery Prize! It could be free spins, money, or something else! Raise your level and prove that you are worthy of the crown!</p>
                            </div>
                            <div className="vip__content-rules-wrapper"><button className="text-btn subtitle3 vip__content-rules" variant="primary">Rules</button></div>
                            <div className="vip-page">
                                <div className="vip-page__all-levels">
                                    <p className="vip-page__title">All levels</p>
                                    <div className="slick-slider vip-page__slider landing-game-slider slick-initialized">
                                        <div className="slick-list">
                                            <div className="slick-track" style={{ "width": "1302px", "opacity": 1, "transform": "translate3d(0px, 0px, 0px)" }}>
                                                <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Newbie</p>
                                                                <p className="vip-page__slide-text">200 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/1.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/1(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="1" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Gambler</p>
                                                                <p className="vip-page__slide-text">2500 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/2.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/2(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="2" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Professional</p>
                                                                <p className="vip-page__slide-text">5000 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/3.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/3(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="3" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Master</p>
                                                                <p className="vip-page__slide-text">10000 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/4.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/4(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="4" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Guru</p>
                                                                <p className="vip-page__slide-text">15000 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/5.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/5(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="5" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">Sensei</p>
                                                                <p className="vip-page__slide-text">20000 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/6.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/6(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="6" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style={{ "outline": "none", "width": "186px" }}>
                                                    <div>
                                                        <div className="vip-page__slide" tabIndex="-1" style={{ "width": "100%", "display": "inline-block" }}>
                                                            <div className="vip-page__slide-top">
                                                                <p className="vip-page__slide-level">King</p>
                                                                <p className="vip-page__slide-text">25000 CP</p>
                                                            </div>
                                                            <div className="vip-page__slide-info">
                                                                <img className="vip-page__slide-prize-img image" src="./assets/7.svg"/>
                                                                <p className="vip-page__slide-text">Mystery Prize</p>
                                                                <div className="vip-page__slide-divider"></div>
                                                                <img className="vip-page__slide-cashback-img image" src="./assets/7(1).svg"/>
                                                                <p className="vip-page__slide-text">Cashback</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="vip-program" className="vip__loyalty">
                                <h6>How works VIP program?</h6>
                                <p>For each 20 EUR (or currency equivalents) of real bets in slots, you get one comp point. Once you have accumulated a sufficient amount of comp points, you automatically reach the next VIP level.</p>
                                <p>Even the very first VIP level allows you to claim cashback on Monday!</p>
                                <ul>
                                    <li>Cashback is available once a week on Monday (12:00—13:00 UTC);</li>
                                    <li>Cashback is available for players who lost more than €1 during the week;</li>
                                    <li>Cashback is valid for 7 days;</li>
                                    <li>The maximum size of the cashback is 5,000 EUR;</li>
                                    <li>The cashback wager is 5x.</li>
                                    <li>One high bet free spin is equal to 1 EUR bet.</li>
                                    <li>To activate the reward for the achievement of the new level you are to activate it in your profile.</li>
                                    <li>All comp points earned are set to zero on the first Monday of the month at 13:00 UTC—14:00 UTC.</li>
                                    <li>Exchange redeemable comp points rate is 1.00 EUR or its equivalents per 100 points.</li>
                                </ul>
                                <p>The cashback depends on the VIP level:</p>
                                <ul>
                                    <li>Newbie (200-2,499 CP) gets 5% cashback.</li>
                                    <li>Gambler (2,500-4,999 CP) gets 6% cashback.</li>
                                    <li>Professional (5,000-9,999 CP) gets 7% cashback.</li>
                                    <li>Master (10,000-14,999 CP) gets 8% cashback.</li>
                                    <li>Guru (15,000-19,999 CP) gets 9% cashback.</li>
                                    <li>Sensei (20,000-24,999 CP) gets 10% cashback.</li>
                                    <li>King (25,000 CP and more) gets 15% cashback.</li>
                                </ul>
                                <p>This month will be full of surprises! With each new VIP-level, you will get a special Mystery Prize! It could be free spins, money, or something else!</p>
                                <p>Raise your level and prove that you are worthy of the crown!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
  )
}

export default VIP