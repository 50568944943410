import React from 'react';
import { Link } from 'react-router-dom';

import logo_lightning_roulette from '../../container/assets/providers/lightning_roulette.webp';
import buttonPlay from '../../container/assets/button__play.svg';

function GameSlider() {
    return (
        <div className="slick-slider game-slider common-slider slick-initialized" dir="ltr">
            <div className="slick-list">
                <div
                    className="slick-track"
                    style={{
                        "opacity": "1",
                        "transform": "translate3d(0px, 0px, 0px)"
                    }}>

                    <div data-index="-3" tabIndex="-1" className="slick-slide slick-cloned" aria-hidden="true">
                        <div>
                            <div
                                className="game-slider__item game-slider__list"
                                tabIndex="-1"
                                style={{
                                    "width": "100%",
                                    "display": "inline-block"
                                }}
                            >
                                <article className="game--xs game game--user">
                                    <div className="game__wrap">
                                        <img
                                            className="game__img image"
                                            alt="Lightning Roulette game tile"
                                            src={logo_lightning_roulette}
                                        />

                                        <img
                                            className="game__img image image--hidden"
                                            alt="Lightning Roulette game tile"
                                            src={logo_lightning_roulette}
                                        />

                                        <div className="game__content-wrap">
                                            <div className="game__content">
                                                <div className="game__title">
                                                    <Link
                                                        className="game__link link-no-styles"
                                                        variant="primary"
                                                        to="/game/lightning-roulette">
                                                        Lightning Roulette
                                                    </Link>
                                                </div>
                                                <div className="game__provider">evolution</div>
                                            </div>

                                            <div className="game__action">
                                                <button className="game__play">
                                                    <img
                                                        className="game__button image"
                                                        src={buttonPlay}
                                                    />
                                                </button>
                                            </div>
                                        </div>

                                        <button className="text-btn game__favorite" variant="primary">
                                            <i className="icon-heart"></i>
                                        </button>
                                        <div className="game__label-list"></div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameSlider