import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";


import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

function ResendPassword() {
  const navigate = useNavigate();

  const [formValue, setFormValue] = useState({
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formValue.email) {
      console.log("Enter required fields.");
      return;
    }
    // Rend Request
  };

  const { email } = formValue;

  return (
    <div id="modal-root">
      <div
        id="resend-unlock-instructions"
        className="email-modal modal modal--entered"
      >
        <div
          className="modal__backdrop"
          style={{ transitionDuration: "300ms" }}
        ></div>
        <div
          className="modal__content"
          style={{ maxWidth: "480px", transitionDuration: "300ms" }}
        >
          <section className="user-page-container resend-unlock-instructions">
            <h1 className="heading5 resend-unlock-instructions__title">
              resend unlock instructions
            </h1>
            <p className="text resend-unlock-instructions__description">
              If your account has been locked, fill in your e-mail address and
              we will send you unlock instructions via e-mail.
            </p>
            <form className="auth-form" onSubmit={handleSubmit} >
              <div className="auth-form__form-element-wrap">
                <div className="form-element">
                  <label className="input input--empty">
                    <input
                      className="input__native"
                      name="email"
                      type="email"
                      autoComplete="off"
                      placeholder=" "
                      value={email}
                      onChange={handleChange}
                      required
                    />
                    <span className="input__label">your e-mail address</span>
                    <span className="input__focus"></span>
                  </label>
                  <div
                    className="collapse collapse--entered"
                    style={{ transitionDuration: "300ms", height: "19px" }}
                  >
                    <div className="collapse__content-wrapper">
                      <div className="collapse__content">
                        <div className="form-element__error">
                          must be completed
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="captcha-term-and-policy">
                This site is protected by reCAPTCHA and the Google&nbsp;
                <Link
                  className="captcha-term-and-policy__link"
                  to="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </Link>{" "}
                and &nbsp;
                <Link
                  className="captcha-term-and-policy__link"
                  to="https://policies.google.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </Link>{" "}
                apply.
              </p>
              <button
                className="btn btn--danger btn--large btn--block"
                type="submit"
              >
                resend unlock instructions
              </button>
              <Link
                className="auth-form__sign link-text-btn"
                to="/login"
                variant="primary"
              >
                <ArrowBackOutlinedIcon />
                <span style={{ marginLeft: "10px" }}>Sign in</span>
              </Link>
              <div className="auth-form__instructions">
                <Link
                  className="auth-form__link link"
                  variant="primary"
                  to="/forgotpassword"
                >
                  Forgot Your Password?
                </Link>
              </div>
            </form>
          </section>
          <button 
            className="text-btn modal__close-button" 
            variant="primary"
            onClick={ () => navigate(`/`)}
            >
            <CloseOutlinedIcon />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResendPassword;
