import React from 'react';
import { Link } from 'react-router-dom';


function PaymentWidthrow() {
    return (
        <div className="payment-tabs">
            <p className="payment-tabs-title heading1 secondary">Withdrawal</p>
            <table className="payment-table__table">
                <tr className="payment-table-head">
                    <th>Method</th>
                    <th>Type</th>
                    <th>Comission</th>
                    <th>Processing Time</th>
                    <th>Minimal Withdrawal</th>
                    <th></th>
                </tr>

                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img 
                                    className="payment-table__method-img image" 
                                    src="./assets/visa.svg" 
                                    alt="img" 
                                />
                            </div>
                        </div>
                    </td>
                    <td>Credit/Debit Card</td>
                    <td>Free</td>
                    <td>1—3 Banking days</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>

                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/mastercard.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Credit/Debit Card</td>
                    <td>Free</td>
                    <td>1—3 Banking days</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/bank_transfer.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Bank Transfer</td>
                    <td>Free</td>
                    <td>3—5 Banking days</td>
                    <td>100 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/skrill.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/neteller.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/ecopayz.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/idebit.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Banking</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 CAD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/instadebit.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-mifinity.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-muchbetter.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-pay4fun.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/astropay_card.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-card</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/astropay_direct.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-payretailers.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Varies</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-interac.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Banking</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>30 CAD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/bitcoin_coinspaid_b.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.0002 BTC</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/ethereum_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.01 ETH</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/litecoin_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.01 LTC</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/BTC_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.001 BCH</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/dogecoin_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>1 DOGE</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src="./assets/tether.svg" alt="img" />
                            </div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0,01 USDT</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>

            </table>
        </div>
    )
}

export default PaymentWidthrow