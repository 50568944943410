import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "./context/AuthContext";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import Casino from "./components/Casino/Casino";
import LiveCasino from "./components/LiveCasino/LiveCasino";
import Tournaments from "./components/Tournaments/Tournaments";
import Notification from "./components/Notification/Notification";
import Lobby from "./components/Lobby/Lobby";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import FooterPanel from "./components/Footer/FooterPanel";
import Payments from "./components/Payments/Payments";
import Bonuses from "./components/Bonuses/Bonuses";
import BonusTerms from "./components/BonusTerms/BonusTerms";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Terms from "./components/Terms/Terms";
import Profile from "./components/Profile/Profile";
import FAQ from "./components/FAQ/FAQ";
import ResponsibleGaming from "./components/ResponsibleGaming/ResponsibleGaming";
import Support from "./components/Support/Support";
import VIP from "./components/VIP/VIP";
import Popup from "./components/Popup/Popup";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import Deposit from "./components/Wallet/Deposit";
import ProfileHeader from "./components/Profile/ProfileHeader";
import ResendPassword from "./components/Authentication/ResendPassword";
import ProfileResponsibleGaming from "./components/Profile/ResponsibleGaming/ProfileResponsibleGaming";
import GameHistory from "./components/Profile/GameHistory/GameHistory";
import TransactionHistory from "./components/Profile/Transactions/TransactionHistory";
import Promo from "./components/Profile/Promo/Promo";
import Withdrawal from "./components/Wallet/Withdrawal";
import Balance from "./components/Wallet/Balance";
import ProfileVerification from "./components/Profile/ProfileVerification/ProfileVerification";
import ProfileSecurity from "./components/Profile/ProfileSecurity/ProfileSecurity";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Keno from "./components/Keno/Keno";
import TVBET from "./components/LiveCasino/TvBet";
import Aviator from "./components/Aviator/Aviator";
import RestrictedRegion from "./components/RestrictedRegion/RestrictedRegion";
import FastGames from "./components/FastGames/FastGames";
import VerifyEmail from "./components/VerifyEmail";
import Plinko from "./components/Plinko";

// Scroll To Top
function Wrapper({ children }) {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
}

function Routerelement(props) {
  const { user, isAuthenticated } = useContext(AuthContext);
  //const navigate = useNavigate();
  const routeLocation = useLocation();

  useEffect(() => {}, [isAuthenticated]);

  return (
    <>
      {/*<Profile/>*/}
      <div className="layout">
        <Header />
        <Wrapper>
          <Routes>
            {/* Header Menu */}
            <Route index path="/" element={<Home />} />
            <Route path="casino" element={<Casino />} />
            <Route path="live-casino" element={<LiveCasino />} />
            <Route path="tvbet" element={<TVBET />} />
            <Route path="keno80" element={<Keno />} />
            <Route path="aviator" element={<Aviator />} />
            <Route path="fast-games" element={<FastGames />} />
            <Route path="plinko" element={<Plinko />} />

            {/* Authentication */}
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgotpassword" element={<ForgotPassword />} />
            <Route path="resend-unlock" element={<ResendPassword />} />
            <Route path="verify/:id" element={<VerifyEmail />} />

            <Route path="payments" element={<Payments />} />
            <Route path="bonuses" element={<Bonuses />} />
            <Route path="vip" element={<VIP />} />
            <Route path="tournaments" element={<Tournaments />} />

            <Route path="bonus-terms" element={<BonusTerms />} />
            <Route path="responsible-gaming" element={<ResponsibleGaming />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-and-conditions" element={<Terms />} />
            <Route path="support" element={<Support />} />

            {/* Profile Links */}
            <Route path="profile/general" element={<Profile />} />
            <Route path="profile/promo" element={<Promo />} />
            <Route
              path="profile/verification"
              element={<ProfileVerification />}
            />
            <Route path="profile/security" element={<ProfileSecurity />} />
            <Route path="profile/gamehistory" element={<GameHistory />} />
            <Route
              path="profile/responsible-gaming"
              element={<ProfileResponsibleGaming />}
            />

            {/* Wallet */}
            <Route path="wallet/deposit" element={<Deposit />} />
            <Route path="wallet/balance" element={<Balance />} />
            <Route path="wallet/withdrawal" element={<Withdrawal />} />
            <Route
              path="wallet/transaction"
              element={<TransactionHistory />}
            />

            <Route path="lobby" element={<Lobby />} />
            <Route path="popup" element={<Popup />} />

            {/*<Route path="restricted-region" element={<RestrictedRegion />} />*/}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Wrapper>
        <Footer />

        {/* ----------------------------------------------------------------- */}

        {/*<Wrapper>
          <Routes>
            <Route path="/profile/general" element={<Profile />} />
            <Route path="/profile/promo" element={<Promo />} />
            <Route path="/profile/verification" element={<ProfileVerification />} />
            <Route path="/profile/security" element={<ProfileSecurity />} />
            <Route path="/profile/gamehistory" element={<GameHistory />} />
            <Route path="/profile/responsible-gaming" element={<ProfileResponsibleGaming />} />

            <Route path="/wallet/deposit" element={<Deposit />} />
            <Route path="/wallet/balance" element={<Balance />} /> 
            <Route path="/wallet/withdrawal" element={<Withdrawal />} />
            <Route path="/wallet/transaction" element={<TransactionHistory />} />
            
            <Route path="*" element={<PageNotFound />} />

          </Routes>
      </Wrapper>*/}
      </div>

      {<FooterPanel />}
    </>
  );
}

export default Routerelement;
