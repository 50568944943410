import React from 'react';
import { Link } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../container/assets/partners', false, /\.(png|jpe?g|svg)$/));

function CatalogItem(props) {
    return (
        <div>
        {
            Object.keys(images).map((keyName, i) => (
                <div key={i} className="catalog__item">
                    <article className="games-block-default__game-item game--md game game--mystery">
                        <div className="game__wrap">
                            <span></span>
                            <img
                                className="game__img image"
                                alt="Sweet Bonanza Candyland game tile"
                                src={images[keyName]}
                            />
                            <img
                                className="game__img image image--hidden"
                                alt="Sweet Bonanza Candyland game tile"
                                src={images[keyName]}
                            />
                            <div className="game__content-wrap">
                                <div className="game__content">
                                    <div className="game__title">
                                        <a
                                            className="game__link link-no-styles"
                                            variant="primary"
                                            href="/game/sweet-bonanza-candyland"
                                        >
                                            Sweet Bonanza Candyland
                                        </a>
                                    </div>
                                    <div className="game__provider">pragmaticplaylive</div>
                                </div>
                                <div className="game__action">
                                    <button className="game__play">
                                        <img
                                            className="game__button image"
                                            src="./assets/button__play.svg"
                                        />
                                    </button>

                                    <button className="plain-btn game__demo btn--demo" variant="primary">
                                        demo
                                    </button>
                                </div>
                            </div>
                            <div className="game__label-list"></div>
                        </div>
                    </article>
                </div>

            ))
        }
        </div>

    )
}

export default CatalogItem