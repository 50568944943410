import React from 'react';
import { Link } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
  
const images = importAll(require.context('../../container/assets/payment', false, /\.(png|jpe?g|svg|webp)$/));

function PaymentDeposit() {
    console.log(images);
    return (
        <div className="payment-tabs">
            <p className="payment-tabs-title heading1 secondary">Deposit</p>
            <table className="payment-table__table">
                <tr className="payment-table-head">
                    <th>Method</th>
                    <th>Type</th>
                    <th>Comission</th>
                    <th>Processing Time</th>
                    <th>Minimal Deposit</th>
                    <th></th>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img 
                                    className="payment-table__method-img image" 
                                    src={images["visa.svg"]}
                                    alt="img" 
                                    />
                            </div>
                        </div>
                    </td>
                    <td>Credit Card</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["mastercard.svg"]} alt="img" /></div>
                        </div>
                    </td>
                    <td>Credit Card</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td>
                        <Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["maestro.svg"]} alt="img" /></div>
                        </div>
                    </td>
                    <td>Credit Card</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td>
                        <Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link>
                    </td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["skrill.svg"]}  alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["neteller.svg"]} alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["ecopayz.svg"]}  alt="img" />
                            </div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/neosurf-color.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Voucher</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/idebit.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 CAD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-interac.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>15 CAD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-mifinity.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-muchbetter.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/trustly-trustly.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Banking</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-pay4fun.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/astropay_card.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-card</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/astropay_direct.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>E-wallet</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>10 USD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-payretailers.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Varies</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 EUR</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/devcode-flexepin.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Voucher</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>20 CAD</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/bitcoin_coinspaid_b.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.0002 BTC</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap"><img className="payment-table__method-img image" src="./assets/ethereum_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.01 ETH</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src="./assets/litecoin_coinspaid.svg" alt="img" />
                            </div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.01 LTC</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src={images["BTC_coinspaid.svg"]} alt="img" />
                            </div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0.001 BCH</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src="./assets/dogecoin_coinspaid.svg" alt="img" /></div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>1 DOGE</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
                <tr className="payment-table-row">
                    <td>
                        <div className="payment-table__card-wrap">
                            <div className="payment-table__image-wrap">
                                <img className="payment-table__method-img image" src="./assets/tether.svg" alt="img" />
                            </div>
                        </div>
                    </td>
                    <td>Сryptocurrency</td>
                    <td>Free</td>
                    <td>Instant</td>
                    <td>0,01 USDT</td>
                    <td><Link className="bar__auth-btn deposit-button btn link-btn btn--primary" to="/register">Register</Link></td>
                </tr>
            </table>
        </div>
    )
}

export default PaymentDeposit