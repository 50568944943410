import React from 'react';
import HomeGamesLove from './HomeGamesLove';

import HomeHeader from './HomeHeader';
import HomeHeaderPayment from './HomeHeaderPayment';

function Home() {
    return (
        <main className="layout__main">
            <div className="home">
                <HomeHeader/>
                <HomeHeaderPayment/>
                
            {/*
            <div className="home__games-love-bg home__games-love-bg">

                <div className="home-games-love">
                    <div className="home-games-love__container home-games-love__wrap container--small container">
                    <HomeGamesLove/>        

                    <div className="landing-game home-games-love__games">
                        <div className="slick-slider landing-game__slider landing-game-slider slick-initialized" dir="ltr">

                            <div className="slick-list">
                                <div className="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px);">
                                    <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style="outline: none; width: 0px;">
                                        <div>
                                            <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                <article className="game--xs game">
                                                    <div className="game__wrap">
                                                        <img className="game__img image" alt="Book of Dead game tile" src="./assets/BookofDead.webp"><img className="game__img image image--hidden" alt="Book of Dead game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/book-of-dead">Book of Dead</a></div>
                                                                            <div className="game__provider">playngo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>


                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="The Dog House game tile" src="./assets/TheDogHouse.webp"><img className="game__img image image--hidden" alt="The Dog House game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/the-dog-house">The Dog House</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div data-index="1" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Tiger Jungle game tile" src="./assets/TigerJungle.webp"><img className="game__img image image--hidden" alt="Tiger Jungle game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/tiger-jungle">Tiger Jungle</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Outback Gold: Hold and Win game tile" src="./assets/OutbackGoldHoldandWin.webp">
                                                                    <img className="game__img image image--hidden" alt="Outback Gold: Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/outback-gold-hold-and-win">Outback Gold: Hold and Win</a></div>
                                                                            <div className="game__provider">isoftbet</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="2" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Gates of Olympus game tile" src="./assets/GatesOfOlympus1.webp">
                                                                    <img className="game__img image image--hidden" alt="Gates of Olympus game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/gates-of-olympus">Gates of Olympus</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Dragon&#39;s Gold 100 game tile" src="./assets/DragonsGold100.webp">
                                                                    <img className="game__img image image--hidden" alt="Dragon&#39;s Gold 100 game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/dragons-gold-100">Dragon's Gold 100</a></div>
                                                                            <div className="game__provider">bgaming</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="3" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Sword of Khans game tile" src="./assets/SwordofKhans_tk.webp"><img className="game__img image image--hidden" alt="Sword of Khans game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sword-of-khans">Sword of Khans</a></div>
                                                                            <div className="game__provider">thunderkick</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Dragon Pearls: hold and win game tile" src="./assets/DragonPearls.webp">
                                                                    <img className="game__img image image--hidden" alt="Dragon Pearls: hold and win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/dragon-pearls-hold-and-win">Dragon Pearls: hold and win</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="4" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Bigger Bass Bonanza game tile" src="./assets/BiggerBassBonanza.webp">
                                                                    <img className="game__img image image--hidden" alt="Bigger Bass Bonanza game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/bigger-bass-bonanza">Bigger Bass Bonanza</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Elvis Frog in Vegas game tile" src="./assets/ElvisFroginVegas.webp">
                                                                    <img className="game__img image image--hidden" alt="Elvis Frog in Vegas game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/elvis-frog-in-vegas">Elvis Frog in Vegas</a></div>
                                                                            <div className="game__provider">bgaming</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="5" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Temple Tumble game tile" src="./assets/TempleTumble.webp"><img className="game__img image image--hidden" alt="Temple Tumble game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/temple-tumble">Temple Tumble</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Eagle Power: Hold and WIn game tile" src="./assets/EaglePowerHoldandWIn.webp">
                                                                    <img className="game__img image image--hidden" alt="Eagle Power: Hold and WIn game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/eagle-power-hold-and-win">Eagle Power: Hold and WIn</a></div>
                                                                            <div className="game__provider">playson</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div data-index="6" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Ghost of Dead game tile" src="./assets/GhostofDead.webp"><img className="game__img image image--hidden" alt="Ghost of Dead game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/ghost-of-dead">Ghost of Dead</a></div>
                                                                            <div className="game__provider">playngo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Majestic Megaways game tile" src="./assets/MajesticMegaways.webp">
                                                                    <img className="game__img image image--hidden" alt="Majestic Megaways game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/majestic-megaways">Majestic Megaways</a></div>
                                                                            <div className="game__provider">isoftbet</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div data-index="7" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Buffalo Power Hold and Win game tile" src="./assets/BuffaloPowerHoldandWin.webp">
                                                                    <img className="game__img image image--hidden" alt="Buffalo Power Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/buffalo-power-hold-and-win">Buffalo Power Hold and Win</a></div>
                                                                            <div className="game__provider">playson</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Hell Hot 100 game tile" src="./assets/endorphina2_HellHot100.webp">
                                                                    <img className="game__img image image--hidden" alt="Hell Hot 100 game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/hell-hot-100">Hell Hot 100</a></div>
                                                                            <div className="game__provider">endorphina</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button></div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="8" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Royal Coins: Hold and Win game tile" src="./assets/RoyalCoinsHoldandWin.webp">
                                                                    <img className="game__img image image--hidden" alt="Royal Coins: Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/royal-coins-hold-and-win">Royal Coins: Hold and Win</a></div>
                                                                            <div className="game__provider">playson</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Aloha King Elvis game tile" src="./assets/AlohaKingElvis.webp">
                                                                    <img className="game__img image image--hidden" alt="Aloha King Elvis game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/aloha-king-elvis">Aloha King Elvis</a></div>
                                                                            <div className="game__provider">bgaming</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="9" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Deadwood game tile" src="./assets/Deadwood1.webp"><img className="game__img image image--hidden" alt="Deadwood game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/deadwood">Deadwood</a></div>
                                                                            <div className="game__provider">nolimit</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Hit the Gold! Hold and Win game tile" src="./assets/HittheGoldHoldandWin.webp">
                                                                    <img className="game__img image image--hidden" alt="Hit the Gold! Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/hit-the-gold-hold-and-win">Hit the Gold! Hold and Win</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="10" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Sweet Bonanza game tile" src="./assets/SweetBonanza.webp"><img className="game__img image image--hidden" alt="Sweet Bonanza game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sweet-bonanza">Sweet Bonanza</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Money Train 2 game tile" src="./assets/MoneyTrain2.webp"><img className="game__img image image--hidden" alt="Money Train 2 game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/money-train-2">Money Train 2</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="11" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="15 Dragon Pearls: Hold and Win game tile" src="./assets/15DragonPearlsHoldandWin.webp">
                                                                    <img className="game__img image image--hidden" alt="15 Dragon Pearls: Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/15-dragon-pearls-hold-and-win">15 Dragon Pearls: Hold and Win</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="WBC Ring of Riches game tile" src="./assets/WbcRingOfRiches.webp">
                                                                    <img className="game__img image image--hidden" alt="WBC Ring of Riches game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/wbc-ring-of-riches">WBC Ring of Riches</a></div>
                                                                            <div className="game__provider">bgaming</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="12" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Book of Truth game tile" src="./assets/BookofTruth94.webp"><img className="game__img image image--hidden" alt="Book of Truth game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/book-of-truth">Book of Truth</a></div>
                                                                            <div className="game__provider">truelab</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Gonzo&#39;s Gold game tile" src="./assets/gonzosgold_r1_not_mobile_sw.webp">
                                                                    <img className="game__img image image--hidden" alt="Gonzo&#39;s Gold game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/gonzos-gold">Gonzo's Gold</a></div>
                                                                            <div className="game__provider">netent</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="13" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Iron Bank game tile" src="./assets/IronBank.webp"><img className="game__img image image--hidden" alt="Iron Bank game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/iron-bank">Iron Bank</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Templar Tumble game tile" src="./assets/TemplarTumble.webp"><img className="game__img image image--hidden" alt="Templar Tumble game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/templar-tumble">Templar Tumble</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="14" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Starburst game tile" src="./assets/starburst_not_mobile_sw.webp">
                                                                    <img className="game__img image image--hidden" alt="Starburst game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/starburst">Starburst</a></div>
                                                                            <div className="game__provider">netent</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Rise of Merlin game tile" src="./assets/RiseofMerlin.webp"><img className="game__img image image--hidden" alt="Rise of Merlin game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/rise-of-merlin">Rise of Merlin</a></div>
                                                                            <div className="game__provider">playngo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="15" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Fruit Party 2 game tile" src="./assets/FruitParty2.webp"><img className="game__img image image--hidden" alt="Fruit Party 2 game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/fruit-party-2">Fruit Party 2</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="3 Coins game tile" src="./assets/3Coins.webp"><img className="game__img image image--hidden" alt="3 Coins game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/3-coins">3 Coins</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="16" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="San Quentin game tile" src="./assets/SanQuentin1.webp"><img className="game__img image image--hidden" alt="San Quentin game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/san-quentin">San Quentin</a></div>
                                                                            <div className="game__provider">nolimit</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Top Dawg$ game tile" src="./assets/TopDawg.webp"><img className="game__img image image--hidden" alt="Top Dawg$ game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/top-dawg">Top Dawg$</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="17" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Wild Spin game tile" src="./assets/wildspin.webp"><img className="game__img image image--hidden" alt="Wild Spin game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/wild-spin">Wild Spin</a></div>
                                                                            <div className="game__provider">platipus</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Legacy of Dead game tile" src="./assets/LegacyofDead.webp"><img className="game__img image image--hidden" alt="Legacy of Dead game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/legacy-of-dead">Legacy of Dead</a></div>
                                                                            <div className="game__provider">playngo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="18" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Wolf Power: Hold and Win game tile" src="./assets/WolfPower.webp">
                                                                    <img className="game__img image image--hidden" alt="Wolf Power: Hold and Win game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/wolf-power-hold-and-win">Wolf Power: Hold and Win</a></div>
                                                                            <div className="game__provider">playson</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Crypts of Fortune game tile" src="./assets/CryptsofFortune96.webp">
                                                                    <img className="game__img image image--hidden" alt="Crypts of Fortune game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/crypts-of-fortune">Crypts of Fortune</a></div>
                                                                            <div className="game__provider">truelab</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="19" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Fruit Shop game tile" src="./assets/fruitshop_not_mobile_sw.webp">
                                                                    <img className="game__img image image--hidden" alt="Fruit Shop game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/fruit-shop">Fruit Shop</a></div>
                                                                            <div className="game__provider">netent</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="El Paso Gunfight xNudge game tile" src="./assets/ElPaso.webp"><img className="game__img image image--hidden" alt="El Paso Gunfight xNudge game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/el-paso-gunfight-xnudge">El Paso Gunfight xNudge</a></div>
                                                                            <div className="game__provider">nolimit</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="20" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Big Bass Bonanza game tile" src="./assets/BigBassBonanza.webp">
                                                                    <img className="game__img image image--hidden" alt="Big Bass Bonanza game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/big-bass-bonanza">Big Bass Bonanza</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Gonzo&#39;s Quest Megaways game tile" src="./assets/GonzosQuestMegaways.webp">
                                                                    <img className="game__img image image--hidden" alt="Gonzo&#39;s Quest Megaways game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/gonzos-quest-megaways">Gonzo's Quest Megaways</a></div>
                                                                            <div className="game__provider">redtiger</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="21" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Circus Fruits game tile" src="./assets/CircusFruits94.webp"><img className="game__img image image--hidden" alt="Circus Fruits game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/circus-fruits">Circus Fruits</a></div>
                                                                            <div className="game__provider">truelab</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Gold Digger game tile" src="./assets/GoldDigger.webp"><img className="game__img image image--hidden" alt="Gold Digger game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/gold-digger">Gold Digger</a></div>
                                                                            <div className="game__provider">isoftbet</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="22" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Book of Shadows game tile" src="./assets/BookofShadows1.webp"><img className="game__img image image--hidden" alt="Book of Shadows game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/book-of-shadows">Book of Shadows</a></div>
                                                                            <div className="game__provider">nolimit</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="John Hunter and the Book of Tut game tile" src="./assets/JohnHunterandtheBookofTut.webp">
                                                                    <img className="game__img image image--hidden" alt="John Hunter and the Book of Tut game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/john-hunter-and-the-book-of-tut">John Hunter and the Book of Tut</a></div>
                                                                            <div className="game__provider">pragmaticplay</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="23" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Fruit Million game tile" src="./assets/FruitMillion.webp"><img className="game__img image image--hidden" alt="Fruit Million game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/fruit-million">Fruit Million</a></div>
                                                                            <div className="game__provider">bgaming</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Reactoonz game tile" src="./assets/Reactoonz_desktop.webp"><img className="game__img image image--hidden" alt="Reactoonz game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/reactoonz">Reactoonz</a></div>
                                                                            <div className="game__provider">playngo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="24" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Sun of Egypt 2 game tile" src="./assets/SunOfEgypt2.webp"><img className="game__img image image--hidden" alt="Sun of Egypt 2 game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sun-of-egypt-2">Sun of Egypt 2</a></div>
                                                                            <div className="game__provider">booongo</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Wild Chapo game tile" src="./assets/WildChapo.webp"><img className="game__img image image--hidden" alt="Wild Chapo game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/wild-chapo">Wild Chapo</a></div>
                                                                            <div className="game__provider">relax</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="landing-game__blocks">
                                        <div className="landing-game__blocks-item">
                                            <article className="game--lg game">
                                                <div className="game__wrap"><img className="game__img image" alt="Book of Dead game tile" src="./assets/BookofDead.webp"><img className="game__img image image--hidden" alt="Book of Dead game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/book-of-dead">Book of Dead</a></div>
                                                            <div className="game__provider">playngo</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="The Dog House game tile" src="./assets/TheDogHouse.webp"><img className="game__img image image--hidden" alt="The Dog House game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/the-dog-house">The Dog House</a></div>
                                                            <div className="game__provider">pragmaticplay</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Tiger Jungle game tile" src="./assets/TigerJungle.webp"><img className="game__img image image--hidden" alt="Tiger Jungle game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/tiger-jungle">Tiger Jungle</a></div>
                                                            <div className="game__provider">booongo</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Outback Gold: Hold and Win game tile" src="./assets/OutbackGoldHoldandWin.webp">
                                                    <img className="game__img image image--hidden" alt="Outback Gold: Hold and Win game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/outback-gold-hold-and-win">Outback Gold: Hold and Win</a></div>
                                                            <div className="game__provider">isoftbet</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>

                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Gates of Olympus game tile" src="./assets/GatesOfOlympus1.webp"><img className="game__img image image--hidden" alt="Gates of Olympus game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/gates-of-olympus">Gates of Olympus</a></div>
                                                            <div className="game__provider">pragmaticplay</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Dragon&#39;s Gold 100 game tile" src="./assets/DragonsGold100.webp"><img className="game__img image image--hidden" alt="Dragon&#39;s Gold 100 game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/dragons-gold-100">Dragon's Gold 100</a></div>
                                                            <div className="game__provider">bgaming</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Sword of Khans game tile" src="./assets/SwordofKhans_tk.webp"><img className="game__img image image--hidden" alt="Sword of Khans game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sword-of-khans">Sword of Khans</a></div>
                                                            <div className="game__provider">thunderkick</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Dragon Pearls: hold and win game tile" src="./assets/DragonPearls.webp"><img className="game__img image image--hidden" alt="Dragon Pearls: hold and win game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/dragon-pearls-hold-and-win">Dragon Pearls: hold and win</a></div>
                                                            <div className="game__provider">booongo</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Bigger Bass Bonanza game tile" src="./assets/BiggerBassBonanza.webp"><img className="game__img image image--hidden" alt="Bigger Bass Bonanza game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/bigger-bass-bonanza">Bigger Bass Bonanza</a></div>
                                                            <div className="game__provider">pragmaticplay</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Elvis Frog in Vegas game tile" src="./assets/ElvisFroginVegas.webp"><img className="game__img image image--hidden" alt="Elvis Frog in Vegas game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/elvis-frog-in-vegas">Elvis Frog in Vegas</a></div>
                                                            <div className="game__provider">bgaming</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Temple Tumble game tile" src="./assets/TempleTumble.webp"><img className="game__img image image--hidden" alt="Temple Tumble game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/temple-tumble">Temple Tumble</a></div>
                                                            <div className="game__provider">relax</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Eagle Power: Hold and WIn game tile" src="./assets/EaglePowerHoldandWIn.webp"><img className="game__img image image--hidden" alt="Eagle Power: Hold and WIn game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/eagle-power-hold-and-win">Eagle Power: Hold and WIn</a></div>
                                                            <div className="game__provider">playson</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Ghost of Dead game tile" src="./assets/GhostofDead.webp"><img className="game__img image image--hidden" alt="Ghost of Dead game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/ghost-of-dead">Ghost of Dead</a></div>
                                                            <div className="game__provider">playngo</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game__blocks-item">
                                            <article className="game--xs game">
                                                <div className="game__wrap"><img className="game__img image" alt="Majestic Megaways game tile" src="./assets/MajesticMegaways.webp"><img className="game__img image image--hidden" alt="Majestic Megaways game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/majestic-megaways">Majestic Megaways</a></div>
                                                            <div className="game__provider">isoftbet</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-games-love__content home-games-love__mobile">
                                    <div className="home-games-love__btns-wrap"><a className="home-games-love__btn btn link-btn btn--danger" href="https://www.crossgaming.com/games">Explore now</a></div>
                                    <div className="home-games-logos">
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/elk"><img className="home-games-logos__img image" src="./assets/elk.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/pragmatic"><img className="home-games-logos__img image" src="./assets/pragmatic.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/playngo"><img className="home-games-logos__img image" src="./assets/playngo.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/yggdrasil"><img className="home-games-logos__img image" src="./assets/yggdrasil.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/amatic"><img className="home-games-logos__img image" src="./assets/amatic.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/endorphina"><img className="home-games-logos__img image" src="./assets/endorphina.svg"/></a>
                                        <a className="home-games-logos__item link" variant="primary" href="https://www.crossgaming.com/games/all/egt"><img className="home-games-logos__img image" src="./assets/egt.svg"/></a><button className="home-games-logos__btn-more text">And more</button></div>
                                </div>
                            </div>
                        </div>
                        <div className="home-popular-game-categories">
                            <div className="home-popular-game-categories__container container--small container">
                                <h3 className="home-popular-game-categories__title">Popular game categories</h3>
                                <div className="slick-slider categories-game-slider home-popular-game-categories__categories landing-game-slider slick-initialized" dir="ltr">
                                    <div className="slick-list">
                                        <div className="slick-track" style="width: 6590px; opacity: 1; transform: translate3d(0px, 0px, 0px);">
                                            <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style="outline: none;">
                                                <div>
                                                    <a className="categories-game-slider__item categories-game-slider__item--slots link-text-btn" href="https://www.crossgaming.com/games/slots" variant="primary" tabIndex="-1" style="width: 100%; display: inline-block;"><img className="categories-game-slider__item-image image" alt="Slots" src="./assets/slots.png"/>
                                                        <h4 className="categories-game-slider__item-title">Slots</h4>
                                                        <div className="categories-game-slider__item-count"><span className="categories-game-slider__item-count">4570 Games</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div data-index="1" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none;">
                                                <div>
                                                    <a className="categories-game-slider__item categories-game-slider__item--jackpots link-text-btn" href="https://www.crossgaming.com/games/jackpot" variant="primary" tabIndex="-1" style="width: 100%; display: inline-block;"><img className="categories-game-slider__item-image image" alt="Jackpot" src="./assets/jackpots.png"/>
                                                        <h4 className="categories-game-slider__item-title">Jackpot</h4>
                                                        <div className="categories-game-slider__item-count"><span className="categories-game-slider__item-count">657 Games</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div data-index="2" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none;">
                                                <div>
                                                    <a className="categories-game-slider__item categories-game-slider__item--live link-text-btn" href="https://www.crossgaming.com/games/live-games" variant="primary" tabIndex="-1" style="width: 100%; display: inline-block;"><img className="categories-game-slider__item-image image" alt="Live" src="./assets/live.png"/>
                                                        <h4 className="categories-game-slider__item-title">Live</h4>
                                                        <div className="categories-game-slider__item-count"><span className="categories-game-slider__item-count">419 Games</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div data-index="3" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none;">
                                                <div>
                                                    <a className="categories-game-slider__item categories-game-slider__item--table-games link-text-btn" href="https://www.crossgaming.com/games/table" variant="primary" tabIndex="-1" style="width: 100%; display: inline-block;"><img className="categories-game-slider__item-image image" alt="Table games" src="./assets/table-games.png"/>
                                                        <h4 className="categories-game-slider__item-title">Table games</h4>
                                                        <div className="categories-game-slider__item-count"><span className="categories-game-slider__item-count">95 Games</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div data-index="4" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none;">
                                                <div>
                                                    <a className="categories-game-slider__item categories-game-slider__item--drops-wins link-text-btn" href="https://www.crossgaming.com/games/drops-and-wins" variant="primary" tabIndex="-1" style="width: 100%; display: inline-block;"><img className="categories-game-slider__item-image image" alt="Drops &amp; Wins" src="./assets/drops-wins.png"/>
                                                        <h4 className="categories-game-slider__item-title">Drops &amp; Wins</h4>
                                                        <div className="categories-game-slider__item-count"><span className="categories-game-slider__item-count">25 Games</span></div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home-true-wins">
                        <div className="home-true-wins__container container--small container">
                            <div className="home-true-wins__content">
                                <h3 className="home-true-wins__title">True Wins are closer than they seem</h3>
                                <p className="home-true-wins__description subtitle2">Total Jackpot</p>
                                <h4 className="home-true-wins__count">€30,246,437</h4>
                                <div className="home-true-wins__btns-wrap"><a className="home-true-wins__btn home-true-wins__btn--sm home-true-wins__btn--desk btn link-btn btn--danger" href="https://www.crossgaming.com/games/jackpot">See all Jackpots</a></div>
                            </div>
                            <div className="home-true-wins__games">
                                <div className="landing-game-wins-slider home-true-wins__slider">
                                    <div className="slick-slider landing-game-wins-slider__slider landing-game-slider slick-initialized" dir="ltr">
                                        <div className="slick-list">
                                            <div className="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px);">
                                                <div data-index="0" className="slick-slide slick-active slick-current" tabIndex="-1" aria-hidden="false" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game-wins-slider__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Sisters of Oz WOWPOT game tile" src="./assets/MGS_SistersOfOzWowPot.webp">
                                                                    <img className="game__img image image--hidden" alt="Sisters of Oz WOWPOT game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sisters-of-oz-wowpot">Sisters of Oz WOWPOT</a></div>
                                                                            <div className="game__provider">quickfire</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game-wins-slider__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Mega Moolah The Witch&#39;s Moon game tile" src="./assets/MGS_megaMoolahTheWitchsMoonDesktop.webp">
                                                                    <img className="game__img image image--hidden" alt="Mega Moolah The Witch&#39;s Moon game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/mega-moolah-the-witchs-moon">Mega Moolah The Witch's Moon</a></div>
                                                                            <div className="game__provider">quickfire</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list">
                                                                        <div className="game__label game__label--new">new</div>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="1" className="slick-slide slick-active" tabIndex="-1" aria-hidden="false" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game-wins-slider__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Divine Fortune game tile" src="./assets/godsoffortune_not_mobile_sw.webp">
                                                                    <img className="game__img image image--hidden" alt="Divine Fortune game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/divine-fortune">Divine Fortune</a></div>
                                                                            <div className="game__provider">netent</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="landing-game-wins-slider__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Mega Moolah game tile" src="./assets/MGS_MegaMoolah.webp"><img className="game__img image image--hidden" alt="Mega Moolah game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/mega-moolah">Mega Moolah</a></div>
                                                                            <div className="game__provider">quickfire</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-index="2" className="slick-slide" tabIndex="-1" aria-hidden="true" style="outline: none; width: 0px;">
                                                    <div>
                                                        <div className="landing-game-wins-slider__slider-item" tabIndex="-1" style="width: 100%; display: inline-block;">
                                                            <article className="game--xs game">
                                                                <div className="game__wrap"><img className="game__img image" alt="Shining Crown game tile" src="./assets/ShiningCrown.webp"><img className="game__img image image--hidden" alt="Shining Crown game tile" src="">
                                                                    <div className="game__content-wrap">
                                                                        <div className="game__content">
                                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/shining-crown">Shining Crown</a></div>
                                                                            <div className="game__provider">egt</div>
                                                                        </div>
                                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="game__label-list"></div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                    <div></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="landing-game-wins-slider__blocks">
                                        <div className="landing-game-wins-slider__blocks-item">
                                            <article className="game--lg game">
                                                <div className="game__wrap"><img className="game__img image" alt="Sisters of Oz WOWPOT game tile" src="./assets/MGS_SistersOfOzWowPot.webp"><img className="game__img image image--hidden" alt="Sisters of Oz WOWPOT game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/sisters-of-oz-wowpot">Sisters of Oz WOWPOT</a></div>
                                                            <div className="game__provider">quickfire</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game-wins-slider__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Mega Moolah The Witch&#39;s Moon game tile" src="./assets/MGS_megaMoolahTheWitchsMoonDesktop.webp">
                                                    <img className="game__img image image--hidden" alt="Mega Moolah The Witch&#39;s Moon game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/mega-moolah-the-witchs-moon">Mega Moolah The Witch's Moon</a></div>
                                                            <div className="game__provider">quickfire</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list">
                                                        <div className="game__label game__label--new">new</div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game-wins-slider__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Divine Fortune game tile" src="./assets/godsoffortune_not_mobile_sw.webp"><img className="game__img image image--hidden" alt="Divine Fortune game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/divine-fortune">Divine Fortune</a></div>
                                                            <div className="game__provider">netent</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game-wins-slider__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Mega Moolah game tile" src="./assets/MGS_MegaMoolah.webp"><img className="game__img image image--hidden" alt="Mega Moolah game tile" src="">
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/mega-moolah">Mega Moolah</a></div>
                                                            <div className="game__provider">quickfire</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                        <div className="landing-game-wins-slider__blocks-item">
                                            <article className="game--sm game">
                                                <div className="game__wrap"><img className="game__img image" alt="Shining Crown game tile" src="./assets/ShiningCrown.webp"><img className="game__img image image--hidden" alt="Shining Crown game tile" src="">
                                                    
                                                    
                                                    <div className="game__content-wrap">
                                                        <div className="game__content">
                                                            <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/game/shining-crown">Shining Crown</a></div>
                                                            <div className="game__provider">egt</div>
                                                        </div>
                                                        <div className="game__action"><button className="game__play"><img className="game__button image" src="./assets/button__play.svg"/></button>
                                                            <button className="plain-btn game__demo btn--demo" variant="primary">demo</button>
                                                        </div>
                                                    </div>
                                                    <div className="game__label-list"></div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </div>
                                <div className="home-true-wins__btns-wrap"><a className="home-true-wins__btn home-true-wins__btn--sm home-true-wins__btn--mob btn link-btn btn--danger" href="https://www.crossgaming.com/games/jackpot">See all Jackpots</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="home-play-more">
                        <div className="home-play-more__container container--small container">
                            <div className="home-play-more__info">
                                <h3 className="heading3 home-play-more__title">Choose your adventure!</h3>
                                <p className="home-play-more__description text">There are different ways to play at Cross. Compete with the other players, progress through the ranks of VIP program, bet on sports! Experience all of them! You just have to decide what are you going to do first...</p>
                                <div className="home-play-more__btns-wrap"><a className="home-play-more__btn btn link-btn btn--danger" href="https://www.crossgaming.com/tournaments">Choose!</a></div>
                            </div>
                            <div className="home-play-more__items">
                                <a className="home-play-more__item home-play-more__item--vip link-text-btn" href="https://www.crossgaming.com/vip" variant="primary"><img className="home-play-more__item-image image" alt="VIP program" src="./assets/vip--xs.d70ba1c0.png"/><img className="home-play-more__item-image home-play-more__item-image--md image" alt="VIP program" src="./assets/vip.97511023.png"/>
                                    <h6 className="home-play-more__item-title">VIP program</h6><span className="home-play-more__item-description">Everything counts! Prove yourself as dedicated player and climb the ladder of VIP program! Accumulate enough status point by making real bets, and you will achieve new VIP levels and unlock worthy prizes and benefits!</span></a>
                                <a className="home-play-more__item home-play-more__item--tournaments link-text-btn" href="https://www.crossgaming.com/tournaments" variant="primary"><img className="home-play-more__item-image image" alt="Exciting tournaments" src="./assets/tournaments--xs.6e09d6b0.png"/><img className="home-play-more__item-image home-play-more__item-image--md image" alt="Exciting tournaments"
                                        src="./assets/tournaments.f7126c0e.png"/>
                                    <h6 className="home-play-more__item-title">Spirit of competition!</h6>
                                </a>
                                <a className="home-play-more__item home-play-more__item--bonuses link-text-btn" href="https://www.crossgaming.com/bonuses" variant="primary"><img className="home-play-more__item-image image" alt="Up to 100% and other bonuses" src="./assets/bonuses--xs.721b3a7d.png"/><img className="home-play-more__item-image home-play-more__item-image--md image" alt="Up to 100% and other bonuses"
                                        src="./assets/bonuses.aee667fa.png"/>
                                    <h6 className="home-play-more__item-title">The jump-start! Up to 1,000 EUR Welcome Bonus!</h6>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="home-new-betting">
                        <div className="home-new-betting__container container--small container"><img className="home-new-betting__person-img image" src="./assets/new-betting.7d588251-optimized.png"/>
                            <div className="home-new-betting__info">
                                <h3 className="heading3 home-new-betting__title">Digital Stadium!</h3>
                                <p className="home-new-betting__description">Cross Sports, the new betting platform, invites you to bet on sports and cybersport. The perfect opportunity to experience trending one-click sports betting and combo-bonus from multiple bets.</p>
                                <div className="home-new-betting__btns-wrap"><a className="home-new-betting__btn btn link-btn btn--danger" href="https://sportsbet.crossgaming.com/">Bet now!</a></div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="home__overflow">
                        <div className="home-keep-calm">
                            <div className="home-keep-calm__container container--small container">
                                <h3 className="heading3 home-keep-calm__title">Keep calm with world perfect banking</h3>
                                <div className="home-keep-calm__items">
                                    <div className="home-keep-calm__item">
                                        <div className="home-keep-calm__item-img-wrap"><img className="home-keep-calm__item-img image" src="./assets/keep-calm-cash.c58c843b.svg"/></div>
                                        <div className="home-keep-calm__content">
                                            <h6 className="home-keep-calm__item-title">Deposit with no limits</h6>
                                            <p className="home-keep-calm__item-description text">Deposit in different currencies including the variety of crypto. Choose what is convenient for you.</p>
                                        </div>
                                    </div>
                                    <div className="home-keep-calm__item">
                                        <div className="home-keep-calm__item-img-wrap"><img className="home-keep-calm__item-img image" src="./assets/keep-calm-history.5f07c069.svg"/></div>
                                        <div className="home-keep-calm__content">
                                            <h6 className="home-keep-calm__item-title">Everything is in check</h6>
                                            <p className="home-keep-calm__item-description text">Detailed and transparent session history. Forget about confusion. Enjoy order.</p>
                                        </div>
                                    </div>
                                    <div className="home-keep-calm__item">
                                        <div className="home-keep-calm__item-img-wrap"><img className="home-keep-calm__item-img image" src="./assets/keep-calm-deposits.905f477b.svg"/></div>
                                        <div className="home-keep-calm__content">
                                            <h6 className="home-keep-calm__item-title">Feels like a real wallet</h6>
                                            <p className="home-keep-calm__item-description text">Deposit and withdrawal fast and unimpeded. Feel that your account is truly yours and within hand’s reach.</p>
                                        </div>
                                    </div>
                                    <div className="home-keep-calm__item">
                                        <div className="home-keep-calm__item-img-wrap"><img className="home-keep-calm__item-img image" src="./assets/keep-calm-privacy.7b41b1a1.svg"/></div>
                                        <div className="home-keep-calm__content">
                                            <h6 className="home-keep-calm__item-title">Privacy and confidentiality</h6>
                                            <p className="home-keep-calm__item-description text">There must be no buts and ifs in the matter of security. No bank details or sensitive data stored. You have 24/7 support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home-device"><button className="pwa-btn btn btn--primary"></button>
                            <div className="home-device__container container--small container">
                                <div className="home-device__info">
                                    <h3 className="heading3 home-device__title">Tap to spin!</h3>
                                    <p className="home-device__description">The online casino is not truly online if you can’t take it with you. Cross is always in your pocket! Make a bet on your smartphone during coffee break and hit the jackpot on your way home!</p><button className="home-device__btn btn btn--primary btn--large"><img className="home-device__icon image" src="./assets/pwa-icon.5ae9f382.svg"/><span> Install now</span></button></div>
                                <img className="home-device__image image" alt="Max fun on any device" src="./assets/device.c5964800.png"/></div>
                        </div>
                    </div>*/}



                </div>
            </main>
    )
}

export default Home
