import React from 'react'
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


function Search() {
    return (
        <Link
            className="header__search link-text-btn"
            to={"/?search"}
            variant="primary"
            >
            <SearchOutlinedIcon/>
        </Link>
    )
}

export default Search
