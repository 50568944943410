import AuthContext from '../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';

function ProfileSidebar() {
  const { user, setUser } = useContext(AuthContext);
  
    return (
        <div className="sc-fzomME jIpIlW sidebar__container">

            <div className="sc-fzoydu cqsFgi sidebar__header">
              <div className="sc-fzoYHE kvpgjy navigation__container"><a href="https://www.crossgaming.com/"
                  className="sc-fzplWN gwNWYc sc-fzpkqZ bfAUwT navigation__back-button sc-fzpkqZ bfAUwT navigation__back-button styled-button styled-button--primary styled-button--sm">Back
                  to Casino</a><button
                  className="sc-fzoJMP eFMCar sc-fznAgC jdARWm navigation__close-button text-button text-button--primary"
                  type="button"><i className="icon icon-close"></i></button></div>
              <div className="sc-fzowVh iOWyev player-account__wrapper">
                <div title="meliqbuild" className="sc-fzqzlV jzOxXE player-account__user-name">meliqbuild</div>
                <div className="sc-fzoxnE VkQBT player-account__common-balance-container">
                  <div className="sc-fzoJus gZgDfc player-account__container">
                    <div className="sc-fznLxA bOoBAE player-account__balance-value">0</div>
                    <div className="sc-fzqLLg ktHqpt player-account__balance-currency">EUR</div>
                  </div>
                </div>
                <div className="sc-fznXWL AfVto player-account__flex-container">
                  <div className="sc-fznLPX eftFOV account-balance-info__wrapper">
                    <div className="sc-fzoaKM cTTObP account-balance-info__sum-wrapper">
                      <div className="sc-fzomuh jNhATa account-balance-info__sum">0</div>
                    </div>
                    <div className="sc-fzqMdD ejgoSR account-balance-info__title">Withdrawable</div>
                  </div>
                  <div className="sc-fznLPX eftFOV account-balance-info__wrapper">
                    <div className="sc-fzoaKM cTTObP account-balance-info__sum-wrapper">
                      <div className="sc-fzomuh jNhATa account-balance-info__sum">0</div>
                    </div>
                    <div className="sc-fzqMdD ejgoSR account-balance-info__title">Locked by bonus</div>
                  </div>
                </div><a data-seo-id="deposit button profile main"
                  className="sc-fzplWN gwNWYc sc-fzokvW epsrXo player-account__deposit-button sc-fzokvW epsrXo player-account__deposit-button styled-button styled-button--secondary styled-button--sm"
                  variant="secondary" href="https://www.crossgaming.com/profile/wallet/deposit">Deposit</a>
              </div>
            </div>

            <div className="sc-fzqAbL ekefSR sidebar__menu">
              <div className="sc-fzqMAW fyYAFt sidebar__vertical-group"><a className="sc-fzoiQi PNtMq menu-item__row"
                  href="https://www.crossgaming.com/profile/promo">
                  <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-gift"></i><span
                      className="sc-fzqNqU hvGzuo menu-item__title">Promo</span></div>
                </a><a className="sc-fzoiQi PNtMq menu-item__row" href="https://www.crossgaming.com/profile/general">
                  <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-profile"></i><span
                      className="sc-fzqNqU hvGzuo menu-item__title">Profile</span></div>
                </a><a className="sc-fzoiQi PNtMq menu-item__row" href="https://www.crossgaming.com/profile/wallet">
                  <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-wallet"></i><span
                      className="sc-fzqNqU hvGzuo menu-item__title">Wallet</span></div>
                </a><a className="sc-fzoiQi PNtMq menu-item__row" href="https://www.crossgaming.com/profile/game_history">
                  <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-history"></i><span
                      className="sc-fzqNqU hvGzuo menu-item__title">Game History</span></div>
                </a><a className="sc-fzoiQi PNtMq menu-item__row active" href="https://www.crossgaming.com/profile/limits"
                  aria-current="page">
                  <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-responsible"></i><span
                      className="sc-fzqNqU hvGzuo menu-item__title">Responsible Gambling</span></div>
                </a></div>
              <div className="sc-fzokOt hQCeKK breakpoints--mobile-or-tablet">
                <div className="sc-fznWqX ezzqlh divider"></div>
                <div className="sc-fzqMAW fyYAFt sidebar__vertical-group">
                  <div name="language" role="combobox" aria-expanded="false" aria-haspopup="listbox"
                    aria-labelledby="downshift-2-label"
                    className="sc-fzolEj sc-fzoYkl dtysCG select__container select__container--language language-select__container">
                    <div className="sc-fzoiQi PNtMq menu-item__row">
                      <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-globe"></i><span
                          className="sc-fzqNqU hvGzuo menu-item__title">Language</span></div><span
                        className="sc-fzoyTs gbRuai menu-item__semi-bold-text">en-AU</span>
                    </div>
                  </div>
                </div>
                <div className="sc-fznWqX ezzqlh divider"></div>
                <div className="sc-fzqMAW fyYAFt sidebar__vertical-group">
                  <div className="sc-fzoiQi PNtMq menu-item__row">
                    <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-help"></i><span
                        className="sc-fzqNqU hvGzuo menu-item__title">Support</span></div>
                  </div>
                  <div className="sc-fzoiQi PNtMq menu-item__row">
                    <div className="sc-fzqARJ guQvBr menu-item__group"><i className="icon icon-logout"></i><span
                        className="sc-fzqNqU hvGzuo menu-item__title">Log out</span></div>
                  </div>
                </div>
                <div className="sc-fznWqX ezzqlh divider"></div>
                <div className="sc-fzqMAW fyYAFt sidebar__vertical-group"><a href="https://www.crossgaming.com/games"
                    className="sc-fzoiQi PNtMq menu-item__row">
                    <div className="sc-fzqARJ guQvBr menu-item__group"><span className="sc-fzqNqU hvGzuo menu-item__title">All
                        Games</span></div>
                  </a></div>
              </div>
            </div>

          </div>
    )
}
export default ProfileSidebar;