import React from 'react'
import enLogo from '../../container/assets/language/en.svg';


function LobbyLanguage() {
    return (
        <div className="header-game__locales player-language-select select-language-icons-with-code" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="lang-select-label">
                    <div className="select-language-icons-with-code__button" aria-autocomplete="list" aria-labelledby="lang-select-label" autoComplete="off" value="English - Australia" id="lang-select">
                        <a className="select-language-icons-with-code__link">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="en-AU" src={enLogo}/>
                            </div>
                            <div className="select-language-icons-with-code__code">English - Australia</div>
                        </a>
                        <div className="select-language-icons-with-code__caret">
                            <i className="icon-caret"></i>
                        </div>
                    </div>

                    <div className="select-language-icons-with-code__container">
                        <ul className="select-language-icons-with-code__list" role="listbox" aria-labelledby="lang-select-label" id="lang-select-menu">
                            
                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-0" role="option" aria-selected="false" href="https://www.crossgaming.com/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="en" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/en.svg"/>
                                    </div>English
                                </div>
                            </a>
                            {/*
                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-1" role="option" aria-selected="false" href="https://www.crossgaming.com/ru/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="ru" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/ru.svg"/>
                                    </div>Русский
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link option-link--selected link" disabled="" variant="primary" id="lang-select-item-2" role="option" aria-selected="false" href="https://www.crossgaming.com/en-AU/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="en-AU" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/en-AU.svg"/>
                                    </div>English - Australia
                                    <i className="select-language-icons-with-code__icon-check icon-check-small"></i>
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-3" role="option" aria-selected="false" href="https://www.crossgaming.com/en-NZ/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="en-NZ" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/en-NZ.svg"/>
                                    </div>English - New Zealand
                                </div>
                            </a>


                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-4" role="option" aria-selected="false" href="https://www.crossgaming.com/de/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="de" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/de.svg"/>
                                    </div>Deutsch
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-5" role="option" aria-selected="false" href="https://www.crossgaming.com/fi/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="fi" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/fi.svg"/>
                                    </div>Suomi
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-6" role="option" aria-selected="false" href="https://www.crossgaming.com/pl/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="pl" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/pl.svg"/>
                                    </div>Polski
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-7" role="option" aria-selected="false" href="https://www.crossgaming.com/es/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="es" 
                                            src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/es.svg"/>
                                    </div>Español
                                </div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-8" role="option" aria-selected="false" href="https://www.crossgaming.com/pt/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="pt" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/pt.svg"/></div>Português</div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-9" role="option" aria-selected="false" href="https://www.crossgaming.com/pt-BR/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="pt-BR" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/pt-BR.svg"/></div>Português - BR</div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-10" role="option" aria-selected="false" href="https://www.crossgaming.com/ja/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="ja" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/ja.svg"/></div>日本語</div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-11" role="option" aria-selected="false" href="https://www.crossgaming.com/no/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="no" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/no.svg"/></div>Norwegian</div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-12" role="option" aria-selected="false" href="https://www.crossgaming.com/en-CA/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="en-CA" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/en-CA.svg"/></div>English - Canada</div>
                            </a>

                            <a className="select-language-icons-with-code__link option-link link" variant="primary" id="lang-select-item-13" role="option" aria-selected="false" href="https://www.crossgaming.com/fr-CA/game/lightning-roulette">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img className="select-language-icons-with-code__img flag-icon image" alt="fr-CA" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/fr-CA.svg"/></div>Français - Canada</div>
                            </a>
                            */}
                        </ul>
                    </div>
                    
                </div>
    )
}

export default LobbyLanguage
