import React from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function GamesCategoryMenu() {
    return (
        <div className="game-category-menu">
            <div className="game-category-menu__menu"><a className="game-category-menu__item-link subtitle2 game-category-menu__item-link-active link" variant="primary" href="https://www.crossgaming.com/games/top"><i className="game-category-menu__item-icon icon-game-all"></i><span>Top Games</span></a>
                <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/slots"><i className="game-category-menu__item-icon icon-game-slots"></i><span>Slots</span></a>
                <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/jackpot"><i className="game-category-menu__item-icon icon-game-jackpots"></i><span>Jackpot</span></a>
                <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/live-games"><i className="game-category-menu__item-icon icon-game-live"></i><span>Live Casino</span></a>
                <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link__screen-xxl link" variant="primary" href="https://www.crossgaming.com/games/table"><i className="game-category-menu__item-icon icon-game-card-games"></i><span>Table Games</span></a>
                <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link__screen-xxl link" variant="primary" href="https://www.crossgaming.com/games/drops-and-wins"><i className="game-category-menu__item-icon icon-game-drop-wins"></i><span>Drops &amp; Wins</span></a>
                <div className="game-category-menu__item-link game-category-menu__item-link-features subtitle2">
                    <div className="game-features-select game-features-select--menu" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="game-features-label">
                        <div className="game-features-select__button" aria-autocomplete="list" aria-labelledby="game-features-label" autoComplete="off" value="" id="game-features"><i className="game-features-select__button-icon icon-game-all"></i>
                            <div>
                                <div className="game-features-select__button-inner subtitle2">game-features</div>
                                <div className="game-features-select__caret">
                                    <KeyboardArrowDownOutlinedIcon/>
                                </div>
                            </div>
                        </div>
                        <div className="game-features-select__content" role="listbox" aria-labelledby="game-features-label" id="game-features-menu">
                            <div className="game-features-select__list">
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/megaways">Megaways</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/scratch">Scratch cards</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/btc-games">BTC games</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/new">New games</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/hold-and-win">Hold &amp; Win</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/fast-play">Fast play</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GamesCategoryMenu
