import ProfileHeader from "./ProfileHeader";
import ProfileLayout from "./ProfileLayout";
import ProfileNotification from "./ProfileNotification";


function Profile() {
    return (
        <>
            <ProfileNotification/>
            <ProfileHeader/>
            <ProfileLayout/>
        </>

    )
}

export default Profile;