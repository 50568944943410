import React from 'react';
import { Link } from 'react-router-dom';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import englishFlag from '../../container/assets/language/en.svg';

function PopupHeader() {
    return (
        <header className="page-game__header header-game header--bg">
            <Link 
                className="header__back link" 
                variant="primary" 
                to="/"
                >
                <ArrowBackIosOutlinedIcon/>
                <span className="header__back-text">Go to Lobby</span>
            </Link>
            
            <Link 
                className="header-game__search link-text-btn" 
                to="?search-modal=modal" 
                variant="primary">
                <SearchOutlinedIcon/>
                <span className="header-game__search-placeholder">search-game</span>
            </Link>
            
            <Link 
                className="header-game__back link-text-btn" 
                to="/" 
                variant="primary">
                <ArrowBackIosOutlinedIcon/>
            </Link>
            
            <div className="header__block header__block--jackpot"></div>
            
            <div className="header-game__right">
                <div className="game-currency-select select-game-currency" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="game-currency-select-label">
                   
                    <div className="select-game-currency__button" aria-autocomplete="list" aria-labelledby="game-currency-select-label" autoComplete="off" value="EUR" id="game-currency-select">
                        <Link 
                            className="select-game-currency__link select-game-currency__link-select"
                            to="#">
                            EUR
                        </Link>
                        <div className="select-game-currency__arrow-down-wrapper">
                            <KeyboardArrowDownOutlinedIcon/>
                        </div>
                    </div>
                   
                    <div className="select-game-currency__container">
                        <ul className="select-game-currency__list" role="listbox" aria-labelledby="game-currency-select-label" id="game-currency-select-menu">
                            <div id="game-currency-select-item-0" role="option" aria-selected="false" className="select-game-currency__link option-link option-link--selected">
                                <div className="option-link__content">EUR
                                    <i className="select-game-currency__icon-check icon-check-small"></i>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
                
                <Link 
                    className="header-game__button-deposit deposit-button btn link-btn btn--primary" 
                    to="/wallet/deposit">
                    Deposit
                </Link>
                
                <div className="header-game__notification-center notification-center notification-center--empty" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-4-label">
                    <button className="text-btn notification-center__button" variant="primary">
                        <NotificationsOutlinedIcon/>
                        <div className="notification-center__count">0</div>
                    </button>
                    
                    <div className="notification-center__container" role="listbox" aria-labelledby="downshift-4-label" id="downshift-4-menu">
                        <div className="notification-center__list">
                            <div className="notification-center__empty">
                                <span className="notification-center__empty-text">No current notifications</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-game__user-menu-select select-user-menu" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-5-label">
                    <div className="select-user-menu__button" aria-autocomplete="list" aria-labelledby="downshift-5-label" autoComplete="off" value="" id="downshift-5-input">
                        <Person2OutlinedIcon/>
                    </div>
                </div>

                <div className="header-game__locales player-language-select select-language-icons-with-code" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="lang-select-label">
                   
                   <div className="select-language-icons-with-code__button" aria-autocomplete="list" aria-labelledby="lang-select-label" autoComplete="off" value="English - Australia" id="lang-select">
                        <Link 
                            className="select-language-icons-with-code__link"
                            to="#">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="en" 
                                    src={englishFlag} 
                                    />
                            </div>
                            <div className="select-language-icons-with-code__code">English</div>
                        </Link>
                        <div className="select-language-icons-with-code__caret">
                            <i className="icon-caret"></i>
                        </div>
                    </div>

                    <div className="select-language-icons-with-code__container">

                        <ul 
                            className="select-language-icons-with-code__list" 
                            role="listbox" 
                            aria-labelledby="lang-select-label" 
                            id="lang-select-menu">
                            
                            <Link 
                                className="select-language-icons-with-code__link option-link link" 
                                variant="primary" 
                                id="lang-select-item-0" 
                                role="option" 
                                aria-selected="false" 
                                to="#">
                                <div className="option-link__content">
                                    <div className="select-language-icons-with-code__icon">
                                        <img 
                                            className="select-language-icons-with-code__img flag-icon image" 
                                            alt="en" 
                                            src="./assets/en.svg" />
                                    </div>
                                    English
                                </div>
                            </Link>

                        </ul>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default PopupHeader