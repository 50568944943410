import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Auth from './Auth';
import Language from './Language';
import Profile from './Profile';
import AuthContext from '../../context/AuthContext';

function HeaderRight({ props }) {

    const { user, setUser } = useContext(AuthContext);
    //const navigate = useNavigate();

    useEffect( () => {
    }, [user]);

    return (
        <div className="header__right">
            {
                user ?  (
                        <Profile 
                            balance={` ${user.balance} ${user.currency} `}
                            firstName={` ${user.firstName} `}
                        />) 
                     :  (<Auth/>)
            }
            <Language/>
        </div>
    )
}

export default HeaderRight
