import React from 'react';
import { Link } from 'react-router-dom';

function BonusTerms() {
    return (
        <main className="layout__main">
            <div className="container">
                <div className="container__wrapper">
                    <h1 className="container__wrapper_title">Bonus Terms and Conditions</h1>
                    <div className="container__wrapper_updated">Last updated: 30.07.2021</div>
                    <p>Feel free to contact our customer support if you have any questions about Bonus Terms. Some bonuses may have their own terms. To learn more about particular bonuses, please read their Bonus Terms.</p>
                    <h2>1. General bonus terms and conditions</h2>
                    <p>1.1. Each available currency has a fixed <Link to="/bonus-terms/currency-equivalents">equivalent</Link> in relation to the other available currencies in terms of depositing, betting, and receiving prizes. These
                        equivalents apply unless otherwise stated.</p>
                    <p>1.2. The minimal deposit for receiving any deposit bonus is 20 EUR.</p>
                    <p>1.3. Regarding the bonuses and promotions, all times and dates are indicated in UTC TZ.</p>
                    <p>1.4. You can claim only one bonus at a time. Deposit-related bonuses do not stack. Only one bonus can be activated at a time.</p>
                    <p>1.5. All the bonuses can only be received once per person/account, family, household, address, e-mail address, credit card number, IP addresses, and environments where computers are shared (university, fraternity, school, public
                        library, workplace, etc.).</p>
                    <p>1.6. Cashback is a bonus unless otherwise specified. Any rules that apply to bonuses in general apply to cashback bonuses as well.</p>
                    <p>1.7. The maximum withdrawal limit from a no deposit bonus including free spins is 50 EUR. The maximum withdrawal limit from no deposit free spins is 50 EUR. The maximum win restriction persists after the completion of the wagering
                        requirement. The maximum you will ever be able to withdraw from play with funds associated with a (nodeposit) bonus is 50 EUR. An exception can be made for loyal players, and in case of winning, the maximum win amount can be
                        increased. To request a withdrawal, you must fully verify your account and make a minimum deposit.</p>
                    <p>1.8. The casino reserves the right to update Bonus Terms and Conditions at any time.</p>
                    <p>1.9. Bonus Terms and Conditions as published on the Website are in English, and it is the English version of these Terms and Conditions that forms the basis of these Terms and Conditions. Translations into other languages may be
                        made as a service and are made in good faith. However, in the event of differences between the English version and a translation, the English version has priority over any translation.</p>
                    <p>1.10. In the event of a dispute, all decisions made by the casino will be final.</p>
                    <p>1.11. All bonuses have a duration period and expire if not wagered before the set period ends.</p>
                    <p>1.12. Bonuses are automatically cancelled upon active balance reaching the amount of 0.05 EUR or less (or its equivalent). This term only applies to the balance tied to a bonus, i.e. deposit + bonus, or a bonus only (in case of
                        a no deposit bonus), and it doesn't apply to funds on the player's balance gained from other deposits or bonuses.</p>
                    <p>1.13. All bonuses are unavailable to the players from Sweden and Finland.</p>
                    <h2>2. Bonus Wagering</h2>
                    <p>2.1. The bonus must be wagered 50 times before the player can withdraw the winnings, unless otherwise specified. If the player requests the withdrawal before completing the wagering requirements, the bonus and winnings will be
                        void.
                    </p>
                    <p>2.2. The maximum bet while wagering is 5 EUR (or equivalent in another available currency), unless otherwise specified. The maximum bet limit includes bets doubling after a game round has been completed and bonus rounds (purchased
                        within the game). Breaching the maximum bet rule leads to all winnings from the bonus confiscated and deposit refunded.</p>
                    <p>2.3. All bonuses must be fully wagered prior to withdrawal. The bonus is lost when the bonus money associated with it is lost.</p>
                    <p>2.4. When using a deposit bonus, the real money from the player's account are wagered first and only then the bonus money.</p>
                    <p>2.5. The player has a right to cancel a deposit bonus before starting to wager it. If the player decides to cancel a deposit bonus, free spins will not be credited to the player's account either. The reason for it is that the free
                        spins are a part of a deposit bonus.</p>
                    <p>2.6. If the wagering requirements are not met within the period of the bonus validity, both the bonus money and winnings will be removed from the player's balance.</p>
                    <p>2.7. Any bonus can be cancelled before the player starts playing it through. If your bonus has been cancelled, please contact the casino support service to find out more about it.</p>
                    <p>2.8. Games excluded from the bonus wagering will not be available to play if the player has an active bonus.</p>
                    <p>2.9. Please, note that each game comes with its own wagering coefficient:</p>
                    <ul>
                        <li>Videopoker — 5%;</li>
                        <li>Baccarat, Blackjack, Hi-Lo, Roulette, Poker, and other table games — 5%;</li>
                        <li>Slots — 100% (except for the games listed below);</li>
                        <li>Live games — 5%.</li>
                    </ul>
                    <p>2.10. The casino management reserves the right to restrict the accessibility of the games listed in article 5.4. while playing with an active bonus.</p>
                    <p>2.11. A player can change currency only after the first stage of the welcome bonus has been completed.</p>
                    <h2>3. Welcome Package</h2>
                    <p>3.1. The player can get a bonus for each of the three initial deposits. Such bonuses are credited automatically when the player makes at least the minimal deposit required to receive the bonus:</p>
                    <ul>
                        <li>First Deposit Bonus makes 100% of the deposit amount and 50 FS. The maximum bonus amount is 200 EUR. The player will get 25 free spins per day for 2 days (50 free spins in total). Free spins will be credited in Book of Dead
                            (Play'n GO). If Play'n GO is not available in your region, free spins will be credited in Book of Cats slot (BGaming). For Australian players, the FS prize will be credited to Wolf Treasure (IGtech).</li>
                        <li>Second Deposit Bonus makes 50% of the deposit amount. The player should make a deposit by using the promo code <b>RELAX</b> to get this bonus. The maximum bonus amount is 300 EUR.&nbsp;</li>
                        <li>Third Deposit Bonus makes 50% of the deposit amount and 50 FS. The player should make a deposit by using the promo code <b>ACTNOW</b> to get this bonus. The maximum bonus amount is 500 EUR. FS will be credited to Book of Dead
                            (Play’n GO). If the slot for the FS prize is not available in the player’s region, standard FS will be credited to Aztec Magic Deluxe (BGaming). For Australian players, the FS prize will be credited to Wolf Treasure (IGtech).</li>
                    </ul>
                    <p>3.2. Unclaimed bonus free spins expire in 7 days after being credited to the player’s account.</p>
                    <p>3.3. Claimed money bonuses expire in 7 days and free spins bonuses expire in 3 days unless stated otherwise. Any not wagered bonus remaining at the end of the stated period will be removed from the player’s account.</p>
                    <h2>4. Reload Bonuses</h2>
                    <p>4.1. There are three recurring Reload bonuses available for each player starting with their 4th deposit.&nbsp;</p>
                    <p>Monday Reload Bonus. This bonus is available once each Monday. The player should make a deposit by using the promo code <b>MON40</b> to get this bonus. The bonus makes 40% of the deposit amount. The maximum bonus amount is 100
                        EUR.
                    </p>
                    <p>Wednesday Reload Bonus. This bonus is available once each Wednesday and grants up FS. The player should make a deposit by using the promo code <b>BOOK</b> to get this bonus. The number of FS depends on the amount of the deposit:</p>
                    <ul>
                        <li>Deposit 20 EUR or more to get 20 FS;</li>
                        <li>Deposit 50 EUR or more to get 50 FS;</li>
                        <li>Deposit 100 EUR or more to get 100 FS.</li>
                    </ul>
                    <p>High-roller Reload. This bonus is available once per two weeks since the last time the player claimed it. The player should deposit at least 500 EUR by using the promo code HIGHROLL to get this bonus. The maximum bonus amount is
                        1 000 EUR.</p>
                    <p>4.2. Unclaimed bonus free spins expire in 7 days after being credited to the player’s account.</p>
                    <p>4.3. Claimed money bonuses expire in 7 days and free spins bonuses expire in 3 days unless stated otherwise. Any not wagered bonus remaining at the end of the stated period will be removed from the player’s account.</p>
                    <h2>5. Restricted countries, games, and ways to play</h2>
                    <p>5.1. The Casino has the right to revoke any bonuses and winnings, as well as to confiscate all the money in the account if it has been obtained dishonestly or in violation of these rules.</p>
                    <p>5.2. The casino management reserves the right to restrict the accessibility to the games listed in the article 5.4. in the following countries: Bulgaria, Bosnia and Herzegovina, Croatia, Greece, Hungary, Serbia, Kazakhstan, Macedonia,
                        Republic of Moldova, Indonesia, Philippines, Georgia, Sierra Leone, Spain, France, Turkey, Estonia, Slovakia, Anguilla, Sweden.</p>
                    <p>5.3. Players from the following countries can not use no deposit bonuses: Afghanistan, Albania, Algeria, Angola, Anguilla, Australia, Bahrain, Bangladesh, Belgium, Benin, Bosnia and Herzegovina, Botswana, Bulgaria, Burundi, Cambodia,
                        Cameroon, Cape Verde, Chad, Comoros, Côte d'Ivoire, Democratic People's Republic of Croatia, Ecuador, Egypt, Equatorial Guinea, Eritrea, Estonia, Ethiopia, France, Gambia, Georgia, Ghana, Greece, Guinea, Guinea-Bissau, Guyana,
                        Hungary, India, Indonesia, Iraq, Islamic Republic of Iran, Israel, Italy, Kazakhstan, Kenya, Korea, Kuwait, Lao People's Democratic Republic, Lesotho, Liberia, Macedonia, Madagascar, Malawi, Malaysia, Mali, Mauritania, Mauritius,
                        Mexico, Mongolia, Montenegro, Morocco, Mozambique, Myanmar, Namibia, Nepal, Nicaragua, Niger, Nigeria, Oman, Pakistan, Palestine, Panama, Papua New Guinea,&nbsp; Philippines, Poland, Portugal, Republic of Macedonia, Republic
                        of Moldova, Republic of Romania, Rwanda, Sao Tome and Principe, Senegal, Serbia, Seychelles, Sierra Leone, Slovakia, Slovenia, South Sudan, Spain, Sudan, Eswatini, Sweden, Syrian Arab Republic, Taiwan, Thailand, Togo, Tunisia,
                        Turkey, Uganda,&nbsp; Ukraine, United Arab Emirates, United Kingdom, United Republic of Tanzania, United States of America, Vietnam, Yemen, Zambia, Zimbabwe.</p>
                    <p>5.4. The casino management reserves the right to restrict the accessibility to the following games if the player has an active bonus:&nbsp;<span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1 Reel Egypt,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1 Reel Fruits,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1 Reel Halloween,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1 Reel Monkey,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1 Reel Xmas,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">100 Bit Dice,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">1429 Uncharted Seas,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">3 Diamonds,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">3 Secret Cities,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">5 Families,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">9 Lions,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">9 Lions Xmas Edition,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Africa X UP,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">African Quest,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Age of Conquest,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Alchemy Blast,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Alchymedes,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Alm Gaudi,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Ambiance,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Ancient Eclipse,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Ancients Blessing,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Andar Bahar,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Astro Legends: Lyra and Erion,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Augustus,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Aztec Spins,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Baccarat,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Baccarat NC,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Baker's Treat,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bangkok Dreams,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bastet and Cats,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Battle Heroes,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Beautiful Bones,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Belle Epoque,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Biergarten,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Biergarten Unlimited,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bikini Party,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bison Battle,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Black Horse,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Black Horse Deluxe,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bloxx Fruit,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bloxx Zeus,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bollywood Billions,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bombuster,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book Of Tribes Reloaded,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book of 99,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book of Oz,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book of Shadows,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book of the East,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Book of the West,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bookie of Odds,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Break Da Bank Again Respin,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Bronco Spirit,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Butterfly Lovers,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Calliga,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Caribbean Cannons,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cash Elevator,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Castle Builder II,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cauldron,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cazino Cosmos,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cheeky Charlie,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cheeky Charlie 2,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cherry Fortune,&nbsp;</button></span><span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cool Buck,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Crypts of Fortune,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Crystal Cavern,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Crystals Digger,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Cupid Strike 2,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dark Vortex,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dead or Alive,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dead or Alive 2,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dead or Alive 2 Feature Buy,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dia De Muertos,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Diamond Blitz,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Diamond Fortune,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dino Odyssey,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dishes to Riches,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Double Dragons,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dr. Jekyll &amp; Mr. Hyde,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link" variant="primary">Dragon Dance,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Dragon King H5,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Dragon Kingdom - Eyes of Fire,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Dwarf Mine,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Dynamite Riches,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Egypt King,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">El Andaluz,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Elite of Evil: Portal of Gold,&nbsp;</button></span>
                        <span className="text-link__wrap"><button className="text-btn game__text-link"
                            variant="primary">Endless Hair,&nbsp;</button></span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Everlasting
                                Spins,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Extra
                                Win,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Eye
                                of
                                the
                                Kraken,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Fairy
                                Hollow,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Fairybelle,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Five
                                Princesses,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Flaming
                                Sevens,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Flower
                                Fortunes,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Fluffy
                                Rangers,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Football
                                Glory,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Four
                                the
                                Win,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Frequent
                                Flyer,&nbsp;</button>
                        </span>
                        <span className="text-link__wrap">
                            <button
                                className="text-btn game__text-link"
                                variant="primary">Fresh
                                Fruit&nbsp;</button>
                        </span>
                        <button className="text-link__btn--more btn btn--primary">&nbsp;show
                            more...&nbsp;</button>
                        <span className="all-jackpot">and
                            all
                            <a
                                className="link-no-styles"
                                variant="primary"
                                href="https://www.crossgaming.com/en-AU/games/jackpot">jackpot
                                slots</a>
                        </span>
                    </p>
                    <p>5.5. It is forbidden to abuse any promotional offers, lotteries, tournaments at the casino. If any such cases come to light, the casino reserves the right to seize any winnings gained as a result of abusing the bonus offers and
                        disable the option of using any bonuses for that account in the future.</p>
                    <p>5.6. It is forbidden to delay any playing rounds, including free spins and bonus rounds, to a later time when the player no longer needs to play the bonus through and/or make new deposit(s). If a player is suspected of such actions,
                        the casino management reserves the right to cancel all bonuses and any winnings acquired from them at its sole discretion.</p>
                    <p>5.7. The use of the bonus funds purely to progress through the bonus stages is prohibited. For example, it’s prohibited to use the bonus or cash funds purely to progress through the bonus stages (e.g. collecting 9 out of 10 coins
                        to reach a bonus feature) and then the final stages (e.g. playing to collect the final coin to reach 10 out of 10 coins to reach the bonus feature), and to complete the stages with cash bets when the bonus funds have been forfeited,
                        lost or wagering met and converted to cash. All the winnings gained during such a play may be considered void.</p>
                    <p>5.8. The player is not allowed to use any strategies while playing with an active bonus in order to fulfil the requirements to wager the bonus. If the player uses such strategies, and if the Casino has sufficient grounds to believe
                        that the player's actions are aimed solely to obtain financial benefits using the bonus advantage, then the Casino reserves the right to cancel all the player's winnings.</p>
                    <h2>Tournament Terms and Conditions</h2>
                    <p>6.1. Tournament Prize is a bonus unless stated otherwise. Bonus Terms and Conditions apply unless stated otherwise.</p>
                    <p>6.2. Tournaments end promptly at the published time. Any hands, spins or bonus features in play at the point at which the tournament ends will not complete, and the outcome will not apply to the final result of the event. Only
                        the completed hands, spins and bonus features will count towards the final outcome.</p>
                    <p>6.3. Prizes are awarded directly to your casino balance within a few minutes after the tournament ends.</p>
                    <p>6.4. You may only use one account to enter tournaments at the casino. Any tournament winnings of players with multiple active accounts will be forfeited, and all accounts will be blocked.</p>
                    <p>6.5. The leaderboard in the tournament lobby is updated every minute. Consequently, there may be a delay in the display of your position and balance.</p>
                    <p>6.6. More information about our tournaments can be found on our Tournaments page.</p>
                    <h2>7. Comp Points Terms and Conditions</h2>
                    <p>7.1. Comp Point Exchange is a bonus unless stated otherwise. Bonus Terms and Conditions apply unless stated otherwise.</p>
                    <p>7.2. You collect 1 redeemable and 1 status comp point for every 20 EUR of real money wagered in slots.</p>
                    <p>7.3. Players progress through VIP levels as they accumulate the required number of comp points.</p>
                    <p>7.4. The comp points are periodically zeroed depending on the current VIP program terms and conditions.</p>
                    <p>7.5. Players are not allowed to transfer comp points between accounts.</p>
                    <p>7.6. Casino reserves the right to withdraw or amend the comp point program at any time and without prior notice. This will not affect players who have already commenced wagering towards the promotion.</p>
                    <p>7.7. The Free Spins wagering requirement for completion of the level is x50.</p>
                    <p>7.8. The money wagering requirement for completion of the level is x5.</p>
                    <p>7.9. Exchange redeemable comp points rate is 1.00 EUR or its equivalents per 100 points.</p>
                    <h2>8. SportsBook Rules and Conditions</h2>
                    <p>8.1. ComboBoost is available to all registered users.</p>
                    <p>8.2. ComboBoost is available for combined and system bets, with 3 or more events, with a event odds of 1.3 or more:</p>
                    <h2>NBA Play-offs</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all NBA events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>NHL Play-offs:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all NHL events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>French Open:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all tennis Roland Garros events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>WC Ice Hockey:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all WC Ice Hockey events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>European Championship:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all European Championship events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>Copa America:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all Copa America events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>Cricket</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all Cricket events</li>
                        <li>3-5 events – Bonus odds 1.05</li>
                        <li>6-7 events – Bonus odds 1.15</li>
                        <li>8-9 events – Bonus odds 1.25</li>
                        <li>10-11 events – Bonus odds 1.35</li>
                        <li>12 or more events – Bonus odds 1.5</li>
                    </ul>
                    <h2>Freecombo:</h2>
                    <ul>
                        <li>a event odds of 1.4 or more</li>
                        <li>all sports</li>
                        <li>3-4 events – Bonus odds 1.05</li>
                        <li>5-7 events – Bonus odds 1.07</li>
                        <li>8-10 events – Bonus odds 1.1</li>
                        <li>11-14 events – Bonus odds 1.15</li>
                        <li>15 or more events – Bonus odds 1.2</li>
                    </ul>
                    <h2>ComboUSA:</h2>
                    <ul>
                        <li>a event odds of 1.3 or more</li>
                        <li>all USA events</li>
                        <li>3-4 events – Bonus odds 1.07</li>
                        <li>5-7 events – Bonus odds 1.1</li>
                        <li>8-10 events – Bonus odds 1.15</li>
                        <li>11-14 events – Bonus odds 1.2</li>
                        <li>15 or more events – Bonus odds 1.25</li>
                    </ul>
                    <h2>ComboSoccer:</h2>
                    <ul>
                        <li>a event odds of 1.7 or more</li>
                        <li>all soccer events</li>
                        <li>3-4 events – Bonus odds 1.1</li>
                        <li>5-7 events – Bonus odds 1.15</li>
                        <li>8-10 events – Bonus odds 1.2</li>
                        <li>11 or more events – Bonus odds 1.3</li>
                    </ul>
                    <h2>ComboSoccerEurocup:</h2>
                    <ul>
                        <li>a event odds of 1.5 or more</li>
                        <li>all soccer Champions Cup and Europa League events</li>
                        <li>3-4 events – Bonus odds 1.1</li>
                        <li>5-7 events – Bonus odds 1.15</li>
                        <li>8-10 events – Bonus odds 1.2</li>
                        <li>11 or more events – Bonus odds 1.3</li>
                    </ul>
                    <h2>ComboBasketball:</h2>
                    <ul>
                        <li>a event odds of 1.5 or more</li>
                        <li>all basketball events</li>
                        <li>3-4 events – Bonus odds 1.07</li>
                        <li>5-7 events – Bonus odds 1.1</li>
                        <li>8-10 events – Bonus odds 1.15</li>
                        <li>11 or more events – Bonus odds 1.25</li>
                    </ul>
                    <h2>ComboTennis:</h2>
                    <ul>
                        <li>a event odds of 1.3 or more</li>
                        <li>all tennis events</li>
                        <li>3-4 events – Bonus odds 1.05</li>
                        <li>5-7 events – Bonus odds 1.1</li>
                        <li>8-10 events – Bonus odds 1.15</li>
                        <li>11 or more events – Bonus odds 1.25</li>
                    </ul>
                    <h6>ComboCybersport:</h6>
                    <ul>
                        <li>a event odds of 1.3 or more</li>
                        <li>all tennis events</li>
                        <li>3-4 events – Bonus odds 1.05</li>
                        <li>5-7 events – Bonus odds 1.1</li>
                        <li>8-10 events – Bonus odds 1.2</li>
                        <li>11 or more events – Bonus odds 1.3</li>
                    </ul>
                    <p>8.3. The player cannot change the amount of additional winnings. The amount is determined automatically.</p>
                    <p>8.4. The main winning bet and the additional Comboost win is credited to the player on the account of one transaction, one overall win.</p>
                    <p>8.5. The player cannot undo the ComboBoost feature.</p>
                    <p>8.6. If an event in a coupon that was made with ComboBoost function is calculated at 1.00, after the coupon is calculated, the corresponding percentage of the net coupon win will be added to the main win (without taking into account
                        the event calculated with a factor of 1.00).</p>
                    <p>8.7. If you find dishonest bets and abuse of ComboBoost offer, Our Company reserves the right to cancel the assignment of additional winnings.</p>
                    <p>8.8. The ComboBoost feature is optional, but not mandatory to provide.</p>
                    <p>8.9. The Comboboost feature is only available for "Sports" and "Live Betting" sections.</p>
                    <p>8.10. ComboBoost will not be available if at the time the coupon is confirmed, after re-trying to place a bet due to the changed odds, the event factor will change by a factor of less than 1.3.</p>
                    <p>8.11. In order for ComboBoost to be activated again for this coupon (where the odds of all events are 1.3 or more), you need to remove one bet from the coupon and sel ect it again.</p>
                    <p>8.12. Our Company reserves the right to change or cancel the terms of the offer at any time.</p>
                    <p>8.13. The general rules and conditions of Our Company are in force.</p>
                    <h2>9. Welcome Sportsbook Bonus</h2>
                    <p>9.1. Time of the offer: till December 31, 2022 (23:59 UTC).</p>
                    <p>9.2. The player can automatically get the bonus for his next deposit once. The bonus makes 300% of the deposit amount up to 1500 AUD or its equivalent.</p>
                    <p>9.3. The bonus expires in one month after it is claimed. The player must be verified to claim the bonus.</p>
                    <p>9.4. The bonus sum is provided in installments in the form of OnlyWin freebets as the player keep making bets.</p>
                    <p>9.5. Settled bets with coefficients of 1.1 and higher, regardless if they win or lose, contribute to the sum of the next installment. The exceptions are:</p>
                    <ul>
                        <li>Loses or partial wins of the Asian totals when only one of two possible outcomes win (with OnlyWin and AllWintype of bets in the bet slip);</li>
                        <li>cancelled bets;</li>
                        <li>bets with cashout feature.</li>
                    </ul>
                    <p>9.6. Once the sum of the next installment is equal to the player’s current average bet, the players gets a freebet which equals his current average bet. The amount of the received freebet is subtracted from the bonus sum, and the
                        accumulation of the next installment starts over.</p>
                    <p>9.7. As the final freebet exhausts the bonus sum, it will be rounded in favor of the player until the next possible multiple of five. For example, if the player’s average bet is 10 EUR, but the remained bonus sum is 7 EUR, the
                        player will receive a 10 EUR OnlyWin freebet.</p>
                    <p>9.8. Freebets are claimed through the Promotion or Home page.</p>
                    <p>9.9. Freebets expire in three days after they are claimed.</p>
                    <p>9.10. Freebets can be used only for distinct and combined bets on events with coefficients from 1.01 to 2.00 in the Sports and In-play sections. </p>
                    <h2>10. First Steps Bonus</h2>
                    <p>10.1. New players are rewarded with a 15 AUD free bet of OnlyWin type for their first deposit.</p>
                    <p>10.2. Verified new players are additionally rewarded for their first deposit with a 7.5 AUD free bet of OnlyWin type.</p>
                    <p>10.3. Those free bets can be bet on the events with coefficients between 1.00 and 2.00.</p>
                    <p>10.4. Those free bets expire in three days after being credited.</p>
                    <p>10.5. Players from Brazil get 20 BRL free bet instead of 10 EUR one and 15 BRL free bet instead of 5 EUR one.</p>
                    <h2>11. Types of Free Bets</h2>
                    <p>11.1. There are three types of free bets that can be credited to the player as a bonus: OnlyWin, AllWin, and NoRisk. They differ in what happens with the bet amount after the bet is won or lost.</p>
                    <p>11.2. When OnlyWin free bet is won, its amount is discarded and not included in the winnings.</p>
                    <p>11.3. When AllWin free bet is won, its amount is included in the winnings.</p>
                    <p>11.4. When NoRisk free bet is lost, its amount is credited to the player’s account as real money instead.</p>
                </div>
            </div>
        </main>
    )
}

export default BonusTerms