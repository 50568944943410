import Logo from '../../container/assets/CrossGaming.png';
import HeaderRight from './HeaderRight';
import Search from './Search';
import { Link } from 'react-router-dom';

function Header({ props }) {

    //let assetsPath = '../assets/';

    return (
        <header className="layout__header header header--bg">
            <Link 
                className="header__logo logo link" 
                variant="primary" 
                to={"/"}>
                <img 
                    className="logo__img image" 
                    src={Logo} 
                    alt="logo"
                    />
            </Link>

            <ul className="header__menu top-menu">
                
                {/*<Link 
                    className="top-menu__link link top-menu__link--current" 
                    variant="primary" 
                    to={"/"} 
                    aria-current="page">
                    <i className="top-menu__icon icon-sticker"></i>
                    <span className="top-menu__name">Lobby</span>
                </Link>*/}
                
                <Link 
                    className="top-menu__link link top-menu__link--current" 
                    variant="primary" 
                    to={"/casino"}>
                    <span className="top-menu__name">Casino</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/live-casino"}>
                    <span className="top-menu__name">Live Casino</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/tvbet"}>
                    <span className="top-menu__name">TVBET</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/keno80"}>
                    <span className="top-menu__name">Keno 80</span>
                </Link>

                {/*<Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/aviator"}>
                    <span className="top-menu__name">Aviator</span>
                </Link>*/}

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/fast-games"}>
                    <span className="top-menu__name">Fast Games</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/plinko"}>
                    <span className="top-menu__name">Plinko</span>
                </Link>

                {/*<Link 
                    className="top-menu__link link" 
                    to={"/sports"} 
                    variant="primary"
                    target="_blank">sports
            </Link>*/}
                
                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/bonuses"}>
                    <span className="top-menu__name">Bonuses</span>
                </Link>
                
                {/*<Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/vip"}>
                    <span className="top-menu__name">VIP</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" 
                    to={"/payments"}>
                    <span className="top-menu__name">Payments</span>
                </Link>

                <Link 
                    className="top-menu__link link" 
                    variant="primary" to={"/tournaments"}>
                    <span className="top-menu__name">Tournaments</span>
                </Link>*/}
                
            </ul>

            <Search/>
            <HeaderRight/>
        </header>
    )
}

export default Header
