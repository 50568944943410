import React from 'react'

function Terms() {
    return (
        <main className="layout__main">
            <div className="container">
                <div className="container__wrapper">
                    <h1 className="container__wrapper_title">Terms and Conditions</h1>
                    <div className="container__wrapper_updated">Last updated: 14.02.2022</div>
                    <p>Version 2.0</p>
                    <h2>1. General</h2>
                    <p>1.1 The website Cross Gaming (“Casino”, “Website”, “Company”, “We”, “Us”, “Our”) is owned and operated by Dama N.V., a company registered and established under the laws of Curaçao, with registration number 104562  and registered address
                        at Scharlooweg 39, Willemstad, Curaçao, and its wholly-owned subsidiary, Friolion Limited, registered in Cyprus with registration number HE 419102 and registered address Leandrou, 12A 3086, Limassol, Сyprus. Dama N.V. is licensed
                        and regulated by Antillephone N.V. (license no. 8048/JAZ2020-013). All payments with Paysafe are made via Dama N.V. It is the player’s sole responsibility to inquire about the existing laws and regulations of the given jurisdiction
                        for online gambling.</p>
                    <p>1.2 Before using our website, please read these Terms and Conditions carefully. By registering a Player Account with the website, you agree and confirm your consent to the Terms and Conditions stipulated below. </p>
                    <p>1.3 If you do not accept these Terms and Conditions, you are not allowed to use the services of the Casino.</p>
                    <p>1.4 The Casino reserves the right to unilaterally change the Terms and Conditions when such need occurs with notification of the Gambler about it, or without it. We do recommend all Players to visit this page regularly and check
                        for possible updates.</p>
                    <p>1.5 The main language of the Casino website is English, and any translation errors are interpreted in favor of the Casino.</p>
                    <h2>2. Limitations Of Use Of The Casino</h2>
                    <p>2.1 The Casino accepts players only from those countries and geographic regions where online gambling is allowed by law. It is the player’s sole responsibility to inquire about the existing gambling laws and regulations of the
                        given jurisdiction before placing bets on the website.</p>
                    <p>2.2 The Casino accepts strictly adult players (the minimum age is 18) and players who have reached the age specified by the jurisdiction of player’s place of residence as eligible for online gaming.</p>
                    <p>2.3 The Casino reserves the right to ask for proof of age from the player and limit access to the Website or suspend the Player Account to those players who fail to meet this requirement.</p>
                    <p>2.4 Users from the following countries and their territories (“the Restricted Countries”) are not allowed to deposit and play real money games: AAlbania, Angola, Barbados, Belgium, Burkina Faso, Cayman Islands, Central African
                        Republic, Congo, Curacao, Côte d'Ivoire, Dutch West Indies, Eritrea, Ethiopia, France and its overseas territories (Guadeloupe, Martinique, French Guiana, Réunion, Mayotte, St. Martin, French Polynesia, Wallis and Futuna, New
                        Caledonia), Gibraltar, Greece, Haiti, Iraq, Islamic Republic of Iran, Israel, Jamaica, Jersey, Korea, Lebanon, Liberia, Libya, Lithuania, Mali, Mauritius, Mayotte, Myanmar, Nicaragua, Pakistan, Panama, Rwanda, Sierra Leone,
                        Somalia, South Sudan, Spain, Sudan, Syrian Arab Republic, Uganda, Yemen, Zimbabwe, the Netherlands, the United Kingdom, the United States of America. The Casino cannot guarantee successful processing of withdrawals or refunds
                        in the event that player breaches this Restricted Countries policy.</p>
                    <p>2.5 Any bonuses are not available to players from Sweden, including participation in any kind of promotional programs, receiving VIP rewards, as well as exchange of comp points. Deposit bonuses are unavailable for the players from
                        Finland.
                    </p>
                    <p>2.6 The Casino cannot guarantee successful processing of withdrawals or refunds in case of breaching current Limitations of Use policy.</p>
                    <p>2.7. Age Verification Procedure for Bitcoins. In order to be able to play for Bitcoins, player MUST provide the Casino with the proof that he/she is at least 18 years old or above (valid ID, Passport or Driving License or any other
                        valid document). Players are not allowed to make deposits and (or) place bets until the Procedure is finished and their age is verified. Failure to provide the Casino with valid ID, Passport or Driving License will lead to
                        Player’s account closure.</p>
                    <h2>3. Availability of Games</h2>
                    <p>Please bear in mind some games may be unavailable in certain jurisdictions, as required by policies of game providers which may change from time to time.</p>
                    <p>NetEnt</p>
                    <p>3.1. Absolute Restriction</p>
                    <p>NetEnt will not permit NetEnt Casino Games to be supplied to any entity that operates in any of the below jurisdictions (irrespective of whether or not NetEnt Casino Games are being supplied by the entity in that jurisdiction)
                        without the appropriate licenses.</p>
                    <p>Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark, Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Spain, Sweden, Switzerland, United Kingdom, United States of America.</p>
                    <p>3.2. Blacklisted Territories</p>
                    <p>All NetEnt Casino Games may not be offered in the following territories:</p>
                    <p>Afghanistan, Albania, Algeria, Angola, Australia, Bahamas, Botswana, Belgium, Bulgaria, Colombia, Croatia, Czech Republic, Denmark, Estonia, Ecuador, Ethiopia, France, Ghana, Guyana, Hong Kong, Italy, Iran, Iraq, Israel, Kuwait,
                        Latvia, Lithuania, Mexico, Namibia, Nicaragua, North Korea, Pakistan, Panama, Philippines, Portugal, Romania, Singapore, Spain, Sweden, Switzerland, Sudan, Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, United Kingdom,
                        United States of America, Yemen, Zimbabwe.</p>
                    <p>3.3 Blacklisted Branded Games Territories</p>
                    <p>The following NetEnt Branded Games have some further restrictions in addition to the Blacklisted Territories set out above:</p>
                    <p>3.3.1 In addition to the jurisdictions set out in paragraph 3.2, Planet of the Apes Video Slot must not be offered in the following territories:</p>
                    <p>Azerbaijan, China, India, Malaysia, Qatar, Russia, Thailand, Turkey, Ukraine.</p>
                    <p>3.3.2 In addition to the jurisdictions set out in paragraph 3.2, Vikings Video Slot must not be offered in the following jurisdictions:</p>
                    <p>Azerbaijan, Cambodia, Canada, China, France, India, Indonesia, Laos, Malaysia, Myanmar, Papua New Guinea, Qatar, Russia, South Korea, Thailand, Turkey, Ukraine, United States of America.</p>
                    <p>3.3.3 In addition to the jurisdictions set out in paragraph 3.2, Narcos Video Slot must not be offered in the following territories:</p>
                    <p>Indonesia, South Korea.</p>
                    <p>3.3.4 In addition to the jurisdictions set out in paragraph 3.2, Street Fighter Video Slot must not be offered in the following territories:</p>
                    <p>Anguilla, Antigua &amp; Barbuda, Argentina, Aruba, Barbados, Bahamas, Belize, Bermuda, Bolivia, Bonaire, Brazil, British Virgin Islands, Canada, Cayman Islands, China, Chile, Clipperton Island, Columbia, Costa Rica, Cuba, Curacao,
                        Dominica, Dominican Republic, El Salvador, Greenland, Grenada, Guadeloupe, Guatemala, Guyana, Haiti, Honduras, Jamaica, Japan, Martinique, Mexico, Montserrat, Navassa Island, Paraguay, Peru, Puerto Rico, Saba, Saint Barthelemy,
                        Saint Eustatius, Saint Kitts and Nevis, Saint Lucia, Saint Maarten, Saint Martin, Saint Pierre and Miquelon, Saint Vincent and the Grenadines, South Korea, Suriname, Turks and Caicos Islands, United States of America, Uruguay,
                        US Virgin Islands, Venezuela.</p>
                    <p>3.3.5 In addition to the jurisdictions set out in paragraph 3.2, Fashion TV Video Slot must not be offered in the following territories:</p>
                    <p>Cuba, Jordan, Turkey, Saudi Arabia.</p>
                    <p>3.4. Universal Monsters (Dracula, Creature from the Black Lagoon, Phantoms Curse and The Invisible Man) may only be played in the following territories:</p>
                    <p>Andorra, Austria, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Cyprus, Finland, Georgia, Germany, Greece, Hungary, Iceland, Ireland, Liechtenstein, Luxembourg, Malta, Moldova, Monaco, Montenegro, Netherlands, North Macedonia,
                        Norway, Poland, Russia, San Marino, Serbia, Slovakia, Slovenia, Turkey and Ukraine.</p>
                    <p>Additional providers</p>
                    <p>3.5. Players from Canada are not eligible to play the games from NYX.</p>
                    <p>3.6. Players from Sweden are not eligible to play the games by Play'n GO and Pragmatic.</p>
                    <p>3.7. Players from Switzerland and Germany are not eligible to play the games by Play'n GO.</p>
                    <p>3.8. Players from the following countries are not eligible to play the games from Microgaming: USA, Singapore, Italy, Denmark, South Africa, France, UK, Spain, Belgium, Australia, Taiwan, Philippines.</p>
                    <p>3.9. Players from Australia, Austria, Estonia, Greece, USA are not eligible to play the games powered by Amatic.</p>
                    <p>3.10. Using VPN to bypass provider’s block is strictly prohibited and may lead to confiscation of winnings.</p>
                    <h2>4. Available Currencies.</h2>
                    <p>4.1 We accept payments made in EUR, USD, RUB, NOK, AUD, NZD, CAD, PLN, BRL, JPY, BTC, ETH, LTC, BCH, DOG, USDT.</p>
                    <p>4.2 The player is aware of possible changing of the exchange rate for Bitcoin depending on the market value. It is impossible to exchange Bitcoins to any other currency (USD or EUR). </p>
                    <h2>5. Fees And Taxes</h2>
                    <p>5.1. The player is solely responsible for reporting and accounting for any taxes applicable to them under relevant laws for their participation in gambling including any winnings that they receive from the Casino.</p>
                    <h2>6. Game Rules</h2>
                    <p>6.1 The player confirms that he/ she knows and understands the rules of games offered by the website. It is at player’s discretion to know the payout percentage of each game.</p>
                    <h2>7. Disclaimer Of Liabilities </h2>
                    <p>7.1 The player is completely aware of the fact that while gambling at the website he/she takes the risk of losing money. The Company is not liable for any possible financial damage arising from the use of the website.</p>
                    <p>7.2 The Company is taking effective measures to protect player’s private data from any unauthorized use and is only making it available to parties involved in providing of gambling services through the website. Notwithstanding
                        this, the Company is not responsible for how the information is further treated by third parties, for example third party software providers or affiliates. Treatment of player’s private data by such parties is subject to terms
                        and conditions of these parties, if any.</p>
                    <p>7.3 The Company is not liable for any hardware or software defects, poor Internet connection and other technical problems that have occured on Gambler’s side.</p>
                    <p>7.4 In the unlikely case where a wager is confirmed or a payment is performed by us in error, the Company reserves the right to cancel all wagers accepted containing such an error, or to correct the mistake by re-settling all the
                        wagers at the correct terms that should have been available at the time that the wager was placed in the absence of the error.</p>
                    <p> 7.5 If the Casino mistakenly credit your Player Account with a bonus or winnings that do not belong to you, whether due to a technical issue, error in the paytables, human error or otherwise, the amount and/or the winnings from
                        such bonus will remain the Casino property and will be deducted from your Player Account. If you have withdrawn funds that do not belong to you prior to us becoming aware of the error, the mistakenly paid amount will (without
                        prejudice to other remedies and actions that may be available at law) constitute a debt owed by you to us. In the event of an incorrect crediting, you are obliged to notify us immediately by email.</p>
                    <p> 7.6 The Casino, its directors, employees, partners, service providers:</p>
                    <ul>
                        <li>do not warrant that the software or the Website is/are fit for their purpose;</li>
                        <li>do not warrant that the software and Website are free from errors;</li>
                        <li>do not warrant that the Website and/or Games will be accessible without interruptions;</li>
                        <li>shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to Your use of the Website or Your participation in the Games.</li>
                    </ul>
                    <p> 7.7 The Player hereby agrees to fully indemnify and hold harmless the Casino, its directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise
                        in relation to the Player’s use of the Website or participation in the Games.</p>
                    <p> 7.8 You acknowledge that the Casino shall be the final decision-maker of whether you have violated the Casino’s rules, terms or conditions in a manner that results in your suspension or permanent barring from participation in our
                        site.
                    </p>
                    <h2>8. Gaming Account</h2>
                    <p>8.1 Each player is allowed to create only one (1) personal account per residence address, household, IP address, PC. Creating multiple Player Accounts by a single player can lead, at the sole discretion of the Casino, to termination
                        of all such accounts and cancellation of all payouts to the player. The player shall not provide access to his user account or allow using the website to any third party including but not limited to minors.</p>
                    <p>8.2 Besides, any returns, winnings or bonuses that the Player received or accumulated during the time when the Duplicate Account was active the account will be forfeited and may be reclaimed by the Casino. The Player will return
                        to the Casino on demand any funds which have been withdrawn from the Duplicate Account.</p>
                    <p>8.3 The website can be used solely for personal purposes and shall not be used for any type of commercial profit. </p>
                    <p>8.4 We reserve the right to make a phone call to the number provided in your user account, which at our own discretion can be a necessary part of the KYC procedure. Withdrawals may be terminated until the account is fully verified.
                        We will make reasonable efforts trying to contact you regarding the withdrawal of the funds, but if we are not able to reach you (by email or phone) in two (2) weeks as from the date of the request for withdrawal, your account
                        will be locked due to your failure to pass the KYC procedure.</p>
                    <h2>9. Anti-Fraud Policy</h2>
                    <p> 9.1 The Company has strict anti-fraud policy. If the player is suspected of fraudulent actions including but not limited to:</p>
                    <ul>
                        <li>participating in any type of collusion with other players</li>
                        <li>development and use of strategies aimed at unfaithful winnings</li>
                        <li>fraudulent actions against other online casinos or payment providers</li>
                        <li>charge back transactions with a credit card or denial of some payments made,</li>
                        <li>other types of cheating</li>
                        <li>providing of forged documents</li>
                        <li>creating two or more accounts</li>
                        <li>or is known to have gone bankrupt in the country of his residence, the Company reserves the right to terminate such a Player Account and suspend and/or cancel all payouts to the player. This decision is at the sole discretion
                            of the Company and the Player will not be notified or informed about the reasons of such actions. The Company also reserves the right and may be obliged to inform applicable regulatory bodies of the fraudulent actions performed
                            by the player.</li>
                    </ul>
                    <p> 9.2 The Casino reserves the right to close the Player’s Member Account and to refund to the Player the Account balance, subject to the deduction of relevant withdrawal charges, at Casino’s absolute discretion and without any obligation
                        to state a reason or give prior notice.</p>
                    <p> 9.3 The Casino has zero tolerance to advantage play. Any player who will try to gain advantage of casino welcome offers or other promotions agrees that the Company reserves the right to void bonuses and any winnings from such bonuses,
                        for the reasons of:</p>
                    <ul>
                        <li>use of stolen cards;</li>
                        <li>chargebacks;</li>
                        <li>creating more than one account in order to get advantage from casino promotions;</li>
                        <li>providing incorrect registration data;</li>
                        <li>providing of forged documents;</li>
                        <li>any other actions which may damage the Casino;</li>
                        <li>low risk roulette play where the player betting equal stakes for both black/red or even/odd covering 25 or more out of 37 numbers on the table. (Placing bets on black/red only covers 36 of 37 possible numbers).</li>
                    </ul>
                    <p>9.4 The Casino reserves the right to retain payments, if suspicion or evidence exists of manipulation of the casino system. Criminal charges will be brought against any user or any other person(s), who has/have manipulated the
                        casino system or attempted to do so. The Casino reserves the right to terminate and/or change any games or events being offered on the Website.</p>
                    <p>9.5 Should the user become aware of possible errors or incompleteness in the software, he/she agrees to refrain from taking advantage of them. Moreover, the user agrees to report any error or incompleteness immediately to the Casino.
                        Should the user fail to fulfil the obligations stated in this clause, the Casino has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective
                        error/incompleteness and the failed notification by the user. </p>
                    <p>9.6 To prevent any fraudulent activities concerning bitcoin transactions we lay down the following rule: In case the transaction is marked as replaceable - Opt-in Replace-by-Fee (RBF) the casino reserves the right to close such
                        accounts immediately and withhold any wins.</p>
                    <p>9.7 If a player requested a withdrawal of the funds, but the sum of made bets since last deposit is less than 3x (three) times the size of that deposit, the Casino reserves the right to charge the player the costs of transaction
                        processing, including deposits and withdrawals. This decision is at the sole discretion of the Casino management.</p>
                    <p>9.8 In order to verify player`s account casino management require documents (ID, payment systems, utility bills еtc) in Latin or Cyrillic alphabet. In case player doesn’t have an opportunity to provide documents in above-mentioned
                        alphabets casino reserves the right to demand video verification where player shows his/her documents.</p>
                    <p>9.9 The casino is not a financial institution and thus should not be treated as such. Your account will not bear any interests and no conversion or exchange services (including fiat-crypto exchange) will be offered at any time.</p>
                    <h2>10. Depositing</h2>
                    <p>10.1 Cross Gaming offers a variety of payment methods. They include VISA and MasterCard credit and debit cards, as well as various alternative payment methods. Please note that all payments with Paysafe are processed via Dama N.V.</p>
                    <p>10.2 Contact our support team at support@crossgaming.com to inquire about the payment methods which are most favorable for your country of residence.</p>
                    <p>10.3 The Company does not accept third party payments. You must make deposits only from a bank account, bank cards, e-wallets or other payment methods that are registered in your own name. If we determine during the security checks
                        that you have violated this condition, your winnings will be confiscated and the original deposit will be returned to the owner of the payment account. The Company is not responsible for the lost funds deposited from third
                        party accounts.</p>
                    <p>10.4 Please note that the minimal amount of deposit is 20€ or an equivalent. The maximum amount of deposit depends on the payment method you decide to use and will appear when choosing the payment method.</p>
                    <p>10.5 Kindly note that due to the nature of cryptocurrencies, deposit limits cannot be applied to the deposits made through CoinsPaid payment system. If you want to limit your gambling in the casino, please, use any other available
                        option.
                    </p>
                    <h2>11. Withdrawal Policy</h2>
                    <p>11.1 The minimal withdrawal amount from the gaming account is 20 EUR/USD (200 NOK, 30 CAD/AUD/NZD, 90 PLN, 1500 RUB, 2400 JPY; 140 BRL; 0.00043 BTC; 0.007 ETH, 0.066 LTC; 0.017 BCH; 40 DOG; 20 USDT). The maximum amount for withdrawal
                        available depends on the chosen payment method, and will appear when choosing the payment method. If the requested amount of withdrawal exceeds the limit of a particular payment system, the amount will be withdrawn in installments.
                    </p>
                    <p>11.2 The Casino reserves the right to check your identity prior to processing payouts and to hold any refund or withdrawals for the time needed to check your identity.In case you provide false or incomplete Personal Data, the withdrawal
                        can be refused and the Player Account terminated, of which you will be informed by email. The Casino may be required to report the player's actions to the applicable regulatory bodies.</p>
                    <p>11.3 The Website supports payouts via Original Credit Transfer (OCT) from Visa and via Payment Transfer from Mastercard. Additional requirements are that the respective credit card is not a corporate credit card and the card is
                        issued in a supported country.</p>
                    <p>11.4 For Visa, the following countries are not supported: USA, Australia, Hong Kong, India, Indonesia, Japan, Korea, Malaysia, Singapore.</p>
                    <p>11.5 For Mastercard, only the following countries are supported: Andorra, Austria, Belgium, Cyprus, Czech Republic, Denmark, Estonia, France, Germany, Gibraltar, Greece, Hungary, Iceland, Ireland, Italy, Latvia, Luxembourg, Malta,
                        Monaco, the Netherlands, Norway, San Marino, Slovakia, Slovenia, Spain, Sweden, Switzerland, Turkey, and the United Kingdom.</p>
                    <p> 11.6 Please note that even for supported countries the Casino is not able to guarantee successful credit card payment processing in all cases, since banks issuing credit cards may block or reject such transactions at their own
                        discretion.
                    </p>
                    <p> 11.7 The Casino reserves the right to transfer funds in a way different from the payment method specified by the Gambler if such need occurs.</p>
                    <p>11.8 The withdrawal request are processed from 0 to 24 hours depending on the chosen payment method, but, please, keep in mind that some web wallets take up to 1 day to process the withdrawal request.</p>
                    <p>11.9 The maximum withdrawal limit from sign up free spins bonus is 20 EUR/USD (200 NOK, 30 CAD/AUD/NZD, 90 PLN, 1500 RUB, 2400 JPY; 140 BRL; 0.00043 BTC; 0.007 ETH, 0.066 LTC; 0.017 BCH; 40 DOG; 20 USDT).</p>
                    <p> 11.10 The weekly withdrawal limit is 5,000 AUD or its equivalent in other currencies since the last successful cashout. The monthly limit is 25,000 AUD or its equivalent in other currencies. The monthly limit is reset every first
                        day of the month. Exceptions can be made for the most active and devoted players. All progressive jackpot wins will be paid in full.</p>
                    <p>11.11 If you have deposited via credit or debit card and the amount of withdrawal is equal or smaller to the one deposited, we reserve the right to pay the withdrawal amount back to the credit or debit card. If the amount exceeds
                        the one deposited, the amount in excess will be paid via one of the alternative payment methods.</p>
                    <p>11.12 In case of necessity to verify Gambler’s identity prior to processing payouts, the withdrawal can be hold up to 72 hours. In case of providing false personal data the Casino reserves the right to cancel all bets and winnings
                        as well as terminate user's account. The player will be informed thereof by email.</p>
                    <p>11.13 Please, note that the internal operating currency of the website is Euro. Due to this fact, in case you transact in other currencies, the amount deducted from your credit card may be insignificantly higher than displayed
                        at the time of transaction due to currency conversions on the side of your bank and/or the Casino's payment processing system. You will not be able to request a Bank Transfer for USD payouts. All Bank Transfer payouts in principle
                        are processed within 3 (three) working banking days.</p>
                    <p>11.14 You acknowledge that withdrawals via bank transfers can in exceptional cases be subject to additional charges by the intermediary banks. These charges remain outside the influence of The Casino and are in our experience limited
                        to the equivalent of EUR 16.</p>
                    <p>11.15 Please keep in mind the Casino is not a financial institution. Your account will thus not bear any interests and no conversion or exchange services will be offered at any time.</p>
                    <p>11.16. All costs that may occur upon refund procedure are on the player.</p>
                    <h2>12. Dormant Accounts</h2>
                    <p> 12.1 An inactive Account is a Player Account which a player has not logged into or logged out of for 12 (twelve) consecutive months. If your Player Account is deemed to be inactive, the Casino reserves the right to charge a monthly
                        administrative fee of €10 or the equivalent in another currency (or the current balance of your Player Account, if less) as long as the balance of your Player Account remains positive.</p>
                    <p> 12.2 You authorize the Casino to debit this fee from your Player Account at the beginning of the month following the day on which your Player Account is deemed inactive, and at the beginning of every subsequent month that your
                        Player Account remains inactive. The Casino will stop deducting the fee if the account balance is zero or if the account is re-activated.</p>
                    <h2>13. Expiry Period</h2>
                    <p>You agree that any claim and/or cause of action arising out of or related to these Terms and Conditions or a service provided by the Casino must be filed within one (1) year after such claim or cause of action arose.</p>
                    <h2>14. Refund Policy</h2>
                    <p>14.1 A refund request will only be considered if it is requested within the first twenty-four (24) hours of the alleged transaction, or within thirty (30) calendar days if a Player alleges that another individual has accessed his/her
                        Player Account</p>
                    <p>14.2 If you have funding your account with a Credit Card we reserve the right to pay all withdrawal requests up to the total amount deposited as refunds against the purchases you have made. If your withdrawals exceed the total
                        amount deposited, any excess amount will be paid to you via one of our alternative methods available.</p>
                    <p>14.3 Before a refund is processed all bonuses and winnings in your balance will be deducted prior to calculating the amount to be refunded.</p>
                    <p>14.4 In case any Credit Card deposits/withdrawals are considered to carry an unacceptable risk for security or legal reasons either by our Payment processors or by the Casino, we will initiate refunds for all such transactions
                        back to the Сredit Сard, and notify all the appropriate authorities and parties.</p>
                    <h2>15. Complaints</h2>
                    <p>15.1 Complaints are handled in the support department and escalated in the organisation of the Casino in the case that support personnel did not solve the case immediately. You shall be informed about the state of the complaint
                        to a reasonable level.</p>
                    <p>15.2 The Casino is to acknowledge a complaint started by the account holder only. It is forbidden to, and therefore you can not assign, transfer, hand over or sell your complaint to the third party.</p>
                    <p>15.3 Casino will dismiss the complaint if the matter is handed over to be conducted by the third party and not the original account owner.</p>
                    <p>15.4 In the event of any dispute, you agree that the server logs and records shall act as the final authority in determining the outcome of any claim. You agree that in the unlikely event of a disagreement between the result that
                        appears on your screen and the game server, the result that was logged on the game server will prevail, and you acknowledge and agree that our records will be the final authority in determining the terms and circumstances of
                        your participation in the relevant online gaming activity and the results of this participation.</p>
                    <p>15.5 When we wish to contact you regarding such a dispute, we will do so by using any of the contact details provided in your Player Account.</p>
                    <h2>16. Non Transferability</h2>
                    <p>You can not assign, pledge or transfer ownership under any title whatsoever to claims arising from these Terms and Conditions, the use of the Website or participation in the Games against the Casino without consent of the Casino.
                        This prohibition is designed as a non-transferability clause ex article 83 paragraph 2 of book 3 of the Civil Code and includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts,
                        winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial, or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning,
                        usufruct, trading, brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, entity in any way shape or form.</p>
                    <h2>17. Arbitration</h2>
                    <p>All disputes which may arise between you and the Casino including their successors in title under general or special title as a result of these Terms and Conditions or as a result of further agreements and other acts in connection
                        with these Terms and Conditions shall be settled exclusively by arbitration in Cyprus and in accordance with Cyprus Civil Procedure Rules.</p>
                </div>
            </div>
        </main>
    )
}

export default Terms