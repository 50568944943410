import React from 'react'

function PaymentTitle() {
    return (
        <>
            <h1 className="payments__page-title title-accent">
                Payments
            </h1>
            
            <div className="payments__text body2">
                We ensure that your deposits and withdrawals are processed securely and quickly. Payment methods are country specific. We charge no fees, however, some non EU transfers may be subject to charges applied by the processing banks.
                We are currently offering the following currencies: EUR, USD, RUB, NOK, AUD, NZD, CAD, PLN, BRL, JPY, BTC, ETH, LTC, BCH, DOG.
            </div>

        </>
    )
}

export default PaymentTitle