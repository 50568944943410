import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import "./i18n";


// Fonts Import
import './container/styles/9fa21c44.css';
//import './container/styles/60b69d6f.css';
// Double with 9fa21c44 
//import './container/styles/3305590c.css';
import './container/styles/e71f2114.css';
import './container/styles/fd6c0845.css';

import './container/styles/Auth.css';
import './container/styles/Bonuses.css';
import './container/styles/Games.css';
import './container/styles/Home.css';
import './container/styles/Montserrat.css';
import './container/styles/MontserratAlternates.css';
import './container/styles/NotoSans.css';
import './container/styles/Payment.css';
import './container/styles/Recaptcha.css'
import './container/styles/Registr.css';
import './container/styles/Roboto.css';
import './container/styles/Tournaments.css';
import './container/styles/Vip.css';
import './container/styles/Popup.css';
import './container/styles/FAQ.css';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);



