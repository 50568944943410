import React from 'react';
import GameMenuList from './GameMenuList';
import GameSlider from './GameSlider';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function GameContent() {
    return (
        <div className="page-game__content">
            <div className="container">
                <div className="game-info">
                    <div className="game-info__top">
                        <div className="game-info__top-block">
                            <div className="game-info__top-text-wrap">
                                <div className="game-info__top-name">Lightning Roulette</div>
                                <div className="game-info__top-provider">evolution</div>
                            </div>
                        </div>
                        <div className="game-info__top-block game-info__top-block--info">
                            <div className="game-info__top-mini-block">
                                <div className="game-info__top-mini-name">Volatility</div>
                                <div className="game-info__top-mini-value"></div>
                            </div>
                            <div className="game-info__top-mini-block">
                                <div className="game-info__top-mini-name">playlines</div>
                                <div className="game-info__top-mini-value"></div>
                            </div>
                            <div className="game-info__btn-icon-wrap">
                                <i className="game-info__btn-arrow-icon icon-arrow"></i>
                            </div>
                        </div>
                    </div>
                    <div className="game-info__dropdown">
                        <div className="game-description"></div>
                    </div>
                </div>

                <div className="game-menu">
                    <div className="game-slider-wrapper game-menu__slider">

                        <div className="game-slider__tabs">
                            <div className="tabs">
                                <div className="tabs__tabs-wrapper">
                                    <span className="tabs__tab subtitle2 tabs__tab--active">GAMES FOR YOU</span>
                                    <span className="tabs__tab subtitle2">TOP GAMES</span>
                                    <span className="tabs__tab subtitle2">NEW</span>
                                    <span className="tabs__tab subtitle2">PLAYED GAMES</span>
                                </div>
                            </div>
                        </div>

                        <div className="common-slider__controls">
                            <button className="common-slider__button common-slider__button--prev btn btn--outline">
                                <KeyboardArrowDownOutlinedIcon/>
                            </button>
                            <button className="common-slider__button common-slider__button--next btn btn--outline">
                                <KeyboardArrowDownOutlinedIcon/>
                            </button>
                        </div>
                        
                       <GameSlider/>
                    </div>

                   <GameMenuList/>
                    
                </div>
            </div>
        </div>
    )
}

export default GameContent