import React from 'react';
import { Link } from "react-router-dom";

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../container/assets/partners', false, /\.(png|jpe?g|svg)$/));


function FooterProviderList() {
    return (
        <div className="slick-list">
            <div
                className="slick-track"
                style={{
                    width: '9240px',
                    opacity: 1,
                    /*transform: `translate3d(-2904px, 0px, 0px)`*/
                }}>


                {/* Object to Map Affiliate partners images*/ } 
                {
                    Object.keys(images).map((keyName, i) => (
                        <div
                            key={i}
                            data-index="-10"
                            tabIndex="-1"
                            className="slick-slide slick-cloned"
                            aria-hidden="true"
                            style={{
                                width: '132px'
                            }}>
                            <div>
                                <Link
                                    className="footer-logos__item"
                                    to="/#"
                                    tabIndex="-1"
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}>
                                    <img
                                        className="footer-logos__image image"
                                        src={images[keyName]} alt="image"
                                    />
                                </Link>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default FooterProviderList
