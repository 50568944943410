import { Link } from "react-router-dom";
import "./ProfileHeader.css";

import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Logo from "../../container/assets/CrossGaming.png";

function ProfileHeader() {
  return (
    <div className="bCCWCd header__row">
      <div className="cyRGxT header__group">
        <Link
          to="/"
          className="jzRSMS header__logo-link-wrapper"
        >
          <img alt="logo" src={Logo} className="beqVcz header__logo" />
        </Link>

        <div className="hxdFHN header__separator"></div>

        <Link
          to="/casino"
          title="All Games"
          className="sc-fzoJMP eFMCar sc-fzpdyU dxrHjA header__links text-button text-button--primary"
          type="button"
        >
          All Games
        </Link>
      </div>

      <div className="hQCeKK breakpoints--mobile-or-tablet">
        <div className="cyRGxT header__group">
          <Link
            className="gwNWYc sc-fzqKVi jQobnC header__deposit-button sc-fzqKVi jQobnC header__deposit-button styled-button styled-button--secondary styled-button--sm"
            variant="secondary"
            to="/profile/wallet/deposit"
          >
            Deposit
          </Link>
          <button
            className="eFMCar sc-fzoOEf dfwHgI header__menu-button text-button text-button--primary"
            type="button"
          >
            <MenuOutlinedIcon />
          </button>
        </div>
      </div>

      <div className="kTbGNa breakpoints--desktop">
        <div className="cyRGxT header__group">
          <Link
            to="/casino"
            className="gwNWYc iqIHyv header__back-button iqIHyv header__back-button styled-button styled-button--primary styled-button--sm"
          >
            Back to Casino
          </Link>
          <div
            name=""
            role="combobox"
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-labelledby="downshift-0-label"
            className="jcWMUb crymUo header-menu__container select__container select__container--"
          >
            <button

              className="gwNWYc jXbvUm header-menu__menu-button jXbvUm header-menu__menu-button styled-button styled-button--primary"
            >
              <MoreHorizOutlinedIcon fontSize="1 rem" />
            </button>

            {/*<div className="sc-fzqBkg yTaSA select__bottom-line">
              <div role="listbox" aria-labelledby="downshift-0-label" id="downshift-0-menu" className="sc-fzqPZZ eupXoq sc-fzqzEs kRxexy header-menu__dropdown select__dropdown">
                <div name="language" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-7-label" className="sc-fzolEj sc-fznzqM foqpsW sc-fzpdbB bIZXlm header-menu__text-dropdown select__container select__container--language text_dropdown__container text_dropdown__container--language" data-theme-path="common.textDropdown">
                  <button className="sc-fzoJMP fUWEas sc-fznOgF gUKcSs text-dropdown__button text-button text-button--secondary" type="button" data-theme-path="common.textButton">
                    English - Australia <i className="sc-fzoKki kgdacT select__arrow_icon icon icon-dropdown"></i>
                  </button>
                </div>
                
                <div className="sc-fznWqX ifBgbV sc-fzoPby gZOZQz header-menu__divider divider" data-theme-path="common.divider"></div>
                <div className="sc-fzoxKX jhvISy sc-fzqOul eZopsr header-menu__option select__option">Support</div>
                <div className="sc-fzoxKX jhvISy sc-fzqOul eZopsr header-menu__option select__option">Log out</div>
              </div>
            </div>*/}


          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader;
