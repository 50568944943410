import React from 'react'

function LobbyContainer() {
    return (
        <div className="page-game__container page-game__container--game">
            <div className="page-game__grid page-game__grid--count-1">
                <div className="page-game__grid-item">
                    <div className="game-frame">
                        <div className="game-frame__inner">
                            <div className="game-frame__head">
                                <h1 className="game-frame__title">Play Lightning Roulette Online by evolution</h1>
                                <div className="game-frame__controls">
                                    <button className="text-btn game-frame__button game-frame__button--favorite" variant="primary">
                                        <i className="font-icons font-icons--favorite icon-heart"></i>
                                    </button>
                                    <button className="text-btn game-frame__button game-frame__button--close"variant="primary">
                                        <i className="font-icons font-icons--close icon-close"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="game-frame__block-frame"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="game-panel page-game__panel game-panel--open">
                <div className="game-controls">
                    <button className="game-controls__button game-controls__button--fullscreen btn btn--outline btn--block">
                        <i className="game-controls__icon icon-full-window"></i>
                    </button>
                    
                    <button className="game-controls__button game-controls__button--screen game-controls__button--active btn btn--outline btn--block">
                        <i className="game-controls__icon icon-one-window"></i>
                    </button>

                    <button className="game-controls__button game-controls__button--two-screen btn btn--outline btn--block">
                        <i className="game-controls__icon icon-two-window"></i>
                    </button>
                    
                    <button className="game-controls__button game-controls__button--three-screen btn btn--outline btn--block">
                        <i className="game-controls__icon icon-four-window"></i>
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default LobbyContainer
