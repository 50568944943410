import React from 'react'

import headerSlider from '../../container/assets/header-slider/live-casino-holdem-en.jpg'
import headerSliderMobile from '../../container/assets/header-slider/live-casino-holdem-en.jpg'


function HeaderSlider(props) {

    return (
        <div className="home-header">
            <img
                className="home-header__banner home-header__banner-desktop image"
                alt="bg"
                /* 1792x640*/
                src={headerSlider}
            />
            <img
                className="home-header__banner home-header__banner-mobile image"
                alt="bg"
                /* 480x586*/
                src={headerSliderMobile}
            />

            <div
                className="home-header__container home-header__container--nosign container--small container">
                <div className="home-header__info">
                    <p className="heading1 secondary home-header__subtitle">{props.subTitle}</p>
                    <h1 className="home-header__title">{props.headerTitle}</h1>
                    <p className="home-header__description">
                        {props.headerDescription}
                    </p>
                </div>


            </div>
        </div>
    )
}

export default HeaderSlider



