import React from 'react';
import { Link } from 'react-router-dom';


function FooterMenu() {

    return (
        <div className="footer__section-menu">
            <div className="footer__menu-col">
                <p className="subtitle2 footer-menu__title">Quick Links</p>
                <ul className="footer__menu footer-menu">
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Bonuses" to={"/bonuses"}
                        >Bonuses
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="VIP" 
                            to={"/vip"}
                            >VIP
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Tournaments" 
                            to={"/tournaments"}
                            >Tournaments
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="footer__menu-col">
                <p className="subtitle2 footer-menu__title">Info</p>
                <ul className="footer__menu footer-menu">
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Payments" 
                            to={"/payments"}
                            >Payments
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Support" 
                            to={"/support"}
                            >Support
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Bonus Terms and Conditions" 
                            to={"/bonus-terms"}
                            >Bonus Terms and Conditions
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Responsible gaming" 
                            to={"/responsible-gaming"}
                            >Responsible gaming
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Terms And Conditions" 
                            to={"/terms-and-conditions"}
                            >Terms And Conditions
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="FAQ" 
                            to={"/faq"}
                            >FAQ
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Privacy Policy" 
                            to={"/privacy-policy"}
                            >Privacy Policy
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="General SportsBook Rules" 
                            to={"/general-sportsbook-rules"}
                            >General SportsBook Rules
                        </Link>
                    </li>
                </ul>
                <div 
                    className="footer-menu__item" 
                    style={{
                        marginTop: '8px', 
                        listStyle: 'none'
                    }}>
                    <Link
                        className="body2 footer-menu__link link" 
                        href="https://cnews.ng/" 
                        variant="primary" 
                        target="_blank">Affiliate
                    </Link>
                </div>
            </div>
            <div className="footer__menu-col">
                <p className="subtitle2 footer-menu__title">Games</p>
                <ul className="footer__menu footer-menu">
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Fast Games" 
                            to="/fast-games"
                            >Fast Games
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Slots" 
                            to={"/casino/slots"}
                            >Slots
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Jackpot" 
                            to={"/casino/jackpot"}
                            >Jackpot
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Live Blackjack" 
                            to={"games/live-blackjack"}
                            >Live Blackjack
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Live Roulette" 
                            to={"/casino/live-roulette"}
                            >Live Roulette
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Drops &amp; Wins" 
                            to={"/casino/drops-and-wins"}
                            >Drops &amp; Wins
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Live Casino" 
                            to={"/casino/live-games"}
                            >Live Casino
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Bonus buy" 
                            to={"/casino/bonus-buy"}
                            >Bonus buy
                        </Link>
                    </li>
                    <li className="footer-menu__item">
                        <Link 
                            className="body2 footer-menu__link link" 
                            variant="primary" 
                            title="Table games" 
                            to={"/casino/table"}
                            >Table games
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default FooterMenu
