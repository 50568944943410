import "./ProfileSecurity.css";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

function ProfileSecurity() {
  return (
    <main className="sc-fzoXzr wPaCf layout__main">
      <h1 className="sc-pIJJz XfiFP title title--h1">Profile</h1>

      <nav className="sc-pIJJz dhpicN tabs__container">
        <div className="sc-pRFjI iaxKdj tabs__wrapper">
          <a
            aria-current="page"
            className="sc-pZBmh eUpOXi tabs__tab-link "
            href="/profile/general/info"
          >
            General
          </a>
          <span className="sc-oTbqq ccNeVQ tabs__delimiter"></span>

          <a
            className="sc-pZBmh eUpOXi tabs__tab-link"
            href="/profile/general/verification"
          >
            Verification
          </a>

          <span className="sc-oTbqq ccNeVQ tabs__delimiter"></span>

          <a
            className="sc-pZBmh eUpOXi tabs__tab-link active"
            href="/profile/general/security"
          >
            Security
          </a>
          <span className="ccNeVQ tabs__delimiter"></span>
        </div>
      </nav>

      <div className="sc-pjSSY iCntqh profile-security page-container page-container--table">
        <div className="sc-pZOBi jOdohT styled-cards__container">

          <div className="sc-pJUVA bCkCTr styled-card__wrapper">
            <div className="sc-qYIQh ePgKnE styled-card">
              <div className="sc-pBzUF cIxsVM styled-card__title">
                Two-factor authentication
              </div>
              <div className="sc-qQKeD hDroiJ two-factor-authentication__container">
                <div className="sc-fzqBZW fsGqTR breakpoints--desktop-or-tablet">
                  <a href="otpauth://totp/meliqbuild@gmail.com?secret=oxlkfvqmgbs3nuomjt7smeaf&amp;issuer=crossgaming">
                    <div className="sc-pZQux flJfFJ two-factor-authentication__qr-code-container">
                      <div
                        size="80"
                        className="sc-oTLFK dXMMtz qr-code__container"
                      >
                        <canvas
                          height="80"
                          width="80"
                          style={{
                            height: "80px",
                            width: "80px",
                          }}
                        ></canvas>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="sc-qYGWS bSxwqI two-factor-authentication__description-wrapper">
                  Install a code-generating app such as Google Authenticator or
                  Authy on your phone. Scan the QR code. Enter the received code
                  below.
                  <div className="sc-fzoyAV dpRpSU breakpoints--mobile">
                    <br />
                    <button className="sc-fzplWN gwNWYc sc-pJVnX dLRiFN two-factor-authentication__turn-on-button sc-pJVnX dLRiFN two-factor-authentication__turn-on-button styled-button styled-button--secondary">
                      Turn on
                    </button>
                    <div className="collapse">
                      <a
                        href="otpauth://totp/meliqbuild@gmail.com?secret=oxlkfvqmgbs3nuomjt7smeaf&amp;issuer=crossgaming"
                        className="sc-pBxWu eLsRNf two-factor-authentication__can-not-scan"
                      >
                        I can't scan QR-code
                      </a>
                      <a
                        href="otpauth://totp/meliqbuild@gmail.com?secret=oxlkfvqmgbs3nuomjt7smeaf&amp;issuer=crossgaming"
                        className="sc-pRtcU ftHBiz two-factor-authentication__secret-link"
                      >
                        <div className="sc-pZQux flJfFJ two-factor-authentication__qr-code-container">
                          <div
                            size="80"
                            className="sc-oTLFK dXMMtz qr-code__container"
                          >
                            <canvas
                              height="80"
                              width="80"
                              style={{
                                height: "80px",
                                width: "80px",
                              }}
                            ></canvas>
                          </div>
                        </div>
                      </a>
                      <button
                        className="sc-fzoJMP eFMCar sc-pjGMk jcUjWI secret-code-copy-button text-button text-button--primary"
                        type="button"
                      >
                        Copy secret code
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <form className="">
                <div className="sc-fzpmMD bdYFba input__wrapper">
                  <input
                    type="text"
                    placeholder="Type it here"
                    data-name="authenticationCode"
                    autoComplete="off"
                    className="sc-fzoNJl cbYJr input__native"
                    value=""
                  />
                  <div className="sc-fzoXWK hNONtA input__focus-container"></div>
                  <div className="sc-fznMAR htmcOf input__right-container">
                    <button
                      type="submit"
                      disabled=""
                      className="sc-fzoJMP eFMCar sc-prrfo lfeDbm two-factor-authentication-form__submit-button text-button text-button--primary"
                    >
                      <ArrowForwardOutlinedIcon className="icon icon-arrow-right" />
                    </button>
                  </div>
                </div>
                <div className="sc-pbvYO eGMCJF errors__wrapper"></div>
              </form>
            </div>
          </div>

          <div className="sc-pJUVA bCkCTr styled-card__wrapper">
            <div className="sc-qYIQh ePgKnE styled-card">
              <div className="sc-qQWDO iSLOvX security__title-container">
                <div className="sc-pBzUF cIxsVM styled-card__title">
                  Password change
                </div>
              </div>
              <form className="">
                <div className="sc-psedN cJAnTr security__input-field-container">
                  <div className="sc-fzpmMD bdYFba input__wrapper">
                    <div className="sc-fznxKY bOFhRf input__left-container">
                      <LockOutlinedIcon className="icon icon-lock-closed" />
                    </div>
                    <input
                      type="password"
                      id="current_password"
                      placeholder="Old password"
                      data-name="current_password"
                      autoComplete="off"
                      className="sc-fzoNJl cbYJr input__native"
                      value=""
                    />
                    <div className="sc-fzoXWK hNONtA input__focus-container"></div>
                  </div>
                </div>
                <div className="sc-psedN cJAnTr security__input-field-container">
                  <div className="sc-fzpmMD bdYFba input__wrapper">
                    <div className="sc-fznxKY bOFhRf input__left-container">
                      <LockOutlinedIcon className="icon icon-lock-closed" />
                    </div>
                    <input
                      type="password"
                      id="password"
                      placeholder="New password"
                      data-name="password"
                      autoComplete="off"
                      className="sc-fzoNJl cbYJr input__native"
                      value=""
                    />
                    <div className="sc-fzoXWK hNONtA input__focus-container"></div>
                    <div className="sc-fznMAR htmcOf input__right-container">
                      <button
                        type="button"
                        className="sc-fzoJMP eFMCar text-button text-button--primary"
                      >
                        <VisibilityOutlinedIcon className="icon icon-eye" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="sc-psedN cJAnTr security__input-field-container">
                  <div className="sc-fzpmMD bdYFba input__wrapper">
                    <div className="sc-fznxKY bOFhRf input__left-container">
                      <LockOutlinedIcon className="icon icon-lock-closed" />
                    </div>
                    <input
                      type="password"
                      id="password_confirmation"
                      placeholder="Password confirm"
                      data-name="password_confirmation"
                      autoComplete="off"
                      className="sc-fzoNJl cbYJr input__native"
                      value=""
                    />
                    <div className="sc-fzoXWK hNONtA input__focus-container"></div>
                    <div className="sc-fznMAR htmcOf input__right-container">
                      <button
                        type="button"
                        className="sc-fzoJMP eFMCar text-button text-button--primary"
                      >
                        <VisibilityOutlinedIcon className="icon icon-eye" />
                      </button>
                    </div>
                  </div>
                </div>
                <button
                  id="submit-password-change"
                  type="submit"
                  disabled=""
                  className="sc-fzplWN gwNWYc styled-button styled-button--secondary styled-button--sm"
                >
                  Update
                </button>
                <div className="sc-pbvYO eGMCJF sc-pzLqt bLrnkY password-change__errors errors__wrapper"></div>
              </form>
            </div>
          </div>

        </div>

        <div className="sc-pzXPE vHQtY session-history__container">
          
          <div className="sc-qXgLg fFhMWf session-history__title">
            Session history
          </div>

          {/* Mobile & Tablet */}
          <div className="sc-fzokOt hQCeKK breakpoints--mobile-or-tablet">
            <div className="sc-oUbqx fuOviU session-history__list">
              
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      78.109.64.167
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      AM
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 10.0; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      15 Jan 2023, 17:01
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-pcLhl clqQLz session-history__current-session">
                    Current
                  </div>
                </div>
              </div>

              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      195.250.74.150
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      AM
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 6.3; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      16 Dec 2021, 22:51
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>

              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      78.109.79.205
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      AM
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 6.3; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      22 Feb 2022, 22:46
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>

              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      85.196.218.245
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      EE
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 6.3; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      29 Apr 2022, 20:06
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>

              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      78.109.79.39
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      AM
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 10.0; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      29 Dec 2022, 22:17
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>

              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                      78.109.69.202
                    </div>
                    <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                      AM
                    </div>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                      Windows NT 10.0; Win64; x64
                    </div>
                    <button
                      className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                  <div className="sc-pIvhh hlCgrY session-history__column-container">
                    <div className="sc-pZopv APDAW session-history__list-item-date-column">
                      22 Dec 2022, 11:42
                    </div>
                  </div>
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          
          {/* Desktop Mode */}
          <div className="sc-fzqNJr kTbGNa breakpoints--desktop">
            <div className="sc-oUbqx fuOviU session-history__list">
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  78.109.64.167
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  AM
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 10.0; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  15 Jan 2023, 17:01
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-pcLhl clqQLz session-history__current-session">
                    Current
                  </div>
                </div>
              </div>
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  195.250.74.150
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  AM
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 6.3; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  16 Dec 2021, 22:51
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  78.109.79.205
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  AM
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 6.3; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  22 Feb 2022, 22:46
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  85.196.218.245
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  EE
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 6.3; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  29 Apr 2022, 20:06
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  78.109.79.39
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  AM
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 10.0; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  29 Dec 2022, 22:17
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>
              <div className="sc-pbYdQ fizfxj session-history__list-item">
                <div className="sc-pQQXS eEsVrS session-history__list-item-ip-column">
                  78.109.69.202
                </div>
                <div className="sc-qPjXN iVQzHs session-history__list-item-country-column">
                  AM
                </div>
                <div className="sc-pkUyL jWzMjE session-history__user-agent-column">
                  Windows NT 10.0; Win64; x64
                  <button
                    className="sc-fzoJMP eFMCar sc-psRme cPgVAk session-history__tooltip-user-agent text-button text-button--primary"
                    type="button"
                  >
                    <InfoOutlinedIcon  />
                  </button>
                </div>
                <div className="sc-pZopv APDAW session-history__list-item-date-column">
                  22 Dec 2022, 11:42
                </div>
                <div className="sc-oUnPI kWpLvW session-history__session-status">
                  <div className="sc-ptEpz dEINTn session-history__closed-session">
                    Closed
                    <button
                      className="sc-fzoJMP eFMCar sc-qPwwY cmtDhN session-history__closed-session-info text-button text-button--primary"
                      type="button"
                    >
                      <InfoOutlinedIcon  />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </main>
  );
}
export default ProfileSecurity;
