import React from 'react';
import { Link } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../container/assets/partners', false, /\.(png|jpe?g|svg|webp)$/));


function MenuProviderList() {
    return (
        <div className="menu-providers__list">

            {
                Object.keys(images).map((keyName, i) => (
                    <div
                        key={i}
                        id="games-page-providers-item-0"
                        role="option"
                        aria-selected="false"
                        className="menu-providers__item"
                    >
                        <Link
                            className="menu-providers__link link"
                            variant="primary"
                            to="/games/all/1spin4win"
                        >
                            <img
                                className="menu-providers__img image"
                                src={images[keyName]}
                                alt="img"
                            />
                            1spin4win
                        </Link>
                    </div>

                ))
            }

        </div>
    )
}

export default MenuProviderList
