import React from 'react';
import { Link } from 'react-router-dom';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


function GameContainer(props) {
    return (
        <div className="page-game__container page-game__container--game">
            <div className="page-game__grid page-game__grid--count-1">
                <div className="page-game__grid-item">
                    <div className="game-frame">
                        <div className="game-frame__inner">
                            <div className="game-frame__head">
                                <h1 className="game-frame__title">
                                    {props.frameTitle}
                                </h1>
                                
                                <div className="game-frame__controls">
                                    <button className="text-btn game-frame__button game-frame__button--favorite" variant="primary">
                                        <FavoriteBorderOutlinedIcon/>
                                    </button>
                                    <button className="text-btn game-frame__button game-frame__button--close" variant="primary">
                                        <Link to="/casino">
                                            <CloseOutlinedIcon/>
                                        </Link>
                                    </button>
                                </div>

                            </div>
                            <div className="game-frame__block-frame">
                                <iframe 
                                    title={props.gameTitle}
                                    src={props.gameUrl}
                                    height="100%"
                                    width="100%"
                                 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="game-panel page-game__panel game-panel--open">
                <div className="game-controls">
                    <button className="game-controls__button game-controls__button--fullscreen btn btn--outline btn--block">
                        <FullscreenOutlinedIcon/>
                    </button>
                    
                    <button className="game-controls__button game-controls__button--screen game-controls__button--active btn btn--outline btn--block">
                        <DesktopWindowsOutlinedIcon/>
                    </button>
                    
                    <button className="game-controls__button game-controls__button--two-screen btn btn--outline btn--block">
                        <i className="game-controls__icon icon-two-window"></i>
                    </button>
                    
                    <button className="game-controls__button game-controls__button--three-screen btn btn--outline btn--block">
                        <i className="game-controls__icon icon-four-window"></i>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default GameContainer