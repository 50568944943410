import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { domain } from "../../config";

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import HeaderSlider from "../HeaderSlider/HeaderSlider";
import GamesHeader from "../Casino/GamesHeader";
import GamesCategoryMenu from "../Casino/GamesCategoryMenu";
import GamesFilterSearch from "../Casino/GamesFilterSearch";
import MenuProviderList from "../Casino/MenuProviderList";
import FastGamesCatalog from "./FastGamesCatalog";
import FastGamesHeader from "./FastGamesHeader";

const FastGames = () => {
    const [games, setGames] = useState(null);

    useEffect(() => {
        fetch(`${domain()}/aggregator/games?provider=fiablegames&gameMode=demo&partnerId=1000&language=en&token=`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response => response.json())
        //.then(data => console.log(data.data.result) )
        .then(data => setGames(data.data.result.game_list))
        .catch(error => console.log(error))
    
      }, []);

    return (
        <>
        <HeaderSlider />
        <main className="layout__main">
            <div className="games">
                <div className="container">
                    <div className="games__section">
                        <FastGamesHeader />


                        {/*<div className="games__filter games-filter">
                            <div className="games-filter__cell games-filter__cell--category">
                                <div className="games-filter__category games-filter__category--desktop">
                                    <GamesCategoryMenu />
                                </div>
                            </div>


                            <div className="games-filter__controls">
                                <GamesFilterSearch />


                                <div className="games-filter__cell games-filter__cell--providers games-filter__cell--home">
                                    <div className="menu-providers" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="games-page-providers-label">
                                        <div className="menu-providers__button" aria-autocomplete="list" aria-labelledby="games-page-providers-label" autoComplete="off" value="" id="games-page-providers">
                                            <div className="menu-providers__button-inner subtitle3">Providers</div>
                                            <div className="menu-providers__caret">
                                                <KeyboardArrowDownOutlinedIcon/>
                                            </div>
                                        </div>
                                        <div className="menu-providers__content" role="listbox" aria-labelledby="games-page-providers-label" id="games-page-providers-menu">

                                            <MenuProviderList />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>*/}

                        {/*<GamesCatalog />*/}
                        <FastGamesCatalog games={games} />
                        


                    </div>
                </div>
            </div>
        </main>
    </>
    )
}
export default FastGames;