import React from 'react';
import { Link } from 'react-router-dom';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

function FooterPanel() {
    return (
        <div className="panel">
            <div className="panel__list">
                <Link 
                    className="panel__link panel__link--current link" 
                    variant="primary" 
                    to="/"
                    >
                    {/*<i className="panel__icon icon-tabbar-home-active"></i>*/}
                    <HomeOutlinedIcon/>
                    <span className="panel__name">Home</span>
                </Link>

                <Link 
                    className="panel__link link" 
                    variant="primary" 
                    to="/casino">
                    {/*<i className="panel__icon icon-tabbar-games"></i>*/}
                    <SportsEsportsOutlinedIcon/>
                    <span className="panel__name">Casino</span>
                </Link>

                <Link 
                    className="panel__link link" 
                    variant="primary" 
                    to="/search">
                    {/*<i className="panel__icon icon-tabbar-search"></i>*/}
                    <SearchOutlinedIcon/>
                    <span className="panel__name">Search</span>
                </Link>

                <Link 
                    className="panel__link link" 
                    variant="primary" 
                    to="/more">
                    {/*<i className="panel__icon icon-tabbar-more"></i>*/}
                    <MoreHorizOutlinedIcon/>
                    <span className="panel__name">More</span>
                </Link>
                
            </div>
        </div>
    )
}

export default FooterPanel
