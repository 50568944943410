import React, { useRef } from 'react'
import TournCard from './TournCard';

import img1 from '../../container/assets/tournaments/joo-apple-promo-tournaments-1024x492px-v2.jpg';
import img2 from '../../container/assets/tournaments/joo-freespin-september-promo-1024x492.jpg';
import img3 from '../../container/assets/tournaments/joo-pbb-promo-all-tournaments-page-1024x492px.jpg';


function Tournaments() {
    const titleRef = useRef();
    
    let title = "Tournaments";
    let description = "Get to the top by participating in regular and limited tournaments! Play more than the others to convert your passion into generous prizes! Set new records by gaining the high score!"

    return (
        <main className="layout__main">
            <div className="tournaments-page bg-image">
                <div className="tournaments-page__container container">
                    <h1 className="tournaments-page__title title-accent">{title}</h1>
                    <p className="tournaments-page__description text">
                        {description}
                    </p>
                    <div className="tournaments-page__items">
                        <TournCard title="Title" image={img1}/>
                        <TournCard title="title" image={img2}/>
                        <TournCard title="Title" image={img3}/>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Tournaments
