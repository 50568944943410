import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function Login() {
    const { loginUser, isAuthenticated } = useContext(AuthContext);
    const [passwordShown, setPasswordShown] = useState(false);
    const [formValue, setFormValue] = useState({
        email: "",
        password: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({ ...formValue, [name]: value })
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!formValue.email || !formValue.password) {
            console.log("Enter required fields.")
            return;
        } 
        loginUser(formValue);
    }

    const { email, password } = formValue;

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    }

    return (
        <div id="modal-root">
            <div 
                id="sign-in" 
                className="sign-in-modal modal modal--entered"
                >
                <div className="modal__backdrop" style={{transitionDuration: '300ms'}}></div>
                <div className="modal__content" style={{maxWidth: '480px', transitionDuration: '300ms'}}>
                    <div className="sign-in-modal__page sign-in-page">
                        <ul className="sign-in-page__menu top-menu sign-menu">
                            <Link 
                                className="top-menu__link link" 
                                variant="primary" 
                                to="/register"
                                >
                                <span className="top-menu__name">Register</span>
                            </Link>
                            <Link 
                                className="top-menu__link top-menu__link--current link" 
                                variant="primary" 
                                to="/login"
                                >
                                <span className="top-menu__name">Login</span>
                            </Link>
                        </ul>
                        <div className="sign-in-page__header heading5">
                            Welcome back!
                        </div>
                        
                        <div className="sign-in-page__body">
                            <div className="sign-in-page__form-wrapper"
                            onSubmit={handleSubmit}
                            >
                                <form 
                                    className="login-form sign-in-page__form form">
                                    <div className="form-element">
                                        
                                        <label className="input login-f\orm__input input--empty">      
                                            <input 
                                                className="input__native" 
                                                name="email" 
                                                id="emailInput"
                                                type="email" 
                                                autoComplete="off" 
                                                placeholder="" 
                                                value={email}
                                                onChange={handleChange}
                                                required 
                                                />
                                            <span className="input__label">
                                                your e-mail address
                                            </span>
                                            <span className="input__focus"></span>
                                        </label>

                                        <div className="collapse collapse--entered" style={{transitionDuration: '300ms', height: '22px'}}>
                                            <div className="collapse__content-wrapper">
                                                <div className="collapse__content">
                                                    <div className="form-element__error">
                                                        must be completed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-element">
                                        <div className="password-input login-form__input">
                                            
                                            <label className="input password-input__input input--empty">
                                                <input 
                                                    className="input__native" 
                                                    name="password" 
                                                    type={passwordShown ? "text" : "password"} 
                                                    autoComplete="off" 
                                                    placeholder="" 
                                                    value={password} 
                                                    onChange={handleChange}
                                                    required
                                                />
                                                <span className="input__label">
                                                    your password
                                                </span>
                                                <span className="input__focus"></span>
                                                <div className="input__preffix">
                                                    <button 
                                                        className="text-btn password-input__visibility-button" 
                                                        variant="primary" 
                                                        type="button"
                                                        onClick={togglePassword}
                                                        >
                                                        <VisibilityOutlinedIcon/>
                                                    </button>
                                                </div>
                                            </label>
                                        </div>
                                        
                                        <div className="collapse" style={{transitionDuration: '300ms'}}>
                                            <div className="collapse__content-wrapper">
                                                <div className="collapse__content"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="errors"></div>
                                    <div className="login-form__forgot-password-link-wrapper">
                                        <Link 
                                            className="login-form__forgot-password-link link" 
                                            variant="primary" 
                                            to="/forgotpassword">
                                            Forgot your password?
                                        </Link>
                                    </div>
                                    <button className="btn btn--danger btn--large btn--block" type="submit">Login</button>
                                    <div className="login-form__footer">
                                        <Link 
                                            className="registration-dynamic-form__link link" 
                                            variant="primary" 
                                            to="/register">
                                            Register
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                    
                    <button className="text-btn modal__close-button" variant="primary">
                        <Link to="/">
                            <CloseOutlinedIcon/>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Login
