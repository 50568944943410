import React from 'react'
import { Link } from 'react-router-dom'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';


function GamesFilterSearch() {
    return (
        <div className="games-filter__cell games-filter__cell--search">
            <div className="search-for-games-filter">
                <Link 
                    className="search-for-games-filter__button btn link-btn btn--secondary" 
                    to="?search">
                    <span className="search-for-games-filter__icon">
                        {/*<i className="icon-search-normal"></i>*/}
                        <SearchOutlinedIcon/>
                    </span>
                    <span className="search-for-games-filter__placeholder">search</span>
                </Link>
            </div>
        </div>
    )
}

export default GamesFilterSearch
