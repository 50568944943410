import './ProfileLayout.css';
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import ProfileSidebar from "./ProfileSidebar";
import SidebarHeader from "./Sidebar/SidebarHeader";

import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ResponsibleMain from "./ResponsibleGaming/ResponsibleMain";
import Promo from './Promo/Promo';
import GameHistory from './GameHistory/GameHistory';
import ProfileGeneral from './ProfileGeneral/ProfileGeneral';
import ProfileVerification from './ProfileVerification/ProfileVerification';
import ProfileSecurity from './ProfileSecurity/ProfileSecurity';
import WalletDeposit from './Wallet/WalletDeposit';
import WalletWithdrawel from './Wallet/WalletWithdrawel';
import WalletTransactionHistory from './Wallet/WalletTransactionHistory';


function ProfileLayout() {

  return (
    <div className="gSaNTA layout__container">

      <div className="kTbGNa breakpoints--desktop">
        <div className="jIpIlW sidebar__container">

          <SidebarHeader/>
          
          <div className="ekefSR sidebar__menu">

            <div className="fyYAFt sidebar__vertical-group">

              <Link 
                className="PNtMq menu-item__row"
                to="/profile/promo"
                >
                <div className="guQvBr menu-item__group">
                  <CardGiftcardOutlinedIcon className='sidebar-icons' />
                  <span className="hvGzuo menu-item__title">
                    Promo
                  </span>
                </div>
              </Link>
              
              <Link 
                className="PNtMq menu-item__row" 
                to="/profile/general"
                >
                <div className="guQvBr menu-item__group">
                  <PermIdentityOutlinedIcon className='sidebar-icons' />
                  <span className="hvGzuo menu-item__title">
                    Profile
                  </span>
                </div>
              </Link>
              
              <Link 
                className="PNtMq menu-item__row" 
                to="/profile/wallet"
                >
                <div className="guQvBr menu-item__group">
                  <AccountBalanceWalletOutlinedIcon className='sidebar-icons' />
                  <span className="hvGzuo menu-item__title">
                    Wallet
                  </span>
                </div>
              </Link>
              
              <Link 
                className="PNtMq menu-item__row" 
                to="/profile/game_history"
                >
                <div className="guQvBr menu-item__group">
                  <QueryBuilderOutlinedIcon className='sidebar-icons' />
                  <span className="hvGzuo menu-item__title">
                    Game History
                  </span>
                </div>
              </Link>
              
              <Link 
                className="PNtMq menu-item__row active" 
                to="/profile/limits"
                aria-current="page"
                >
                <div className="guQvBr menu-item__group">
                  <PanToolOutlinedIcon className='sidebar-icons' />
                  <span className="hvGzuo menu-item__title">
                    Responsible Gambling
                    </span>
                </div>
              </Link>

            </div>

            <div className="hQCeKK breakpoints--mobile-or-tablet">
              <div className="ezzqlh divider"></div>
              <div className="fyYAFt sidebar__vertical-group">
                <div name="language" role="combobox" aria-expanded="false" aria-haspopup="listbox"
                  aria-labelledby="downshift-1-label"
                  className="dtysCG select__container select__container--language language-select__container"
                  >

                  <div className="sc-fzoiQi PNtMq menu-item__row">
                    
                    <div className="sc-fzqARJ guQvBr menu-item__group">
                      <i className="icon icon-globe"></i>
                      <span className="sc-fzqNqU hvGzuo menu-item__title">Language</span>
                    </div>
                    
                    <span className="sc-fzoyTs gbRuai menu-item__semi-bold-text">en-AU</span>
                  </div>

                </div>
              </div>

              <div className="ezzqlh divider"></div>
              <div className="fyYAFt sidebar__vertical-group">

                <div className="PNtMq menu-item__row">
                  <div className="guQvBr menu-item__group">
                    <i className="icon icon-help"></i>
                    <span className="hvGzuo menu-item__title">Support</span>
                  </div>
                </div>

                <div className="PNtMq menu-item__row">
                  <div className="guQvBr menu-item__group">
                    <i className="icon icon-logout"></i>
                    <span className="hvGzuo menu-item__title">Log out</span>
                  </div>
                </div>

              </div>
              <div className="ezzqlh divider"></div>

              <div className="fyYAFt sidebar__vertical-group">
                <Link 
                  to="/casino"
                  className="PNtMq menu-item__row"
                  >
                  <div className="guQvBr menu-item__group">
                    <span className="hvGzuo menu-item__title">
                      All Games
                    </span>
                  </div>
                </Link>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className="gMRrhS drawer__wrapper drawer__wrapper--right">
        <div className="NgPCC drawer__overlay"></div>
        <div className="gLGlek drawer__content drawer__content--right">
          <ProfileSidebar />
        </div>
      </div>

      <div className="STczF layout__content-container">
        {/*<Promo />*/}

        {/*<ProfileGeneral />*/}
        {/*<ProfileVerification />*/}
        {/*<ProfileSecurity />*/}

        {<WalletDeposit />}
        {/*<WalletWithdrawel />*/}      
        {/*<WalletTransactionHistory />*/}

        {/*<GameHistory />*/}
        {/*<ResponsibleMain />*/}
      </div>
    </div>
   
  );

 
}

export default ProfileLayout;
