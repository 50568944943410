import { Link } from 'react-router-dom';
import './SidebarHeader.css';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


function SidebarHeader() {
  return (
    <div className="cqsFgi sidebar__header">
      <div className="kvpgjy navigation__container">
        <Link
          href="/"
          className="
          gwNWYc 
          bfAUwT 
          navigation__back-button 
          bfAUwT 
          navigation__back-button 
          styled-button 
          styled-button--primary 
          styled-button--sm"
        >
          Back to Casino
        </Link>
        <button
          className="eFMCar jdARWm navigation__close-button text-button text-button--primary"
          type="button"
        >
            <CloseOutlinedIcon />
        </button>
      </div>
      <div className="iOWyev player-account__wrapper">
        <div
          title="meliqbuild"
          className="jzOxXE player-account__user-name"
        >
          meliqbuild
        </div>
        <div className="VkQBT player-account__common-balance-container">
          <div className="gZgDfc player-account__container">
            <div className="bOoBAE player-account__balance-value">
              0
            </div>
            <div className="ktHqpt player-account__balance-currency">
              EUR
            </div>
          </div>
        </div>
        <div className="AfVto player-account__flex-container">
          <div className="eftFOV account-balance-info__wrapper">
            <div className="cTTObP account-balance-info__sum-wrapper">
              <div className="jNhATa account-balance-info__sum">
                0
              </div>
            </div>
            <div className="ejgoSR account-balance-info__title">
              Withdrawable
            </div>
          </div>
          <div className="eftFOV account-balance-info__wrapper">
            <div className="cTTObP account-balance-info__sum-wrapper">
              <div className="jNhATa account-balance-info__sum">
                0
              </div>
            </div>
            <div className="ejgoSR account-balance-info__title">
              Locked by bonus
            </div>
          </div>
        </div>
        <Link
          data-seo-id="deposit button profile main"
          className="gwNWYc epsrXo player-account__deposit-button epsrXo player-account__deposit-button styled-button styled-button--secondary styled-button--sm"
          variant="secondary"
          to="/profile/wallet/deposit"
        >
          Deposit
        </Link>
      </div>
    </div>
  );
}

export default SidebarHeader;
