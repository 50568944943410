import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translation files

import translationEN from "./languages/en.json";
import translationPT from "./languages/pt.json";
import translationRU from "./languages/ru.json";
import translationES from "./languages/es.json";

// Creating object with the variables of imported translation files
// Example
// https://medium.com/how-to-react/setup-multilingual-in-react-js-using-i18n-module-33b1bfbb57cd

const resources = {
    en: {
        translation: translationEN,
    },
    pt: {
        translation: translationPT,
    },
    ru: {
        translation: translationRU,
    },
    es: {
        translation: translationES,
    }
};

// i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en", // default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;