import React from 'react';
import { Link } from 'react-router-dom';

function BonusesItems(props) {
    return (
        <div className="bonuses-items__item">
            <img 
                className="bonuses-items__item-image image" 
                alt={props.imgAlt} 
                src={props.image} 
                />
            <div className="bonuses-items__item-wrap">
                <h3 className="bonuses-items__item-title">
                    {props.title}
                </h3>
                <p className="bonuses-items__item-description text">
                    {props.description}    
                </p>
                <div className="bonuses-items__item-buttons">
                    <Link 
                        className="bonuses-items__item-btn btn link-btn btn--danger btn--large btn--block" 
                        to="/register">
                        Get Bonus
                    </Link>
                    <button className="text-btn bonuses-items__item-link" variant="primary">
                        {props.readMore}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BonusesItems