import React from 'react';
import { Link } from "react-router-dom";

// Import images folder by input folder
function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../container/assets/payment/main-page', false, /\.(png|jpe?g|svg)$/));


function HomeHeaderPayment() {
    return (
        <div className="home-header__payments">
            <div
                className="home-header__container home-header__container--nosign container--small container">
                <div className="home-header__payments-items-wrap">

                    {
                        Object.keys(images).map((keyName, i) => (
                            <Link
                                key={i}
                                className="home-header__payments-item link"
                                variant="primary"
                                to="/payments">
                                <img
                                    className="home-header__payments-item--image image"
                                    src={images[keyName]} alt="image" />
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeHeaderPayment
