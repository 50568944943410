import React from 'react'

function GameInfo() {
    return (
        <div className="game-info">
            <div className="game-info__top">

                <div className="game-info__top-block">
                    <div className="game-info__top-text-wrap">
                        <div className="game-info__top-name">Lightning Roulette</div>
                        <div className="game-info__top-provider">evolution</div>
                    </div>
                </div>

                <div className="game-info__top-block game-info__top-block--info">
                    <div className="game-info__top-mini-block">
                        <div className="game-info__top-mini-name">Volatility</div>
                        <div className="game-info__top-mini-value"></div>
                    </div>
                    <div className="game-info__top-mini-block">
                        <div className="game-info__top-mini-name">playlines</div>
                        <div className="game-info__top-mini-value"></div>
                    </div>
                    <div className="game-info__btn-icon-wrap">
                        <i className="game-info__btn-arrow-icon icon-arrow"></i>
                    </div>
                </div>

            </div>
            <div className="game-info__dropdown">
                <div className="game-description"></div>
            </div>
        </div>

    )
}

export default GameInfo
