import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


function Auth() {

    const { t, i18n } = useTranslation();

    return (
        <>
            <Link 
                className="header__button btn link-btn btn--secondary" 
                to={'/login'}
                >
                {t('login')}
            </Link>
            
            <Link 
                className="header__button btn link-btn btn--primary" 
                to={'/register'}
                >
                {t('register')}
            </Link>
        </>
    )
}

export default Auth
