import { Link } from 'react-router-dom';

function WalletNavbar(props) {
    /*
    Input Data

    balance=""
    deposit=""
    withdrawal="active"
    transaction=""

    */
  return (
    <nav className="sc-pRFjI fexoHx tabs__container">
      <div className="sc-pZBmh huymOp tabs__wrapper">

        <Link
          className={`sc-oTbqq fmvNGy tabs__tab-link ${props.balance} ` }
          to="/wallet/balance"
        >
          Balance
        </Link>
        <span className="sc-paXsP iITJLX tabs__delimiter"></span>

        <Link
          aria-current="page"
          className={`sc-oTbqq fmvNGy tabs__tab-link ${props.deposit} ` }
          data-seo-id="deposit tab wallet tabs"
          to="/wallet/deposit"
        >
          deposit
        </Link>
        <span className="sc-paXsP iITJLX tabs__delimiter"></span>

        <Link
          className={`sc-oTbqq fmvNGy tabs__tab-link ${props.withdrawal} ` }
          to="/wallet/withdrawal"
        >
          Withdrawal
        </Link>
        <span className="sc-paXsP iITJLX tabs__delimiter"></span>

        <Link
          className={`sc-oTbqq fmvNGy tabs__tab-link ${props.transaction} ` }
          to="/wallet/transactions"
        >
          Transactions history
        </Link>
        <span className="sc-paXsP iITJLX tabs__delimiter"></span>
      </div>
    </nav>
  );
}
export default WalletNavbar;
