import React from 'react'
import { Link } from 'react-router-dom'

function HeaderWelcomeForm(props) {
    return (
        <div className="home-header__form">
            <h3>{props.welcomeTitle}</h3>
            <h4>{props.welcomeBonus}</h4>
            <p>{props.freeSpin}</p>

            <div className="home-header__btns-wrap">
                <Link
                    className="home-header__form_btn  deposit-button btn link-btn btn--primary" 
                    to="/register">
                    {props.getBonusButtonTitle}
                </Link>
                <Link
                    className="home-header__reg deposit-button btn link-btn btn--primary"
                    to="/register">
                    {props.createAccountUrlTitle}
                </Link>
            </div>
        </div>
    )
}

export default HeaderWelcomeForm
