import React from 'react';
import { Link } from 'react-router-dom';

import buttonPlay from '../../container/assets/button__play.svg';


function CatalogItem(props) {
    return (
        <div className="catalog__item">
            <article className="games-block-default__game-item game--md game">
               
                <div className="game__wrap">
                    <img 
                        className="game__img image" 
                        alt={props.alt} 
                        src={props.image} 
                    />

                    <img 
                        className="game__img image image--hidden" 
                        alt={props.alt} 
                        src={props.image} 
                    />
                    
                    <div className="game__content-wrap">
                        <div className="game__content">
                            <div className="game__title">
                                <Link 
                                    className="game__link link-no-styles" 
                                    variant="primary" 
                                    to={props.gameUrl}
                                    >
                                    {props.gameName}
                                </Link>
                            </div>

                            <div className="game__provider">{props.provider}</div>
                        </div>

                        <div className="game__action">
                            <button className="game__play">
                                <img 
                                    className="game__button image" 
                                    src={buttonPlay} 
                                    />
                            </button>

                            <button 
                                className="plain-btn game__demo btn--demo" 
                                variant="primary"
                                >
                                demo
                            </button>
                        </div>

                    </div>

                    <div className="game__label-list"></div>
                </div>
                
            </article>
        </div>
    )
}

export default CatalogItem
