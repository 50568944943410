import React from 'react';
import { Link } from 'react-router-dom';
import HeaderSlider from '../HeaderSlider/HeaderSlider';
import GamesCatalog from './GamesCatalog';
import GamesFilter from './GamesFilter';
import GamesHeader from './GamesHeader';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import playButton from '../../container/assets/button__play.svg';


function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const liveCasinoImages = importAll(require.context('../../container/assets/live-casino', false, /\.(png|jpe?g|svg|webp)$/));

const prividerImages = importAll(require.context('../../container/assets/providers', false, /\.(png|jpe?g|svg|webp)$/));



function LiveCasino() {
  return (
    <>
      <HeaderSlider
        subTitle="Live gaming world!"
        headerTitle="Try your chance!"
        headerDescription=""
      />
      <main className="layout__main">
        <div className="games">
          <div className="container">
            <div className="games__section">

              <div className="games__header">
                <div className="games__headline">
                  <h1 className="games__title title-accent">Live games</h1>

                  <div className="games__provider">
                    <div className="menu-providers" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="games-page-providers-label">
                      <div className="menu-providers__button" aria-autocomplete="list" aria-labelledby="games-page-providers-label" autoComplete="off" value="" id="games-page-providers" inputref="[object Object]">
                        <div className="menu-providers__button-inner subtitle3">Providers</div>
                        <div className="menu-providers__caret">
                          <KeyboardArrowDownOutlinedIcon/>
                        </div>
                      </div>
                      <div className="menu-providers__content" role="listbox" aria-labelledby="games-page-providers-label" id="games-page-providers-menu">
                        <div className="menu-providers__list">
                          {
                            Object.keys(prividerImages).map((keyName, i) => (
                              <div
                                key={i}
                                id="games-page-providers-item-0"
                                role="option"
                                aria-selected="false"
                                className="menu-providers__item"
                              >
                                <Link
                                  className="menu-providers__link link"
                                  variant="primary"
                                  to="/games/all/igtech">
                                  <img
                                    className="menu-providers__img image"
                                    src={prividerImages[keyName]}
                                  />
                                  IGTech
                                </Link>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="games__filter games-filter">
                <div className="games-filter__cell games-filter__cell--category">
                  <div className="games-filter__category games-filter__category--desktop">
                    <div className="game-category-menu">
                      <div className="game-category-menu__menu">
                        <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/top"><img className="game-category-menu__item-icon image" src="./assets/top.svg" /><span>Top Games</span></a>
                        <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/slots"><img className="game-category-menu__item-icon image" src="./assets/slots.svg" /><span>Slots</span></a>
                        <a className="game-category-menu__item-link subtitle2 link" variant="primary" href="https://www.crossgaming.com/games/bonus-buy"><img className="game-category-menu__item-icon image" src="./assets/bonus.svg" /><span>Bonus buy</span></a>
                        <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link-active link" variant="primary" href="https://www.crossgaming.com/games/live-games"><img className="game-category-menu__item-icon image" src="./assets/live.svg" /><span>Live Casino</span></a>
                        <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link__screen-xxl link" variant="primary" href="https://www.crossgaming.com/games/table"><img className="game-category-menu__item-icon image" src="./assets/card.svg" /><span>Table Games</span></a>
                        <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link__screen-xxl link" variant="primary" href="https://www.crossgaming.com/games/mystery-drops"><img className="game-category-menu__item-icon image" src="./assets/drop.svg" /><span>Mystery Drops</span></a>
                        <a className="game-category-menu__item-link subtitle2 game-category-menu__item-link__screen-xxl link" variant="primary" href="https://www.crossgaming.com/who-is-the-best-pilot"><img className="game-category-menu__item-icon image" src="./assets/aviator.svg" /><span>Aviator</span></a>
                        <div className="game-category-menu__item-link game-category-menu__item-link-features subtitle2">
                          <div className="game-features-select game-features-select--menu" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="game-features-label">
                            <div className="game-features-select__button" aria-autocomplete="list" aria-labelledby="game-features-label" autoComplete="off" value="" id="game-features" inputref="[object Object]"><img className="game-category-menu__item-icon image" src="./assets/top.svg" />
                              <div>
                                <div className="game-features-select__button-inner subtitle2">game-features</div>
                                <div className="game-features-select__caret">
                                  <KeyboardArrowDownOutlinedIcon/>
                                </div>
                              </div>
                            </div>
                            <div className="game-features-select__content" role="listbox" aria-labelledby="game-features-label" id="game-features-menu">
                              <div className="game-features-select__list">
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/table">Table Games</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/fast-play">Instant win</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/megaways">Megaways</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/scratch">Scratch cards</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/btc-games">BTC games</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/new">New games</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/hold-and-win">Hold &amp; Win</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/drops-and-wins">Drops &amp; Wins</a></div>
                                <div className="game-features-select__item"><a className="game-features-select__link body3 link" variant="primary" href="https://www.crossgaming.com/games/drops-and-wins-live">Drops &amp; Wins Live</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="games-filter__controls">
                  <div className="games-filter__cell games-filter__cell--search">
                    <div className="search-for-games-filter">
                      <Link className="search-for-games-filter__button btn link-btn btn--secondary" to="/live-games?search-modal=modal">
                        <span className="search-for-games-filter__icon">
                          <SearchOutlinedIcon/>
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="games-filter__cell games-filter__cell--providers games-filter__cell--home">
                    <div className="menu-providers" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="games-page-providers-label">
                      <div className="menu-providers__button" aria-autocomplete="list" aria-labelledby="games-page-providers-label" autoComplete="off" value="" id="games-page-providers" inputref="[object Object]">
                        <div className="menu-providers__button-inner subtitle3">providers</div>
                        <div className="menu-providers__caret">
                          <KeyboardArrowDownOutlinedIcon/>
                        </div>
                      </div>
                      <div className="menu-providers__content" role="listbox" aria-labelledby="games-page-providers-label" id="games-page-providers-menu">
                        <div className="menu-providers__list">
                          <div id="games-page-providers-item-0" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/igtech"><img className="menu-providers__img image" src="./assets/igtech.svg" />IGTech</a>
                          </div>
                          <div id="games-page-providers-item-1" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/pragmaticplay"><img className="menu-providers__img image" src="./assets/pragmaticplay.svg" />Pragmatic Play</a>
                          </div>
                          <div id="games-page-providers-item-2" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/pragmaticplaylive"><img className="menu-providers__img image" src="./assets/pragmaticplaylive.svg" />Pragmatic Play Live</a>
                          </div>
                          <div id="games-page-providers-item-3" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/evolution"><img className="menu-providers__img image" src="./assets/evolution.svg" />Evolution</a>
                          </div>
                          <div id="games-page-providers-item-4" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/playngo"><img className="menu-providers__img image" src="./assets/playngo.svg" />Play'n GO</a>
                          </div>
                          <div id="games-page-providers-item-5" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/netent"><img className="menu-providers__img image" src="./assets/netent_002.svg" />Netent</a>
                          </div>
                          <div id="games-page-providers-item-6" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/thunderkick"><img className="menu-providers__img image" src="./assets/thunderkick.svg" />Thunderkick</a>
                          </div>
                          <div id="games-page-providers-item-7" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/booongo"><img className="menu-providers__img image" src="./assets/booongo.svg" />Booongo</a>
                          </div>
                          <div id="games-page-providers-item-8" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bgaming"><img className="menu-providers__img image" src="./assets/bgaming_002.svg" />BGaming</a>
                          </div>
                          <div id="games-page-providers-item-9" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/nolimit"><img className="menu-providers__img image" src="./assets/nolimit.svg" />Nolimit</a>
                          </div>
                          <div id="games-page-providers-item-10" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/playson"><img className="menu-providers__img image" src="./assets/playson.svg" />Playson</a>
                          </div>
                          <div id="games-page-providers-item-11" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/luckystreak"><img className="menu-providers__img image" src="./assets/luckystreak.svg" />Lucky Streak</a>
                          </div>
                          <div id="games-page-providers-item-12" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/1spin4win"><img className="menu-providers__img image" src="./assets/1spin4win.svg" />1spin4win</a>
                          </div>
                          <div id="games-page-providers-item-13" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/relax"><img className="menu-providers__img image" src="./assets/relax.svg" />Relax Gaming</a>
                          </div>
                          <div id="games-page-providers-item-14" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/pushgaming"><img className="menu-providers__img image" src="./assets/pushgaming.svg" />Push Gaming</a>
                          </div>
                          <div id="games-page-providers-item-15" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/amatic"><img className="menu-providers__img image" src="./assets/amatic.svg" />Amatic</a>
                          </div>
                          <div id="games-page-providers-item-16" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/hacksaw"><img className="menu-providers__img image" src="./assets/hacksaw.svg" />Hacksaw</a>
                          </div>
                          <div id="games-page-providers-item-17" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/spinomenal"><img className="menu-providers__img image" src="./assets/spinomenal.svg" />Spinomenal</a>
                          </div>
                          <div id="games-page-providers-item-18" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/yggdrasil"><img className="menu-providers__img image" src="./assets/yggdrasil.svg" />Yggdrasil</a>
                          </div>
                          <div id="games-page-providers-item-19" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/elk"><img className="menu-providers__img image" src="./assets/elk.svg" />ELK</a>
                          </div>
                          <div id="games-page-providers-item-20" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/1x2gaming"><img className="menu-providers__img image" src="./assets/1x2gaming.svg" />1x2 Gaming</a>
                          </div>
                          <div id="games-page-providers-item-21" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/alg"><img className="menu-providers__img image" src="./assets/alg.svg" />Absolute Live Gaming</a>
                          </div>
                          <div id="games-page-providers-item-22" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/ainsworth"><img className="menu-providers__img image" src="./assets/ainsworth.svg" />Ainsworth</a>
                          </div>
                          <div id="games-page-providers-item-23" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/arcadem"><img className="menu-providers__img image" src="./assets/arcadem.svg" />Arcadem</a>
                          </div>
                          <div id="games-page-providers-item-24" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/atmosfera"><img className="menu-providers__img image" src="./assets/atmosfera.svg" />ATMOSFERA</a>
                          </div>
                          <div id="games-page-providers-item-25" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/authentic"><img className="menu-providers__img image" src="./assets/authentic.svg" />Authentic Gaming</a>
                          </div>
                          <div id="games-page-providers-item-26" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bigtimegaming"><img className="menu-providers__img image" src="./assets/bigtimegaming.svg" />BigTimeGaming</a>
                          </div>
                          <div id="games-page-providers-item-27" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bbgames"><img className="menu-providers__img image" src="./assets/bbgames.svg" />Bbgames</a>
                          </div>
                          <div id="games-page-providers-item-28" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bet2tech"><img className="menu-providers__img image" src="./assets/bet2tech.svg" />Bet2tech</a>
                          </div>
                          <div id="games-page-providers-item-29" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/booming"><img className="menu-providers__img image" src="./assets/booming.svg" />Booming Games</a>
                          </div>
                          <div id="games-page-providers-item-30" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/boomerangstudios"><img className="menu-providers__img image" src="./assets/boomerangstudios.svg" />Boomerangstudios</a>
                          </div>
                          <div id="games-page-providers-item-31" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bsg"><img className="menu-providers__img image" src="./assets/bsg.svg" />Betsoft Gaming</a>
                          </div>
                          <div id="games-page-providers-item-32" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/blueprint"><img className="menu-providers__img image" src="./assets/blueprint.svg" />Blueprint</a>
                          </div>
                          <div id="games-page-providers-item-33" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/belatra"><img className="menu-providers__img image" src="./assets/belatra.svg" />Belatra</a>
                          </div>
                          <div id="games-page-providers-item-34" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/caleta"><img className="menu-providers__img image" src="./assets/caleta.svg" />Caleta</a>
                          </div>
                          <div id="games-page-providers-item-35" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/candlebets"><img className="menu-providers__img image" src="./assets/candlebets.svg" />Candlebets</a>
                          </div>
                          <div id="games-page-providers-item-36" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/egt"><img className="menu-providers__img image" src="./assets/egt.svg" />EGT</a>
                          </div>
                          <div id="games-page-providers-item-37" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/electricelephant"><img className="menu-providers__img image" src="./assets/electricelephant.svg" />Electricelephant</a>
                          </div>
                          <div id="games-page-providers-item-38" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/endorphina"><img className="menu-providers__img image" src="./assets/endorphina.svg" />Endorphina</a>
                          </div>
                          <div id="games-page-providers-item-39" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/evoplay"><img className="menu-providers__img image" src="./assets/evoplay.svg" />Evoplay Entertainment</a>
                          </div>
                          <div id="games-page-providers-item-40" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/ezugi"><img className="menu-providers__img image" src="./assets/ezugi_002.svg" />Ezugi</a>
                          </div>
                          <div id="games-page-providers-item-41" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/fazi"><img className="menu-providers__img image" src="./assets/fazi.svg" />Fazi</a>
                          </div>
                          <div id="games-page-providers-item-42" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/felixgaming"><img className="menu-providers__img image" src="./assets/felixgaming.svg" />Felix Gaming</a>
                          </div>
                          <div id="games-page-providers-item-43" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/fugaso"><img className="menu-providers__img image" src="./assets/fugaso.svg" />Fugaso</a>
                          </div>
                          <div id="games-page-providers-item-44" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gamevy"><img className="menu-providers__img image" src="./assets/gamevy.svg" />Gamevy</a>
                          </div>
                          <div id="games-page-providers-item-45" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gameslab"><img className="menu-providers__img image" src="./assets/gameslab.svg" />Gameslab</a>
                          </div>
                          <div id="games-page-providers-item-46" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gamesinc"><img className="menu-providers__img image" src="./assets/gamesinc.svg" />Gamesinc</a>
                          </div>
                          <div id="games-page-providers-item-47" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gamomat"><img className="menu-providers__img image" src="./assets/gamomat.svg" />Gamomat</a>
                          </div>
                          <div id="games-page-providers-item-48" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gamzix"><img className="menu-providers__img image" src="./assets/gamzix.svg" />Gamzix</a>
                          </div>
                          <div id="games-page-providers-item-49" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/givme"><img className="menu-providers__img image" src="./assets/givme.svg" />Givme</a>
                          </div>
                          <div id="games-page-providers-item-50" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/goldenhero"><img className="menu-providers__img image" src="./assets/goldenhero.svg" />Goldenhero</a>
                          </div>
                          <div id="games-page-providers-item-51" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/greenjade"><img className="menu-providers__img image" src="./assets/greenjade.svg" />Greenjade</a>
                          </div>
                          <div id="games-page-providers-item-52" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gamebeat"><img className="menu-providers__img image" src="./assets/gamebeat.svg" />Gamebeat</a>
                          </div>
                          <div id="games-page-providers-item-53" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/gameart"><img className="menu-providers__img image" src="./assets/gameart.svg" />GameArt</a>
                          </div>
                          <div id="games-page-providers-item-54" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/high5"><img className="menu-providers__img image" src="./assets/high5.svg" />High5Games</a>
                          </div>
                          <div id="games-page-providers-item-55" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/habanero"><img className="menu-providers__img image" src="./assets/habanero.svg" />Habanero</a>
                          </div>
                          <div id="games-page-providers-item-56" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/isb"><img className="menu-providers__img image" src="./assets/isb.svg" />iSoftBet</a>
                          </div>
                          <div id="games-page-providers-item-57" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/isoftbet"><img className="menu-providers__img image" src="./assets/isoftbet.svg" />iSoftBet</a>
                          </div>
                          <div id="games-page-providers-item-58" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/irondogstudio"><img className="menu-providers__img image" src="./assets/irondogstudio.svg" />IronDogStudio</a>
                          </div>
                          <div id="games-page-providers-item-59" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/jaderabbit"><img className="menu-providers__img image" src="./assets/jaderabbit.svg" />Jaderabbit</a>
                          </div>
                          <div id="games-page-providers-item-60" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/kalamba"><img className="menu-providers__img image" src="./assets/kalamba.svg" />Kalamba</a>
                          </div>
                          <div id="games-page-providers-item-61" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/leander"><img className="menu-providers__img image" src="./assets/leander.svg" />Leander</a>
                          </div>
                          <div id="games-page-providers-item-62" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/liveslots"><img className="menu-providers__img image" src="./assets/liveslots.svg" />LiveSlots</a>
                          </div>
                          <div id="games-page-providers-item-63" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/lucky"><img className="menu-providers__img image" src="./assets/lucky.svg" />Reevo</a>
                          </div>
                          <div id="games-page-providers-item-64" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/mancala"><img className="menu-providers__img image" src="./assets/mancala.svg" />Mancala Gaming</a>
                          </div>
                          <div id="games-page-providers-item-65" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/mascot"><img className="menu-providers__img image" src="./assets/mascot.svg" />Mascot</a>
                          </div>
                          <div id="games-page-providers-item-66" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/mrslotty"><img className="menu-providers__img image" src="./assets/mrslotty.svg" />MrSlotty</a>
                          </div>
                          <div id="games-page-providers-item-67" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/merkur"><img className="menu-providers__img image" src="./assets/merkur.svg" />Merkur</a>
                          </div>
                          <div id="games-page-providers-item-68" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/northernlights"><img className="menu-providers__img image" src="./assets/northernlights.svg" />Northernlights</a>
                          </div>
                          <div id="games-page-providers-item-69" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/nucleus"><img className="menu-providers__img image" src="./assets/nucleus.svg" />Nucleus Gaming</a>
                          </div>
                          <div id="games-page-providers-item-70" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/netgame"><img className="menu-providers__img image" src="./assets/netgame.svg" />NetGame</a>
                          </div>
                          <div id="games-page-providers-item-71" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/onlyplay"><img className="menu-providers__img image" src="./assets/onlyplay.svg" />Onlyplay</a>
                          </div>
                          <div id="games-page-providers-item-72" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/oryx"><img className="menu-providers__img image" src="./assets/oryx.svg" />Oryx</a>
                          </div>
                          <div id="games-page-providers-item-73" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/onetouch"><img className="menu-providers__img image" src="./assets/onetouch.svg" />Onetouch</a>
                          </div>
                          <div id="games-page-providers-item-74" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/provisiongaming"><img className="menu-providers__img image" src="./assets/provisiongaming.svg" />Provisiongaming</a>
                          </div>
                          <div id="games-page-providers-item-75" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/pgsoft"><img className="menu-providers__img image" src="./assets/pgsoft.svg" />Pgsoft</a>
                          </div>
                          <div id="games-page-providers-item-76" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/playtech"><img className="menu-providers__img image" src="./assets/playtech.svg" />Playtech</a>
                          </div>
                          <div id="games-page-providers-item-77" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/petersons"><img className="menu-providers__img image" src="./assets/petersons.svg" />Petersons</a>
                          </div>
                          <div id="games-page-providers-item-78" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/printstudios"><img className="menu-providers__img image" src="./assets/printstudios.svg" />Printstudios</a>
                          </div>
                          <div id="games-page-providers-item-79" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/platipus"><img className="menu-providers__img image" src="./assets/platipus.svg" />Platipus</a>
                          </div>
                          <div id="games-page-providers-item-80" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/quickfire"><img className="menu-providers__img image" src="./assets/quickfire.svg" />Quickfire</a>
                          </div>
                          <div id="games-page-providers-item-81" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/quickspin"><img className="menu-providers__img image" src="./assets/quickspin.svg" />Quickspin</a>
                          </div>
                          <div id="games-page-providers-item-82" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/reelplay"><img className="menu-providers__img image" src="./assets/reelplay.svg" />Reelplay</a>
                          </div>
                          <div id="games-page-providers-item-83" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/redtiger"><img className="menu-providers__img image" src="./assets/redtiger.svg" />Red Tiger Gaming</a>
                          </div>
                          <div id="games-page-providers-item-84" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/retrogaming"><img className="menu-providers__img image" src="./assets/retrogaming.svg" />Retrogaming</a>
                          </div>
                          <div id="games-page-providers-item-85" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/revolver"><img className="menu-providers__img image" src="./assets/revolver.svg" />Revolver</a>
                          </div>
                          <div id="games-page-providers-item-86" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/swintt"><img className="menu-providers__img image" src="./assets/swintt.svg" />Swintt</a>
                          </div>
                          <div id="games-page-providers-item-87" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/spearhead"><img className="menu-providers__img image" src="./assets/spearhead.svg" />Spearhead</a>
                          </div>
                          <div id="games-page-providers-item-88" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/silverback"><img className="menu-providers__img image" src="./assets/silverback.svg" />Silverback</a>
                          </div>
                          <div id="games-page-providers-item-89" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/spribe"><img className="menu-providers__img image" src="./assets/spribe.svg" />Spribe</a>
                          </div>
                          <div id="games-page-providers-item-90" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/sthlmgaming"><img className="menu-providers__img image" src="./assets/sthlmgaming.svg" />Sthlmgaming</a>
                          </div>
                          <div id="games-page-providers-item-91" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/smartsoft"><img className="menu-providers__img image" src="./assets/smartsoft.svg" />SmartSoft</a>
                          </div>
                          <div id="games-page-providers-item-92" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/tomhorn"><img className="menu-providers__img image" src="./assets/tomhorn.svg" />Tom Horn Gaming</a>
                          </div>
                          <div id="games-page-providers-item-93" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/technology"><img className="menu-providers__img image" src="./assets/technology.svg" />Casino Technology</a>
                          </div>
                          <div id="games-page-providers-item-94" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/truelab"><img className="menu-providers__img image" src="./assets/truelab.svg" />TrueLab</a>
                          </div>
                          <div id="games-page-providers-item-95" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/vivogaming"><img className="menu-providers__img image" src="./assets/vivogaming.svg" />Vivogaming</a>
                          </div>
                          <div id="games-page-providers-item-96" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/wazdan"><img className="menu-providers__img image" src="./assets/wazdan.svg" />Wazdan</a>
                          </div>
                          <div id="games-page-providers-item-97" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/zillion"><img className="menu-providers__img image" src="./assets/zillion.svg" />Zillion</a>
                          </div>
                          <div id="games-page-providers-item-98" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/goldenrock"><img className="menu-providers__img image" src="./assets/goldenrock.svg" />Goldenrock</a>
                          </div>
                          <div id="games-page-providers-item-99" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/4theplayer"><img className="menu-providers__img image" src="./assets/4theplayer.svg" />4theplayer</a>
                          </div>
                          <div id="games-page-providers-item-100" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/fantasma"><img className="menu-providers__img image" src="./assets/fantasma.svg" />Fantasma</a>
                          </div>
                          <div id="games-page-providers-item-101" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/maxwingaming"><img className="menu-providers__img image" src="./assets/maxwingaming.svg" />Maxwingaming</a>
                          </div>
                          <div id="games-page-providers-item-102" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/fourleafgaming"><img className="menu-providers__img image" src="./assets/fourleafgaming.svg" />Fourleafgaming</a>
                          </div>
                          <div id="games-page-providers-item-103" role="option" aria-selected="false" className="menu-providers__item">
                            <a className="menu-providers__link link" variant="primary" href="https://www.crossgaming.com/games/all/bluegurugames"><img className="menu-providers__img image" src="./assets/bluegurugames.svg" />Bluegurugames</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="games__catalog">
                <div className="catalog">
                  <div className="catalog__list">
                    {
                      Object.keys(liveCasinoImages).map((keyName, i) => (
                        <div key={i} className="catalog__item">
                          <article className="games-block-default__game-item game--md game game--mystery">
                            <div className="game__wrap">
                              <span></span>

                              <img
                                className="game__img image"
                                alt="Sweet Bonanza Candyland game tile"
                                src={liveCasinoImages[keyName]}
                              />

                              <img
                                className="game__img image image--hidden"
                                alt="Sweet Bonanza Candyland game tile"
                                src={liveCasinoImages[keyName]}
                              />

                              <div className="game__content-wrap">
                                <div className="game__content">
                                  <div className="game__title">
                                    <Link
                                      className="game__link link-no-styles"
                                      variant="primary"
                                      to="/popup">
                                      Sweet Bonanza Candyland
                                    </Link>
                                  </div>
                                  <div className="game__provider">pragmaticplaylive</div>
                                </div>

                                <div className="game__action">
                                  
                                  <Link className="game__play" to="/popup">
                                    <img
                                      className="game__button image"
                                      src={playButton}
                                    />
                                  </Link>

                                  <Link 
                                    className="plain-btn game__demo btn--demo" 
                                    variant="primary"
                                    to="/popup" 
                                    >
                                    Demo
                                  </Link>

                                </div>

                              </div>
                              <div className="game__label-list"></div>
                            </div>
                          </article>
                        </div>
                      ))
                    }
                  </div>

                  <div className="catalog__more">
                    <button className="btn--more btn btn--secondary btn--large btn--block">
                      Show More
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default LiveCasino;
