import ProfileHeader from "../ProfileHeader";
import ProfileLayout from "../ProfileLayout";
import ProfileNotification from "../ProfileNotification";

function ProfileResponsibleGaming() {
    return (
        <>
            <ProfileNotification/>
            <ProfileHeader/>
            <ProfileLayout />
        </>
    )
}
export default ProfileResponsibleGaming;