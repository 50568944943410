import FastCatalogItem from "./FastCatalogItem";

const FastGamesCatalog = (props) => {
  return (
    <div className="games__catalog">
      <div className="catalog">
        <div className="catalog__list">
          {/* Object to Map Affiliate partners images*/}
          {props.games &&
            props.games.map((item, index) => {
              return (
                <FastCatalogItem
                  key={index}
                  alt={item.name}
                  image={`https://static.fiable.games/images/fiable/${item.image_name}`}
                  gameUrl={item.url}
                  gameName={item.name}
                  provider="Fiable Games"
                />
              );
            })}
        </div>

        {/*<div className="catalog__more">
                    <button 
                        className="btn--more btn btn--secondary btn--large btn--block"
                        >
                        show more
                    </button>
                </div>*/}
      </div>
    </div>
  );
};
export default FastGamesCatalog;
