function ProfileNotification() {
  return (
    <div className="kjcEaz notification-container">
      <div className="rnc__base ">
        <div className="rnc__notification-container--top-full"></div>
        <div className="rnc__notification-container--bottom-full"></div>
        <div className="rnc__notification-container--top-left"></div>
        <div className="rnc__notification-container--top-right"></div>
        <div className="rnc__notification-container--bottom-left"></div>
        <div className="rnc__notification-container--bottom-right"></div>
        <div className="rnc__notification-container--top-center"></div>
        <div className="rnc__notification-container--center">
          <div className="rnc__util--flex-center"></div>
        </div>
        <div className="rnc__notification-container--bottom-center"></div>
      </div>
    </div>
  );
}

export default ProfileNotification;
