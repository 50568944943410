
function TVBET() {
    const fullScreen = `
      "position": "fixed", 
                      "top": "0",
                      "left": "0",
                      "bottom": "0", 
                      "right": "0", 
                      "width": "100%", 
                      "height": "100%", 
                      "border": "none", 
                      "margin": "0", 
                      "padding": "0", 
                      "overflow": "hidden", 
                      "zIndex": "999999",`;
    return (
      <div>
        <iframe 
          src="https://tvbetframe.com/?lng=en&clientId=6825" 
          title="TVBet"
          style={{
              "height": "calc(100vh - 4px)",
              "width": "calc(100vw - 4px)",
              "box-sizing": "border-box"
          }}
          allow="fullscreen"
          frameBorder="0"
          >
          Your browser doesn't support iframes
        </iframe>
      </div>
    );
  }
  
  export default TVBET;
  