import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

function ForgotPassword() {
    const navigate = useNavigate();

    const [formValue, setFormValue] = useState({
        email: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValue({ ...formValue, [name]: value })
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!formValue.email ) {
            console.log("Enter required fields.")
            return;
        } 
        // Rend Request
    }

    const { email } = formValue;

  return (
    <div id="modal-root">
      <div id="forgot-password" className="email-modal modal modal--entered">
        <div
          className="modal__backdrop"
          style={{ transitionDuration: "300ms" }}
        ></div>
        <div
          className="modal__content"
          style={{ maxWidth: "480px", transitionDuration: "300ms" }}
        >
          <section className="user-page-container">
            <h1 className="heading5">forgot your password?</h1>
            <div className="text">Change your password</div>
            <form className="auth-form" onSubmit={handleSubmit} >
              <div className="auth-form__form-element-wrap">
                <div className="form-element">
                  <label className="input input--empty">
                    <input
                      className="input__native"
                      name="email"
                      type="email"
                      autoComplete="off"
                      placeholder=" "
                      value={email}
                      onChange={handleChange}
                      required
                    />
                    <span className="input__label">your e-mail address</span>
                    <span className="input__focus"></span>
                  </label>
                  <div
                    className="collapse collapse--entered"
                    style={{ transitionDuration: "300ms", height: "19px" }}
                  >
                    <div className="collapse__content-wrapper">
                      <div className="collapse__content">
                        <div className="form-element__error">
                          must be completed
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="captcha-term-and-policy">
                This site is protected by reCAPTCHA and the Google{" "}
                <a
                  className="captcha-term-and-policy__link"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                and{" "}
                <Link
                  className="captcha-term-and-policy__link"
                  to="/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </Link>{" "}
                apply.
              </p>
              <button
                className="btn btn--danger btn--large btn--block"
                type="submit"
              >
                reset password
              </button>
              <Link
                className="auth-form__sign link-text-btn"
                to="/login"
                variant="primary"
              >
                <ArrowBackOutlinedIcon/>
                <span style={{ marginLeft: "10px" }}>Sign in</span>
              </Link>
              <div className="auth-form__instructions">
                <Link
                  className="auth-form__link link"
                  variant="primary"
                  to="/resend-unlock"
                >
                  Didn't receive unlock instructions?
                </Link>
              </div>
            </form>
          </section>
          <button 
            className="text-btn modal__close-button" 
            variant="primary"
            onClick={ () => navigate(`/`)}
            >
            <CloseOutlinedIcon/>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
