import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

import AuthContext from '../../context/AuthContext';


function Register() {
    const { user, registerUser, isAuthenticated } = useContext(AuthContext);

    const [passwordShown, setPasswordShown] = useState(false);
    const [formValue, setFormValue] = useState({

        firstName: "",
        lastName: "",
        dateOfBirth: "",
        email: "",
        country: "Armenia",
        countryCode: "374",
        city: "",
        address: "",
        mobileNumber: "",
        userName: "",
        currency: "EUR",
        password: "",
        passwordVerify: "",
        receive_all_promos: true,
        agreeTermsConditions: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormValue({ ...formValue, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Checking input data is empty or not
        if (
            !formValue.firstName ||
            !formValue.lastName ||
            !formValue.dateOfBirth ||
            !formValue.email ||
            !formValue.country ||
            !formValue.countryCode ||
            !formValue.city ||
            !formValue.address ||
            !formValue.mobileNumber ||
            !formValue.userName ||
            !formValue.currency ||
            !formValue.password ||
            !formValue.passwordVerify || 
            !formValue.receiveAllPromos ||
            !formValue.agreeTermsConditions
        ) {
            console.log("Please add all required fields")
        }

        // Checking password is matching or not
        if (formValue.password !== formValue.passwordVerify) {
            //toast.error("Password does not match.");
            return;
        }

        registerUser(formValue);
    }

    const {
        email,
        firstName,
        lastName,
        dateOfBirth,
        city,
        address,
        mobileNumber,
        userName,
        password,
        passwordVerify,
        currency,
        country,
        countryCode,
        receiveAllPromos,
        agreeTermsConditions
    } = formValue;

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };


    return (
        <div id="modal-root">
            <div id="sign-up" className="sign-up-modal modal modal--entered">
                <div className="modal__backdrop" style={{ transitionDuration: '300ms' }}></div>
                <div className="modal__content" style={{ transitionDuration: '300ms' }}>
                    <div className="sign-up-modal__page sign-up-page">
                        <ul className="sign-up-page__menu top-menu sign-menu">

                            <Link
                                className="top-menu__link top-menu__link--current link"
                                variant="primary"
                                to="/register">
                                <span className="top-menu__name">Register</span>
                            </Link>

                            <Link
                                className="top-menu__link link"
                                variant="primary"
                                to="/login">
                                <span className="top-menu__name">Login</span>
                            </Link>
                        </ul>

                        <div className="registr-promo-xs">
                            <div className="registr-promo--mobile">
                                <span className="heading1">
                                    Welcome Bonus <br /> 1,000 EUR <br /> + 100 Free Spins
                                </span>
                            </div>
                            <img
                                src="./Spirit of competition!_files/gold.a10a1949.png"
                                className="registr-promo-xs__img"
                                alt="sign-up" 
                                />
                        </div>

                        <div className="registr-promo-xl">
                            <div className="register-promo--desctop">
                                <h6>Welcome bonus</h6>
                                <h4 className="font-extra text-accent">Get 1,000 EUR</h4>
                                <h6>+ 100 Free Spins</h6>
                            </div>
                        </div>

                        <div className="sign-up-page__form">
                            <h5 className="sign-up-page__form-title">Welcome to Cross Gaming</h5>
                            <div className="registration-dynamic-form registration-form">
                                <div className="registration-dynamic-form__form-wrapper">

                                    <form
                                        className="registration-dynamic-form__form form"
                                        onSubmit={handleSubmit}
                                    >

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--email form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="email">First Name*</label>
                                            </div>

                                            <label className="input registration-dynamic-form__input registration-dynamic-form__email input--empty">
                                                <input
                                                    className="input__native"
                                                    name="firstName"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    id="firstName"
                                                    required=""
                                                    value={firstName}
                                                    onChange={handleChange}
                                                />
                                                <span className="input__label">First Name</span>
                                                <span className="input__focus"></span>
                                            </label>

                                            <div className="collapse collapse--entered" style={{ transitionDuration: '300ms', height: '22px' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content">
                                                        <div className="form-element__error">must be completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--email form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="email">Last Name*</label>
                                            </div>

                                            <label className="input registration-dynamic-form__input registration-dynamic-form__email input--empty">
                                                <input
                                                    className="input__native"
                                                    name="lastName"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    id="lastName"
                                                    required=""
                                                    value={lastName}
                                                    onChange={handleChange}
                                                />
                                                <span className="input__label">Last Name</span>
                                                <span className="input__focus"></span>
                                            </label>

                                            <div className="collapse collapse--entered" style={{ transitionDuration: '300ms', height: '22px' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content">
                                                        <div className="form-element__error">must be completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--email form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="email">Data of birth*</label>
                                            </div>

                                            <label className="input registration-dynamic-form__input registration-dynamic-form__email input--empty">
                                                <input
                                                    className="input__native"
                                                    name="dateOfBirth"
                                                    type="date"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    id="dateOfBirth"
                                                    required=""
                                                    value={dateOfBirth}
                                                    onChange={handleChange}
                                                />
                                                <span className="input__label">Data of birth</span>
                                                <span className="input__focus"></span>
                                            </label>

                                            <div className="collapse collapse--entered" style={{ transitionDuration: '300ms', height: '22px' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content">
                                                        <div className="form-element__error">must be completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--email form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="email">User name*</label>
                                            </div>

                                            <label className="input registration-dynamic-form__input registration-dynamic-form__email input--empty">
                                                <input
                                                    className="input__native"
                                                    name="userName"
                                                    type="text"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    id="userName"
                                                    required=""
                                                    value={userName}
                                                    onChange={handleChange}
                                                />
                                                <span className="input__label">User name</span>
                                                <span className="input__focus"></span>
                                            </label>

                                            <div className="collapse collapse--entered" style={{ transitionDuration: '300ms', height: '22px' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content">
                                                        <div className="form-element__error">must be completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--email form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="email">Email*</label>
                                            </div>

                                            <label className="input registration-dynamic-form__input registration-dynamic-form__email input--empty">
                                                <input
                                                    className="input__native"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="off"
                                                    placeholder=""
                                                    id="email"
                                                    required=""
                                                    value={email}
                                                    onChange={handleChange}
                                                />
                                                <span className="input__label">Email</span>
                                                <span className="input__focus"></span>
                                            </label>

                                            <div className="collapse collapse--entered" style={{ transitionDuration: '300ms', height: '22px' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content">
                                                        <div className="form-element__error">must be completed</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--password_single form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="password_single">
                                                    Password*
                                                </label>
                                            </div>

                                            <div className="password-input registration-dynamic-form__password_input registration-dynamic-form__password_single">
                                                <label className="input password-input__input input--empty">
                                                    <input
                                                        className="input__native"
                                                        name="password"
                                                        type={passwordShown ? "text" : "password"}
                                                        autoComplete="off"
                                                        placeholder=""
                                                        id="password"
                                                        required=""
                                                        value={password}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Password</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <button
                                                            className="text-btn password-input__visibility-button"
                                                            variant="primary"
                                                            type="button"
                                                            onClick={togglePassword}
                                                        >
                                                            <VisibilityOutlinedIcon/>
                                                        </button>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--password_single form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="password_single">
                                                    Verify Password*
                                                </label>
                                            </div>

                                            <div className="password-input registration-dynamic-form__password_input registration-dynamic-form__password_single">
                                                <label className="input password-input__input input--empty">
                                                    <input
                                                        className="input__native"
                                                        name="passwordVerify"
                                                        type={passwordShown ? "text" : "password"}
                                                        autoComplete="off"
                                                        placeholder=""
                                                        id="passwordVerify"
                                                        required=""
                                                        value={passwordVerify}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Verify Password</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <button
                                                            className="text-btn password-input__visibility-button"
                                                            variant="primary"
                                                            type="button"
                                                            onClick={togglePassword}
                                                        >
                                                            <VisibilityOutlinedIcon/>
                                                        </button>
                                                    </div>
                                                </label>
                                            </div>

                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--currency form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="currency">
                                                    Currency*
                                                </label>
                                            </div>
                                            <div className="registration-dynamic-form__currency-select registration-dynamic-form__currency currency-select select" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="currency-label">
                                                <label className="input select__input">
                                                    <input
                                                        className="input__native"
                                                        name="currency"
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly=""
                                                        placeholder=""
                                                        aria-autocomplete="list"
                                                        aria-labelledby="currency-label"
                                                        id="currency"
                                                        value={currency}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Currency</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <div className="select__arrow-icon-wrapper">
                                                            <KeyboardArrowDownOutlinedIcon/>
                                                        </div>
                                                    </div></label>
                                                <div className="select__bottom-line">
                                                    <div className="select__dropdown" role="listbox" aria-labelledby="currency-label" id="currency-menu"></div>
                                                </div>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--country form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="country">
                                                    Address*
                                                </label>
                                            </div>
                                            <div className="registration-dynamic-form__country-select registration-dynamic-form__country country-select select" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="address-label">
                                                <label className="input select__input">
                                                    <input
                                                        className="input__native"
                                                        name="address"
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly=""
                                                        placeholder=" "
                                                        aria-autocomplete="list"
                                                        aria-labelledby="address-label"
                                                        id="address"
                                                        value={address}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Address</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <div className="select__arrow-icon-wrapper">
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="select__bottom-line">
                                                    <div className="select__dropdown" role="listbox" aria-labelledby="country-label" id="country-menu"></div>
                                                </div>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--country form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="country">
                                                    City*
                                                </label>
                                            </div>
                                            <div className="registration-dynamic-form__country-select registration-dynamic-form__country country-select select" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="city-label">
                                                <label className="input select__input">
                                                    <input
                                                        className="input__native"
                                                        name="city"
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly=""
                                                        placeholder=" "
                                                        aria-autocomplete="list"
                                                        aria-labelledby="city-label"
                                                        id="city"
                                                        value={city}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">City</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <div className="select__arrow-icon-wrapper">
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="select__bottom-line">
                                                    <div className="select__dropdown" role="listbox" aria-labelledby="country-label" id="country-menu"></div>
                                                </div>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--country form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="country">
                                                    Country*
                                                </label>
                                            </div>
                                            <div className="registration-dynamic-form__country-select registration-dynamic-form__country country-select select" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="country-label">
                                                <label className="input select__input">
                                                    <input
                                                        className="input__native"
                                                        name="country"
                                                        type="text"
                                                        autoComplete="off"
                                                        readOnly=""
                                                        placeholder=" "
                                                        aria-autocomplete="list"
                                                        aria-labelledby="country-label"
                                                        id="country"
                                                        value={country}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Country</span>
                                                    <span className="input__focus"></span>
                                                    <div className="input__preffix">
                                                        <div className="select__arrow-icon-wrapper">
                                                            <KeyboardArrowDownOutlinedIcon/>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="select__bottom-line">
                                                    <div className="select__dropdown" role="listbox" aria-labelledby="country-label" id="country-menu"></div>
                                                </div>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--mobile_phone form-element">
                                            <div className="registration-dynamic-form__label-wrapper">
                                                <label className="registration-dynamic-form__label" htmlFor="mobile_phone">
                                                    Mobile number*
                                                </label>
                                            </div>

                                            <div className="registration-dynamic-form__tel registration-dynamic-form__mobile_phone tel">
                                                <div className="tel__code-select tel-code-select select-with-country-icons" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="mobile_phone-code-label">
                                                    <label className="input select-with-country-icons__input input--phone">
                                                        <div className="input__left-container">
                                                            <img className="select-with-country-icons__input-country-icon flag-icon image" alt="AM" src="" />
                                                        </div>
                                                        <input
                                                            className="input__native"
                                                            name="countryCode"
                                                            type="text"
                                                            autoComplete="off"
                                                            placeholder=""
                                                            aria-autocomplete="list"
                                                            aria-labelledby="mobile_phone-code-label"
                                                            id="countryCode"
                                                            value={countryCode}
                                                            onChange={handleChange}
                                                        />
                                                        <span className="input__label">Code</span>
                                                        <span className="input__focus"></span>
                                                        <div className="input__preffix">
                                                            <div className="select-with-country-icons__arrow-icon-wrapper">
                                                                <i className="select-with-country-icons__arrow-icon icon-select"></i>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div className="select-with-country-icons__bottom-line">
                                                        <div className="select-with-country-icons__dropdown" role="listbox" aria-labelledby="mobile_phone-code-label" id="mobile_phone-code-menu"></div>
                                                    </div>
                                                </div><label className="input tel__number-input input--empty">
                                                    <input
                                                        className="input__native"
                                                        name="mobileNumber"
                                                        type="tel"
                                                        autoComplete="off"
                                                        id="mobileNumber"
                                                        placeholder=""
                                                        value={mobileNumber}
                                                        onChange={handleChange}
                                                    />
                                                    <span className="input__label">Number</span>
                                                    <span className="input__focus"></span>
                                                </label>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--receive_all_promos form-element">
                                            <div className="registration-dynamic-form__checkbox registration-dynamic-form__receive_all_promos checkbox checkbox--default">
                                                <input
                                                    id="receive_all_promos"
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    name="receiveAllPromos"
                                                    value={receiveAllPromos}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="receive_all_promos" tabIndex="0" className="checkbox__block">
                                                    <span className="checkbox__point"></span>
                                                    <span className="checkbox__label">Receive promos</span>
                                                </label>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="registration-dynamic-form__element registration-dynamic-form__element--age_terms_acceptance form-element">
                                            <div className="registration-dynamic-form__terms-acceptance registration-dynamic-form__age_terms_acceptance terms-acceptance checkbox checkbox--default">
                                                <input
                                                    id="agreeTermsConditions"
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    name="agreeTermsConditions"
                                                    value={agreeTermsConditions}
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="age_terms_acceptance" tabIndex="0" className="checkbox__block">
                                                    <span className="checkbox__point"></span>
                                                    <span className="checkbox__label">
                                                        <span className="terms-acceptance__label">I am 18 years old and I accept the
                                                            <span className="terms-acceptance__links-wrapper">
                                                                <Link
                                                                    className="terms-acceptance__privacy-policy-link link"
                                                                    variant="primary"
                                                                    to="/privacy-policy"
                                                                    target="_blank">
                                                                    &nbsp;Privacy Policy
                                                                </Link> and
                                                                <Link
                                                                    className="terms-acceptance__terms-link link"
                                                                    variant="primary"
                                                                    to="/terms-and-conditions"
                                                                    target="_blank">
                                                                    &nbsp;Terms and Conditions
                                                                </Link>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                            </div>
                                            <div className="collapse" style={{ transitionDuration: '300ms' }}>
                                                <div className="collapse__content-wrapper">
                                                    <div className="collapse__content"></div>
                                                </div>
                                            </div>
                                        </div>


                                        <button className="registration-dynamic-form__submit-button btn btn--danger" type="submit" formNoValidate="">
                                            Register
                                        </button>
                                        <div className="registration-dynamic-form__footer">
                                            <div className="registration-dynamic-form__link--text">Have an account?</div>
                                            <Link
                                                className="registration-dynamic-form__link link"
                                                variant="primary"
                                                to="/login">
                                                Login
                                            </Link>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="text-btn modal__close-button" variant="primary">
                        <Link to="/">
                            <CloseOutlinedIcon/>
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Register
