import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { useState } from 'react';


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../container/assets/language', false, /\.(png|jpe?g|svg|webp)$/));


function Language(props) {

    const [showDropdown, setShowDropdown] = useState(false);
    const [language, setLanguage] = useState('en');


    //Calling t and i18n method from useTranslation hook 
    const { t, i18n } = useTranslation();

    //Creating a method to change the language onChnage from select box
    const changeLanguageHandler = (languageValue) => {
        //const languageValue = e.target.value
        i18n.changeLanguage(languageValue);
    }

    const dropdownMenu = () => {
        if (!showDropdown) {
            setShowDropdown(true); 
        } else {
            setShowDropdown(false);
        }
    }

    return (
        <div
            className={showDropdown ? "header__locales player-language-select select-language-icons-with-code select-language-icons-with-code--open" : "header__locales player-language-select select-language-icons-with-code"}
            onClick={dropdownMenu}
            role="combobox"
            aria-expanded="false"
            aria-haspopup="listbox"
            aria-labelledby="lang-select-label">

            {/*<select style={{width: 200}} onChange={changeLanguageHandler}>
                    <option value="en" >English</option>
                    <option value="pt" >Portugalian</option>
    </select>*/}

            <div
                className="select-language-icons-with-code__button"
                aria-autocomplete="list"
                aria-labelledby="lang-select-label"
                autoComplete="off"
                value="English"
                id="lang-select">
                <Link
                    className="select-language-icons-with-code__link"
                    to="#"
                >
                    <div className="select-language-icons-with-code__icon">
                        <img
                            className="select-language-icons-with-code__img flag-icon image"
                            alt="en"
                            src={images["en.svg"]}
                        />
                    </div>
                    <div className="select-language-icons-with-code__code">English</div>
                </Link>
                <div className="select-language-icons-with-code__caret">
                    <i className="icon-caret"></i>
                </div>
            </div>

            <div
                className="select-language-icons-with-code__container">
                <ul className="select-language-icons-with-code__list" role="listbox" aria-labelledby="lang-select-label" id="lang-select-menu">

                    <Link
                        onClick={ () => { changeLanguageHandler('en') }}
                        className="select-language-icons-with-code__link option-link option-link--selected link"
                        variant="primary"
                        id="lang-select-item-0"
                        role="option"
                        aria-selected="false"
                        to="#"
                        disabled=""
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img
                                    className="select-language-icons-with-code__img flag-icon image"
                                    alt="en"
                                    src={images["en.svg"]}
                                />
                            </div>
                            English
                            <CheckOutlinedIcon className="select-language-icons-with-code__icon-check icon-check-small" />
                        </div>
                    </Link>

                    <Link
                        onClick={ () => { changeLanguageHandler('pt') }}
                        className="select-language-icons-with-code__link option-link link"
                        variant="primary"
                        id="lang-select-item-8"
                        role="option"
                        aria-selected="false"
                        to="/"
                    >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img
                                    className="select-language-icons-with-code__img flag-icon image"
                                    alt="pt"
                                    src={images["pt.svg"]}
                                />
                            </div>Português</div>
                    </Link>

                    
                    <Link 
                        onClick={ () => { changeLanguageHandler('ru') }}
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-1" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="ru" 
                                    src={images["ru.svg"]} 
                                    />
                            </div>Русский</div>
                    </Link>

                    <Link 
                        onClick={ () => { changeLanguageHandler('es') }}
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-7" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="es" 
                                    src={images["es.svg"]}
                                    />
                            </div>Español</div>
                    </Link>

                    {/*
                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-2" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="en-AU" 
                                    src="./assets/en-AU.svg" 
                                    />
                            </div>English - Australia</div>
                    </Link>


                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-3" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="en-NZ" 
                                    src="./assets/en-NZ.svg" 
                                    />
                            </div>English - New Zealand</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-4" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="de" 
                                    src="./assets/de.svg" 
                                    />
                            </div>Deutsch</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-5" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="fi" 
                                    src="./assets/fi.svg" 
                                    />
                            </div>Suomi</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-6" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="pl" 
                                    src="./assets/pl.svg" 
                                    />
                            </div>Polski</div>
                    </Link>


                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-9" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="pt-BR" 
                                    src="./assets/pt-BR.svg" 
                                    />
                            </div>Português - BR</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-10" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="ja" 
                                    src="./assets/ja.svg" 
                                    />
                            </div>日本語</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-11" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="no" 
                                    src="./assets/no.svg" 
                                    />
                            </div>Norwegian</div>
                    </Link>


                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-12" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="en-CA" 
                                    src="./assets/en-CA.svg" 
                                    />
                            </div>English - Canada</div>
                    </Link>

                    <Link 
                        className="select-language-icons-with-code__link option-link link" 
                        variant="primary" 
                        id="lang-select-item-13" 
                        role="option" 
                        aria-selected="false" 
                        to="/"
                        >
                        <div className="option-link__content">
                            <div className="select-language-icons-with-code__icon">
                                <img 
                                    className="select-language-icons-with-code__img flag-icon image" 
                                    alt="fr-CA" 
                                    src="./assets/fr-CA.svg" 
                                    />
                            </div>Français - Canada</div>
                    </Link>
*/}
                </ul>
            </div>
        </div>
    )
}

export default Language
