import React from 'react'
import GameInfo from './GameInfo'
import GameSlider from './GameSlider'
import LobbyContainer from './LobbyContainer'
import LobbyHeader from './LobbyHeader'

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

function Lobby() {
    return (
        <main className="layout__game">
            <div className="page-game">
                <LobbyHeader />
                <div className="page-game__bg"></div>
                <LobbyContainer />

                <div className="page-game__content">

                    <div className="container">
                        <GameInfo />

                        <div className="game-menu">
                            <div className="game-slider-wrapper game-menu__slider">

                                <div className="game-slider__tabs">
                                    <div className="tabs">
                                        <div className="tabs__tabs-wrapper">
                                            <span className="tabs__tab subtitle2 tabs__tab--active">GAMES FOR YOU</span>
                                            <span className="tabs__tab subtitle2">TOP GAMES</span>
                                            <span className="tabs__tab subtitle2">NEW</span>
                                            <span className="tabs__tab subtitle2">PLAYED GAMES</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="common-slider__controls">
                                    <button className="common-slider__button common-slider__button--prev btn btn--outline">
                                        <KeyboardArrowDownOutlinedIcon/>
                                    </button>

                                    <button className="common-slider__button common-slider__button--next btn btn--outline">
                                        <KeyboardArrowDownOutlinedIcon/>
                                    </button>
                                </div>

                               <GameSlider />

                            </div>

                            <div className="game-menu__list">
                                <div className="home-game-slider game-slider__category">
                                    <div className="home-game-slider__head">
                                        <p className="home-game-slider__title heading1 secondary">Games for you</p><a className="home-game-slider__seeMore btn link-btn btn--outline" href="https://www.crossgaming.com/en-AU/games/recommended">See all</a></div>
                                    <div className="catalog">
                                        <div className="catalog__list">
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Gold Express game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/GoldExpress.webp" /><img className="game__img image image--hidden" alt="Gold Express game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/gold-express">Gold Express</a></div>
                                                                <div className="game__provider">booongo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Bounty Gold game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/BountyGold.webp" /><img className="game__img image image--hidden" alt="Bounty Gold game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/bounty-gold">Bounty Gold</a></div>
                                                                <div className="game__provider">pragmaticplay</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Roo Riches game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/RooRiches.webp" /><img className="game__img image image--hidden" alt="Roo Riches game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/roo-riches">Roo Riches</a></div>
                                                                <div className="game__provider">isoftbet</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Dragon Pearls: hold and win game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/DragonPearls.webp" /><img className="game__img image image--hidden"
                                                        alt="Dragon Pearls: hold and win game tile" src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/dragon-pearls-hold-and-win">Dragon Pearls: hold and win</a></div>
                                                                <div className="game__provider">booongo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="catalog__more"><button className="btn--more btn btn--secondary btn--large btn--block">show more</button></div>
                                    </div>
                                </div>
                                <div className="home-game-slider game-slider__category">
                                    <div className="home-game-slider__head">
                                        <p className="home-game-slider__title heading1 secondary">top games</p><a className="home-game-slider__seeMore btn link-btn btn--outline" href="https://www.crossgaming.com/en-AU/games/top:en-AU">See all</a></div>
                                    <div className="catalog">
                                        <div className="catalog__list">
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Book of Dead game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/BookofDead.webp" /><img className="game__img image image--hidden" alt="Book of Dead game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-dead">Book of Dead</a></div>
                                                                <div className="game__provider">playngo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Wolf Gold game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/WolfGold.webp" /><img className="game__img image image--hidden" alt="Wolf Gold game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/wolf-gold">Wolf Gold</a></div>
                                                                <div className="game__provider">pragmaticplay</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="15 Dragon Pearls: Hold and Win game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/15DragonPearlsHoldandWin.webp" /><img className="game__img image image--hidden"
                                                        alt="15 Dragon Pearls: Hold and Win game tile" src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/15-dragon-pearls-hold-and-win">15 Dragon Pearls: Hold and Win</a></div>
                                                                <div className="game__provider">booongo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Book of Eye game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/BookofEye.webp" /><img className="game__img image image--hidden" alt="Book of Eye game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-eye">Book of Eye</a></div>
                                                                <div className="game__provider">onlyplay</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="catalog__more"><button className="btn--more btn btn--secondary btn--large btn--block">show more</button></div>
                                    </div>
                                </div>
                                <div className="home-game-slider game-slider__category">
                                    <div className="home-game-slider__head">
                                        <p className="home-game-slider__title heading1 secondary">new</p><a className="home-game-slider__seeMore btn link-btn btn--outline" href="https://www.crossgaming.com/en-AU/games/new">See all</a></div>
                                    <div className="catalog">
                                        <div className="catalog__list">
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Bounty Gold game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/BountyGold.webp" /><img className="game__img image image--hidden" alt="Bounty Gold game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/bounty-gold">Bounty Gold</a></div>
                                                                <div className="game__provider">pragmaticplay</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="The Last Sundown game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/TheLastSundown.webp" /><img className="game__img image image--hidden"
                                                        alt="The Last Sundown game tile" src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/the-last-sundown">The Last Sundown</a></div>
                                                                <div className="game__provider">playngo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap">
                                                        <img className="game__img image" alt="Gold Express game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/GoldExpress.webp" />
                                                        <img className="game__img image image--hidden" alt="Gold Express game tile"
                                                            src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/gold-express">Gold Express</a></div>
                                                                <div className="game__provider">booongo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Road 2 Riches game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/Road2Riches.webp" /><img className="game__img image image--hidden"
                                                        alt="Road 2 Riches game tile" src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/road-2-riches">Road 2 Riches</a></div>
                                                                <div className="game__provider">bgaming</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list">
                                                            <div className="game__label game__label--new">new</div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>
                                        <div className="catalog__more"><button className="btn--more btn btn--secondary btn--large btn--block">show more</button></div>
                                    </div>
                                </div>
                                <div className="home-game-slider game-slider__category">
                                    <div className="home-game-slider__head">
                                        <p className="home-game-slider__title heading1 secondary">played games</p><a className="home-game-slider__seeMore btn link-btn btn--outline" href="https://www.crossgaming.com/en-AU/games/played">See all</a></div>
                                    <div className="catalog">
                                        <div className="catalog__list">
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Lightning Roulette game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/lightning_roulette.webp" /><img className="game__img image image--hidden"
                                                        alt="Lightning Roulette game tile" src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/lightning-roulette">Lightning Roulette</a></div>
                                                                <div className="game__provider">evolution</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Wolf Gold game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/WolfGold.webp" /><img className="game__img image image--hidden" alt="Wolf Gold game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/wolf-gold">Wolf Gold</a></div>
                                                                <div className="game__provider">pragmaticplay</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>
                                            </div>
                                            <div className="catalog__item">
                                                <article className="games-block-default__game-item game--md game game--user">
                                                    <div className="game__wrap"><img className="game__img image" alt="Book of Dead game tile" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/BookofDead.webp" /><img className="game__img image image--hidden" alt="Book of Dead game tile"
                                                        src="" />
                                                        <div className="game__content-wrap">
                                                            <div className="game__content">
                                                                <div className="game__title"><a className="game__link link-no-styles" variant="primary" href="https://www.crossgaming.com/en-AU/game/book-of-dead">Book of Dead</a></div>
                                                                <div className="game__provider">playngo</div>
                                                            </div>
                                                            <div className="game__action"><button className="game__play"><img className="game__button image" src="./Play Lightning Roulette Online Casino Game Free or Real Money_files/button__play.svg" /></button></div>
                                                        </div><button className="text-btn game__favorite" variant="primary"><i className="icon-heart"></i></button>
                                                        <div className="game__label-list"></div>
                                                    </div>
                                                </article>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>


    )
}

export default Lobby
