import { useState, useEffect, useContext, useReducer } from 'react';

import RouterComponent from './RouterComponent';
import { AuthContextProvider } from './context/AuthContext';

function App() {
  return (

    // BEM
    <AuthContextProvider>
      <RouterComponent />
    </AuthContextProvider>
  );
}

export default App;
