import React from 'react';
import { Link } from 'react-router-dom'
import PaymentDeposit from './PaymentDeposit';
import PaymentTitle from './PaymentTitle';
import PaymentWidthrow from './PaymentWidthrow';

function Payments() {
  return (
     <main className="layout__main">
                <div className="payments bg-image">
                    <div className="container">
                        <div className="payments__wrapper">
                            <PaymentTitle/>
                            <PaymentDeposit/>
                            <PaymentWidthrow/>
                        </div>
                    </div>
                </div>
            </main>
  )
}

export default Payments