
function DepositPaymentCard(props) {
    return (
        <div className="sc-psRme gQbOlY payment-card">
            <div id="payment_method" data-method-id="finteqhub_finteqhub-neteller-146_neteller"
                className="khVFdb payment-card__content">
                <img
                    alt={props.alt}
                    src={props.src}
                    data-cdn-icon-name="finteqhub-neteller"
                    className="sc-qPjXN hGiEja payment-card__img"
                />
                <div className="sc-qXgLg kxXIM payment-card__min">{props.min}</div>
            </div>
        </div>
    )
}

export default DepositPaymentCard

