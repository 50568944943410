import AuthContext from '../../context/AuthContext';
import { useContext, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';



function Profile(props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const { user, isAuthenticated, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    let menuRef = useRef();

    const dropdownMenu = () => {
        if (!showDropdown) {
            setShowDropdown(true); 
        } else {
            setShowDropdown(false);
        }
    }


    /*useEffect( () => {
        //console.dir('Profile UseEffect: ' + user);
    }, [isAuthenticated]);*/

    /*useEffect(() => {
  }, [user]);*/

  // Popup Menu
  useEffect( () => {
    let handler = (event) => {
      if (showDropdown && menuRef.current && !menuRef.current.contains(event.target)) {
        dropdownMenu();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [showDropdown]);

    return (
        <>
            <div
                className="header__user-menu-balance menu-balance-select select-game-currency"
                role="combobox"
                aria-expanded="false"
                aria-haspopup="listbox"
                aria-labelledby="balance-select-label"
            >
                <div
                    className="select-game-currency__button"
                    aria-autocomplete="list"
                    aria-labelledby="balance-select-label"
                    autoComplete="off"
                    value="0 EUR"
                    id="balance-select">

                    <Link className="select-game-currency__link select-game-currency__link-select" to="/">
                        {props.balance}
                    </Link>

                    <div className="select-game-currency__arrow-down-wrapper">
                        {/*<i className="select-game-currency__arrow-down icon-arrow-down"></i>*/}
                        <KeyboardArrowDownOutlinedIcon />
                    </div>

                </div>

                <div className="select-game-currency__container">
                    <ul className="select-game-currency__list" role="listbox" aria-labelledby="balance-select-label" id="balance-select-menu">
                        <div
                            id="balance-select-item-0"
                            role="option"
                            aria-selected="false"
                            className="select-game-currency__link option-link option-link--selected"
                        >
                            <div className="option-link__content">
                                {props.balance}
                                <i className="select-game-currency__icon-check icon-check-small"></i>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>

            <Link
                className="header__button-deposit deposit-button btn link-btn btn--primary"
                to="/wallet/deposit"
            >
                Deposit
            </Link>

            <div className="header__notification-center notification-center notification-center--empty" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-0-label">
                <button className="text-btn notification-center__button" variant="primary">
                    {/*<i className="notification-center__icon icon-notifications"></i>*/}
                    <NotificationsOutlinedIcon />
                    <div className="notification-center__count">
                        0
                    </div>
                </button>

                <div className="notification-center__container" role="listbox" aria-labelledby="downshift-0-label" id="downshift-0-menu">
                    <div className="notification-center__list">
                        <div className="notification-center__empty">
                            <span className="notification-center__empty-text">
                                No current notifications
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header__user-menu-select select-user-menu" onClick={dropdownMenu} role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-1-label">
                <div className="select-user-menu__button" aria-autocomplete="list" aria-labelledby="downshift-1-label" autoComplete="off" value="" id="downshift-1-input">
                    <Person2OutlinedIcon />
                </div>


                <div 
                    className="select-user-menu__dropdown" 
                    style={ showDropdown ? {"display": "block"} : {"display": "none"} }
                    ref={menuRef}
                    >
                    <div className="select-user-menu__menu-wrapper">
                        <div className="select-user-menu__menu user-menu">
                            <div className="user-menu__item user-menu__user">
                                Hey {props.firstName}!
                            </div>
                            <div className="divider"></div>

                            <Link
                                className="user-menu__item user-menu__item--profile link"
                                to="/profile/general"
                                variant="primary"
                                target="_self"
                            >
                                My account
                            </Link>

                            <Link
                                className="user-menu__item user-menu__item--deposit link"
                                to="/wallet/deposit"
                                variant="primary"
                                target="_self"
                            >
                                Deposit
                            </Link>

                            <Link
                                className="user-menu__item user-menu__item--withdraw link"
                                to="/wallet/withdrawal"
                                variant="primary"
                                target="_self"
                            >
                                Withdraw
                            </Link>

                            <Link
                                className="user-menu__item user-menu__item--promo link"
                                to="/profile/promo"
                                variant="primary"
                                target="_self"
                            >
                                Promotions
                            </Link>

                            <Link
                                className="user-menu__item user-menu__item--history link"
                                to="/profile/gamehistory"
                                variant="primary"
                                target="_self"
                            >
                                Game history
                            </Link>

                            <Link 
                                to="/support"
                                className="user-menu__item user-menu__item--support"
                                /*onClick={ () => {
                                    navigate("/support", { replace: true })
                                }}*/
                                > 
                                Support 
                            </Link>
                            <div className="divider"></div>

                            <button
                                className="text-btn user-menu__logout user-menu__item menu-logout-button"
                                variant="primary"
                                onClick={ () => {
                                    setUser(null);
                                    localStorage.clear();
                                    navigate("/", { replace: true })
                                }}
                            >
                                Sign Out
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default Profile
