import React from 'react'

function FooterLicense() {
    return (
        <div className="footer__license-wrapper">
            <div className="footer__logo-wrapper">
                <div
                    id="apg-seal-container"
                    data-apg-seal-id="4d4d5e53-57bf-4790-9fe6-1fe679506a1b"
                    data-apg-image-size="128" data-apg-image-type="basic-small">
                    <div
                        style={{
                            display: 'block',
                            position: 'relative',
                            overflow: 'hidden',
                            maxWidth: '128px',
                            minWidth: '32px',
                            backgroundImage: `url(https://4d4d5e53-57bf-4790-9fe6-1fe679506a1b.snippet.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png)`
                        }}>
                        <a
                            target="_blank"
                            rel="nonoopener"
                            href="https://validator.antillephone.com/validate?domain=www.CrossGamng.com&amp;seal_id=a5f9fe20e68869f5f53cdba21ec1bdb36db18dad794af40f7da68f241b63e177b1ff09c46f56e98bcc3ef778179897ce&amp;stamp=dcf5edd8c05cf33227b8f1d3ee3e885c"
                        >
                            <img
                                alt=""
                                style={{
                                    width: '100%',
                                    height: 'auto'
                                }}
                                src="./assets/dcf5edd8c05.com-a5f9fe20e68869f5f53cdba21ec1bdb36db18dad794af40f7da68f241b63e177b1ff09c46f56e98bcc3ef778179897ce-c2VhbC5wbmc=" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="footer__license-content-wrapper">
                <p className="body3 footer__license-title">
                    © CrossGamnig, 2021 - { new Date().getFullYear() }
                </p>
                <span className="body4 footer__license-content ">
                    Copyright © 2021, CrossGamng.com is owned and operated by Name N.V.,
                    a company registered and established under the laws of Curaçao, with registration number xxxxxx and
                    registered address at Street number, Willemstad, Curaçao, and its wholly-owned subsidiary,
                    Company Limited, registered in Cyprus with registration number HM xxxxxx and registered address Street,
                    FK xxxx, Limassol, Сyprus. Name N.V. is licensed and regulated by
                    Antillephone N.V. (license no. xxxx/JAZxxxx-xxx).
                    All payments with Paysafe are made via Name N.V.
                    It is the player’s sole responsibility to inquire about the existing laws and regulations of the
                    given jurisdiction for online gambling.
                </span>
            </div>

        </div>
    )
}

export default FooterLicense
