import React from 'react';
import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function BonusesCards(props) {
    return (
        <div className="bonuses-cards__card bonuses-cards__card--/system/comfy/cms/files/files/000/000/272/original/sakura.png">
            <div className="bonuses-cards__bg">
                <div
                    className="bonuses-cards__bgColor"
                    style={{
                        "background": "radial-gradient(69.72% 49.19% at 50% 21.36%, rgba(255, 45, 70, 0.15) 0%, rgba(255, 45, 45, 0) 100%)"
                    }}>
                </div>
                <img 
                    className="bonuses-cards__image image" 
                    alt={props.imgAlt} 
                    src={props.image} 
                />
                <h3 className="heading3 bonuses-cards__title">
                    {props.title}
                </h3>
                <h4 className="bonuses-cards__size">
                    {props.size}
                </h4>
                <p className="bonuses-cards__description">
                    {props.description}
                    <p className="bonuses-cards__description"></p>
                </p>
                <Link 
                    className="bonuses-cards__btn btn link-btn btn--danger btn--large btn--block" 
                    to={props.url}>
                    {props.buttonText}
                </Link>
                <button className="text-btn bonuses-cards__info" variant="primary">
                    <InfoOutlinedIcon/>
                </button>
            </div>
        </div>

    )
}

export default BonusesCards