import './Promo.css';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

function Promo() {
  return (
    <main className="wPaCf layout__main">
      <div className="jIfkRp promo page-container">
        <h1 className="XfiFP title title--h1">Promo</h1>
        <div className="lhnzQ promo-block__points-exchange-container">
          <div className="fhbxkc player-level__container">
            <div className="gaNReY player-level__info-container">
              <div className="iAEnGT player-level__title">
                Level Cote d'Azur 1st step
                <button
                  className="eFMCar sc-pcwJP hRYZJR player-level__info-button text-button text-button--primary"
                  type="button"
                >
                    <InfoOutlinedIcon className="icon icon-info" />
                </button>
              </div>
              <div className="jrHbyV player-level__info-content">
                <div className="cMhwVm player-level__description">
                  0 / 100 to Level Cote d'Azur 2nd step
                </div>
                <div className="ibwKCH player-level__progress-container">
                  <div className="jpwidV styled-progress-bar progress">
                    <div
                      role="progressbar"
                      className="progress-bar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        "width": "0%"
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ieepbn player-level__link-container">
              <a
                href="/vip"
                className="egHtzZ external-link"
                target="_blank"
              >
                All Levels{" "}
                <LinkOutlinedIcon className="eGuNiQ external-link__icon icon icon-external-link" />
              </a>
            </div>
          </div>
        </div>
        <div className="ikLPkb promo-block__banners-container"></div>
        <h2 className="hVAReK title title--h2 bonuses__title">
          Bonuses
        </h2>
        <div className="koRqLQ bonuses__tabs-container bonuses__tabs-container--empty">
          <div className="IjpeF bonuses__see-bonuses-history-link-container">
            <a
              className="gFlPhX bonuses__see-bonuses-history"
              href="/profile/promo/bonuses-history"
            >
              See bonuses history
            </a>
          </div>
        </div>
        <div className="bSSBze styled-cards__container bonuses__cars-container">
          <a
            className="lkvxQx bonuses__see-bonuses-history bonuses__see-bonuses-history-button"
            href="/profile/promo/bonuses-history"
          >
            See bonuses history
          </a>
          <div className="hEzbsI styled-card__wrapper">
            <div className="BTKFv bonus-promo__wrapper">
              <div className="fvArrq styled-card bonus-promo__card">
                <div className="iDgZBz styled-card__title bonus-promo__title">
                  Promo
                </div>
                <div className="hFCtia bonus-promo__sub-title">
                  Have a promocode?
                </div>
                <form className="zvFNV bonus-promo__form">
                  <div className="bdYFba input__wrapper">
                    <input
                      type="text"
                      id="promo-code"
                      placeholder="Type it here"
                      autoComplete="off"
                      className="cbYJr input__native"
                      value=""
                    />
                    <div className="hNONtA input__focus-container"></div>
                    <div className="htmcOf input__right-container">
                      <button
                        disabled=""
                        type="submit"
                        title="Set"
                        className="gwNWYc fpUlcQ bonus-promo__submit-button  fpUlcQ bonus-promo__submit-button styled-button styled-button--secondary"
                      >
                        <ArrowRightAltOutlinedIcon className="icon icon-arrow-right" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="fvArrq styled-card bonus-promo__card">
                <div className="iDgZBz styled-card__title bonus-promo__title">
                  Promo
                </div>
                <div className="hFCtia bonus-promo__sub-title">
                  Want more promo content?
                </div>
                <a
                  href="/bonuses"
                  className="egHtzZ external-link"
                  target="_blank"
                >
                  See all bonuses{" "}
                  <LinkOutlinedIcon className="eGuNiQ external-link__icon icon icon-external-link" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Promo;
