import React from 'react'
import { Link } from 'react-router-dom'
import LobbyLanguage from './LobbyLanguage'


function LobbyHeader() {
    return (
        <header className="page-game__header header-game header--bg">
            <Link 
                className="header__back link" 
                variant="primary" 
                to="/"
                /*href="https://www.crossgaming.com/"*/
                >
                <i className="header__back-icon icon-arrow"></i>
                <span className="header__back-text">Go to Lobby</span>
            </Link>
            
            <Link 
                className="header-game__search link-text-btn" 
                to="?search"
                variant="primary">
                <i className="header-game__search-icon icon-search-normal"></i>
                <span className="header-game__search-placeholder">search-game</span>
            </Link>
            
            <a className="header-game__back link-text-btn" href="https://www.crossgaming.com"
                    variant="primary">
                    <i className="header-game__back-icon icon-arrow-left"></i>
            </a>
            <div className="header__block header__block--jackpot"></div>

            <div className="header-game__right">
                <div className="game-currency-select select-game-currency" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="game-currency-select-label">
                    
                    <div className="select-game-currency__button" aria-autocomplete="list" aria-labelledby="game-currency-select-label" autoComplete="off" value="EUR" id="game-currency-select">
                        <a className="select-game-currency__link select-game-currency__link-select">EUR</a>
                        <div className="select-game-currency__arrow-down-wrapper">
                            <i className="select-game-currency__arrow-down icon-arrow-down"></i>
                        </div>
                    </div>

                    <div className="select-game-currency__container">
                        <ul className="select-game-currency__list" role="listbox" aria-labelledby="game-currency-select-label" id="game-currency-select-menu">
                            <div id="game-currency-select-item-0" role="option" aria-selected="false" className="select-game-currency__link option-link option-link--selected">
                                <div className="option-link__content">EUR<i className="select-game-currency__icon-check icon-check-small"></i></div>
                            </div>
                        </ul>
                    </div>
                </div>
                
                <a className="header-game__button-deposit deposit-button btn link-btn btn--primary" href="https://www.crossgaming.com/profile/wallet/wallet/deposit?back-url=https%3A%2F%2Fwww.crossgaming.com%2Fgame%2Flightning-roulette&amp;origin=casino&amp;target=_self">Deposit</a>
                <div className="header-game__notification-center notification-center notification-center--empty" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-4-label">
                    
                    <button className="text-btn notification-center__button" variant="primary">
                        <i className="notification-center__icon icon-notifications"></i>
                        <div className="notification-center__count">0</div>
                    </button>
                    
                    <div className="notification-center__container" role="listbox" aria-labelledby="downshift-4-label" id="downshift-4-menu">
                        <div className="notification-center__list">
                            <div className="notification-center__empty">
                                <span className="notification-center__empty-text">No current notifications</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-game__user-menu-select select-user-menu" role="combobox" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="downshift-5-label">
                    <div className="select-user-menu__button" aria-autocomplete="list" aria-labelledby="downshift-5-label" autoComplete="off" value="" id="downshift-5-input">
                        <i className="select-user-menu__arrow-icon icon-profile"></i>
                    </div>
                </div>

                <LobbyLanguage />
            </div>
        </header>
    )
}

export default LobbyHeader
