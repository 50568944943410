import React from 'react'
//import { useTranslation } from "react-i18next";


import HeaderWelcomeForm from './HeaderWelcomeForm'
import headerSlider from '../../container/assets/header-slider/lightning_blackjack.png'
import headerSliderMobile from '../../container/assets/header-slider/live-roulette.jpg'



function HomeHeader() {

    //const { t, i18n } = useTranslation();

    const messages = {
        subTitle: 'Welcome our gaming world!',
        headerTitle: 'Play for fun!',
        headerDescription: 'Sign-up, get bonuses, and qualified support and get big wins.'
    }
    return (
        <div className="home-header">
            <img
                className="home-header__banner home-header__banner-desktop image"
                alt="bg"
                /* 1792x640*/
                src={headerSlider}
            />
            <img
                className="home-header__banner home-header__banner-mobile image"
                alt="bg"
                /* 480x586*/
                src={headerSliderMobile}
            />

            <div
                className="home-header__container home-header__container--nosign container--small container">
                <div className="home-header__info">
                    <p className="heading1 secondary home-header__subtitle">{messages.subTitle}</p>
                    <h1 className="home-header__title">{messages.headerTitle}</h1>
                    <p className="home-header__description">
                        {messages.headerDescription}
                    </p>
                </div>

                <HeaderWelcomeForm
                    welcomeTitle="Welcome package"
                    welcomeBonus="500 EUR"
                    freeSpin="+ 50 Free Spins"
                    getBonusButtonTitle="Take Bonus Now!"
                    createAccountUrlTitle="Create account!"
                />


            </div>
        </div>
    )
}

export default HomeHeader
