import './GameHistory.css';

function GameHistory() {
  return (
    <main className="wPaCf layout__main">

      <div className="dDphJh game-history page-container page-container--table">
        
        <h1 className="XfiFP title title--h1">
            Game History
        </h1>
        
        <span className="loXGTB spins__count">
          Only last 50 transactions are available
        </span>

        <div className="DwQUP spins__block"></div>

        <div className="igMKMI styled-table__empty-message">
          No games history
        </div>

      </div>
    </main>
  );
}

export default GameHistory;
